import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    loading: {
      '&:before': {
        content: '"\\200D"',
        display: 'inline',
      },
    },
  });
