import React from 'react';
import {Component} from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import SignalWifi3BarIcon from '@material-ui/icons/SignalWifi3Bar';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import Snackbar from 'components/common/Snackbar';

import {styles} from './styles';

interface Props extends WithStyles<typeof styles> {}

const initialState = {
  open: false,
  isOnline: true,
};
type State = typeof initialState;

class ConnectionStatusBar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    window.addEventListener('online', this.handleOnline, false);
    window.addEventListener('offline', this.handleOffline, false);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  }

  handleOnline = () => {
    this.setState({
      open: true,
      isOnline: true,
    });
  };

  handleOffline = () => {
    this.setState({
      open: true,
      isOnline: false,
    });
  };

  handleClose = () => {
    this.setState({open: false});
  };

  render() {
    const {open, isOnline} = this.state;

    const message = isOnline ? 'You are back online' : 'You are offline';
    const icon = isOnline ? SignalWifi3BarIcon : SignalWifiOffIcon;

    return <Snackbar open={open} onClose={this.handleClose} message={message} Icon={icon} />;
  }
}

export default withStyles(styles)(ConnectionStatusBar);
