import createStyles from '@material-ui/core/styles/createStyles';

export const styles = () =>
  createStyles({
    root: {
      padding: '1rem 0',
      lineHeight: 1,
    },
    title: {
      fontSize: '15px',
      color: '#666',
      textAlign: 'left',
      fontWeight: 'normal',
      marginBottom: '1rem',
    },
    header: {
      fontSize: '0.75rem',
      fontWeight: 300,
      marginBottom: '0.2rem',
      color: '#999',
    },
    chartContent: {
      display: 'flex',
      alignItems: 'center',
    },
    dialogContent: {
      minWidth: 400,
      maxWidth: 500,
      width: '100%',
      height: 300,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 0,
    },
  });
