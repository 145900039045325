import React from 'react';
import {PureComponent} from 'react';
import {Bar} from 'react-chartjs-2';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';

import {toUSLocale} from 'utils/number';

import {styles} from './style';

interface Props extends WithStyles<typeof styles> {
  values: number[];
  width?: number;
  height?: number;
}

class BarChart extends PureComponent<Props, {}> {
  static defaultProps: Partial<Props> = {
    width: 400,
    height: 330,
  };

  private data: any;
  private options: any;

  componentWillMount() {
    this.data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1,
        },
      ],
    };

    this.options = {
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem: any, _data: any) => toUSLocale(Math.round(tooltipItem.yLabel)),
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              callback: (label: any, _index: any, _labels: any) => {
                if (label >= 1000000) {
                  return ` ${label / 1000000}M`;
                }
                if (label >= 1000) {
                  return ` ${label / 1000}K`;
                }
                return label;
              },
            },
          },
        ],
      },
    };
  }

  render() {
    const {classes, height, values, width} = this.props;

    this.data.labels = values.map((_v, index) => index.toString());
    this.data.datasets[0].data = [...values.slice()];

    return (
      <div className={classes.root}>
        <Bar data={this.data} options={this.options} width={width} height={height} />
      </div>
    );
  }
}
export default withStyles(styles)(BarChart);
