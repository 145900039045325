import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      margin: '60px 0',
      lineHeight: 1.65,
    },
    content: {
      'display': 'flex',
      'flexWrap': 'wrap',
      'marginBottom': 40,

      '@media print': {
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
      },
    },
    tile: {
      'marginRight': 70,

      '@media (max-width: 900px)': {
        width: '50%',
        marginRight: 0,
      },

      '@media print': {
        marginRight: 0,
      },
    },
    overlayOpen: {
      '@media (max-width: 500px)': {
        overflow: 'hidden',
      },
    },
  });
