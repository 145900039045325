import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

const SingleValue = (props) => (
  <MenuItem
    buttonRef={props.innerRef}
    selected={props.isFocused}
    component="div"
    style={{
      fontWeight: props.isSelected ? 500 : 400,
    }}
    className={props.selectProps.classes.tablet}
    {...props.innerProps}
  >
    {typeof props.data.picture === 'string' ? (
      <Avatar
        alt={props.data.label}
        src={props.data.picture}
        className={props.selectProps.classes.avatar}
      />
    ) : (
      <props.data.picture className={props.selectProps.classes.icon} />
    )}
    <ListItemText
      style={{padding: '0px 0px 0px 7px'}}
      primary={<span className={props.selectProps.classes.optionLabel}>{props.data.label}</span>}
    />
  </MenuItem>
);

export default SingleValue;
