import React, {Fragment} from 'react';
import {connect as reduxConnect} from 'react-redux';
import {matchPath, RouteComponentProps, withRouter} from 'react-router-dom';
import ConnectionStatusBar from 'components/common/ConnectionStatusBar';
import Container from 'components/common/Layout/Container';
import MainNav from 'components/common/MainNav';
import MainNavFloatingButton from 'components/common/MenuNavFloatingButton';
import NotificationStatusBar from 'components/common/NotificationStatusBar';
import 'styles/global.css';

import {RootState} from 'config/redux/rootReducer';

const hasSideMenu = [
  '/p/:id',
  '/p/:id/manage',
  '/p/:id/manage/logs',
  '/p/:id/manage/cases',
  '/p/:id/manage/access',
];

const mapToState = ({auth: {userInfo}}: RootState) => ({
  isOwner: !!userInfo?.isOwner,
  isAdmin: !!userInfo?.isAdmin,
});

const connect = reduxConnect(mapToState);

type Props = RouteComponentProps & ReturnType<typeof mapToState>;

const initialState = {menuOpen: false};

type State = typeof initialState;

class Layout extends React.Component<Props, State> {
  state = initialState;

  handleMenu = () => {
    this.setState({menuOpen: !this.state.menuOpen});
  };

  render() {
    const {location, children, isOwner, isAdmin} = this.props;
    const {menuOpen} = this.state;
    const route = location?.pathname || '';

    return (
      <>
        {hasSideMenu.some((path) => matchPath(route, {path, exact: true})) && (
          <Fragment>
            <MainNav
              showManage={isOwner || isAdmin}
              menuOpen={menuOpen}
              handleMenuClick={this.handleMenu}
            />
            <MainNavFloatingButton handleMenuClick={this.handleMenu} />
          </Fragment>
        )}
        <Container menuOpen={menuOpen}>{children}</Container>
        <ConnectionStatusBar />
        <NotificationStatusBar />
      </>
    );
  }
}

export default connect(withRouter(Layout));
