import React, {SFC} from 'react';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';

type Props = {onEnter: () => void} & TextFieldProps;

const handleEnter = (callback: () => void) => (e: React.KeyboardEvent<HTMLDivElement>) =>
  e.keyCode === 13 /*ENTER code*/ && callback();

export const EmailField: SFC<Props> = ({onEnter, error, ...props}) => (
  <TextField
    {...props}
    id="proposal-email-input"
    label="Email"
    type="email"
    required={true}
    margin="normal"
    variant="outlined"
    InputLabelProps={{shrink: true}}
    onKeyDown={!error ? handleEnter(onEnter) : null}
    error={error}
  />
);
