import {createAction} from 'redux-act';

export const actions = {
  showNotificationLoading: createAction<string>('global/SHOW_NOTIFICATION_LOADING'),
  showNotificationConfirming: createAction<string>('global/SHOW_NOTIFICATION_CONFIRMING'),
  showNotificationInfo: createAction<string>('global/SHOW_NOTIFICATION_INFO'),
  showNotificationReload: createAction<string>('global/SHOW_NOTIFICATION_RELOAD'),
  showNotification: createAction<string>('global/SHOW_NOTIFICATION'),
  hideNotification: createAction('global/HIDE_NOTIFICATION'),
};
