import abbreviation from 'number-abbreviate';

export const toUSLocaleCurrency = (
  value: string | number,
  decimals?: number,
  maximumFractionDigits?: number,
) => {
  return Number(value).toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits,
    style: 'currency',
    currency: 'USD',
  });
};

export const toUSLocale = (
  value: string | number,
  decimals?: number,
  maximumFractionDigits?: number,
) => {
  return Number(value).toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits,
  });
};

const numAbbr = new abbreviation(['K', 'M', 'B', 'T']);
export const abbreviate = (value: number, decimals?: number) => {
  return numAbbr.abbreviate(value, decimals);
};

export const convertToPercentage = (value: number, total: number) =>
  parseFloat(((value / total) * 100).toFixed(2));
