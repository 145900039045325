import {createReducer} from 'redux-act';

import Proposal from 'models/Proposal';

import {actions as proposalActions} from '../proposal/actions';
import {actions} from './actions';

export const initialState = {
  token: '',
  proposal: {} as Proposal,
  userInfo: {
    isOwner: false,
    isAdmin: false,
  },
};

export default createReducer({}, initialState)
  .on(proposalActions.updateRawProposal, (state, payload) => {
    const {_owner, _collection, ...proposal} = payload;
    return {...state, proposal: {...state.proposal, ...proposal}} as typeof initialState;
  })
  .on(actions.clearUserInfo, (_state) => initialState)
  .on(actions.setToken, (state, token) => ({...state, token}))
  .on(proposalActions.setProposal, (state, proposal) => ({...state, proposal}))
  .on(actions.setUserInfo, (state, userInfo) => ({...state, userInfo}));
