import React from 'react';
import classNames from 'classnames';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

const MultiValue = (props) => (
  <Chip
    tabIndex={-1}
    key={props.data.value}
    label={props.data.label}
    className={classNames(props.selectProps.classes.chip, {
      [props.selectProps.classes.chipFocused]: props.isFocused,
    })}
    avatar={props.data.picture ? <Avatar src={props.data.picture} /> : <></>}
    onDelete={(event) => {
      props.removeProps.onClick();
      props.removeProps.onMouseDown(event);
    }}
  />
);

export default MultiValue;
