import {jwtDecode} from 'utils/jwt';
import environment from 'config/environment';

import {RootState} from '../config/redux/rootReducer';

const env = environment.currentType;

export const stateTransformer = (state: RootState) => {
  return {
    user: {...state.auth.userInfo, ...jwtDecode(state.auth.token)},
  };
};

export const isProd = () => env !== 'development';
