export const selectOptionStyles = (styles, {isDisabled, isFocused, isSelected}) => {
  return {
    ...styles,
    'backgroundColor': getBackgroundColor(isDisabled, isFocused, isSelected),
    ':active': {
      backgroundColor: '#e1bee7',
    },
    'color': isDisabled ? '#ccc' : 'inherit',
    'cursor': isDisabled ? 'not-allowed' : 'default',
  };
};

export const selectValueRemoveStyles = (styles, {data}) => {
  return {
    ...styles,
    'color': data.color,
    ':hover': {
      backgroundColor: '#d0d0d0',
      color: '#4a4a4a',
    },
  };
};

const getSelected = (isFocused: boolean, isSelected: boolean) => {
  const color = '#f2f2f2';
  const selectedColor = '#e2e2e2';
  return isSelected ? selectedColor : isFocused ? color : null;
};

const getBackgroundColor = (isDisabled: boolean, isFocused: boolean, isSelected: boolean) =>
  isDisabled ? null : getSelected(isFocused, isSelected);
