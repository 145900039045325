import {Theme} from '@material-ui/core/styles';
import {emphasize} from '@material-ui/core/styles/colorManipulator';
import createStyles from '@material-ui/core/styles/createStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {},
    input: {
      display: 'flex',
      padding: 0,
    },
    controlInputProps: {
      padding: '4.5px 14px',
    },
    valueContainer: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    chip: {
      margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      fontSize: 16,
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    placeholder: {},
    inputNotchedOutline: {},
    inputLabel: {},
    avatar: {
      height: 22,
      width: 22,
    },
    icon: {
      fontSize: 16,
      color: 'rgba(0, 0, 0, 0.8)',
    },
    optionLabel: {
      fontSize: 13,
      letterSpacing: -0.15,
      margin: '3px 0',
      display: 'flex',
      color: 'rgba(0, 0, 0, 0.8)',
    },
    optionWrapper: {},
    optionSelected: {},
    tablet: {
      height: 36,
      backgroundColor: 'rgba(235, 87, 87, 0.15)',
      borderRadius: 5,
      padding: '0px 12px 0px 8px',
    },
    lightFont: {
      fontWeight: 400,
    },
    boldFont: {
      fontWeight: 500,
    },
  });
