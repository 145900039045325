import React from 'react';
import MainNavMenu from '../MainNavMenu';

export enum PageEnum {
  proposal = '/p/:id',
  manage = '/p/:id/manage',
  cases = '/p/:id/manage/cases',
  access = '/p/:id/manage/access',
  logs = '/p/:id/manage/logs',
}

export enum HashEnum {
  cases = '#cases',
  overview = '#overview',
  audience = '#audience',
  pricing = '#pricing',
  managePublication = '#publication',
  manageDetails = '#details',
  manageCsv = '#csv',
  managePricing = '#pricing',
}

export const PublicMainNavMenus = (props) => {
  const {menuActive, scrollTo, hasPricing} = props;
  return (
    <>
      <MainNavMenu
        asHref={`${HashEnum.overview}`}
        active={menuActive === HashEnum.overview}
        onClick={scrollTo('proposal-main', 'overview')}
      >
        Overview
      </MainNavMenu>
      <MainNavMenu
        asHref={`${HashEnum.audience}`}
        active={menuActive === HashEnum.audience}
        onClick={scrollTo('audience', 'audience')}
      >
        Audience
      </MainNavMenu>
      {hasPricing && (
        <MainNavMenu
          asHref={`${HashEnum.pricing}`}
          active={menuActive === HashEnum.pricing}
          onClick={scrollTo('pricing', 'pricing')}
        >
          Pricing
        </MainNavMenu>
      )}
    </>
  );
};

export const ManageMainNavMenus = (props) => {
  const {id, menuActive, scrollTo} = props;
  return (
    <>
      <MainNavMenu
        asHref={`/p/${id}${PageEnum.manage}`}
        active={menuActive === HashEnum.managePublication}
        onClick={scrollTo('manage-main', 'publication')}
      >
        Publication
      </MainNavMenu>
      <MainNavMenu
        asHref={`/p/${id}${PageEnum.manage}`}
        active={menuActive === HashEnum.manageDetails}
        onClick={scrollTo('manageDetails', 'details')}
      >
        Details
      </MainNavMenu>
      <MainNavMenu
        asHref={`/p/${id}${PageEnum.manage}`}
        active={menuActive === HashEnum.manageCsv}
        onClick={scrollTo('manageCsv', 'csv')}
      >
        CSV
      </MainNavMenu>
      <MainNavMenu
        asHref={`/p/${id}${PageEnum.manage}`}
        active={menuActive === HashEnum.managePricing}
        onClick={scrollTo('managePricing', 'pricing')}
      >
        Pricing
      </MainNavMenu>
    </>
  );
};
