import React from 'react';
import classNames from 'classnames';
import {Assign, Omit} from 'utility-types';

import IconButton from '@material-ui/core/IconButton';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InfoIcon from '@material-ui/icons/Info';
import * as _sortBy from 'lodash.sortby';

import ChartDataItem from 'models/ChartDataItem';

import {abbreviate, toUSLocale} from 'utils/number';

import {styles} from './styles';

type Props = {
  parentTotal?: number;
  children?: boolean;
  data: ChartDataItem[];
  openedItems: string[];
  majorItem: ChartDataItem;
  showInfoButton: boolean;
  percent: boolean;
  total: number;
  sortBy: 'A/Z' | 'Count';
  toggleItem: (item: ChartDataItem) => void;
  onClickInfoButton: (evt: React.MouseEvent<HTMLElement>, id: string) => void;
} & WithStyles<typeof styles>;

export const ChartList = withStyles(styles)(({classes, data, ...props}: Props) => (
  <ul className={classNames(classes.root, {[classes.inside]: props.children})}>
    {order(data, props.sortBy).map((item: ChartDataItem, index: number) => (
      <ChartItem
        key={item.key}
        {...{index, item, classes, open: props.openedItems.includes(item.key), ...props}}
      />
    ))}
  </ul>
));

type ItemProps = {index: number; item: ChartDataItem; open: boolean} & Omit<Props, 'data'>;

const ChartItem = ({classes, item, open, ...props}: ItemProps) => (
  <li key={item.key} className={classNames(classes.item, {open})}>
    <div
      className={classNames(classes.bar, {
        [classes.barInside]: props.children,
        [classes.empty]: item.value === 0,
      })}
      style={{
        width: `${item.value === 0 ? '100' : calcBarSize(item.value, props.majorItem.value)}%`,
      }}
    />
    <div className={classes.itemDetails} onClick={() => props.toggleItem(item)}>
      {item.children && <ChevronRightIcon className={classNames(classes.itemBarIcon, {open})} />}

      <span
        className={classNames(classes.itemLabel, {
          [classes.subItemLabel]: !item.children,
        })}
      >{`${item.key}`}</span>

      {props.showInfoButton && (
        <IconButton
          className="info-button"
          onClick={(ev) => props.onClickInfoButton(ev, item.id ?? '')}
        >
          <InfoIcon style={{fontSize: 17}} />
        </IconButton>
      )}

      <ValueItem value={item.value} {...props} />
    </div>

    {open && item.children && (
      <ChartList {...props} data={item.children} children={true} parentTotal={item.value} />
    )}
  </li>
);

type ValueProps = Assign<
  {value: number},
  Pick<Props, 'percent' | 'children' | 'total' | 'parentTotal'>
>;

const ValueItem = ({percent, children, value, parentTotal = 1, total = 1}: ValueProps) => {
  const percentValue = toUSLocale((value * 100) / (total || 1), 2, 2);
  const percentParentValue = toUSLocale((value * 100) / (parentTotal || 1), 2, 2);
  const itemValue = value < 100000 ? toUSLocale(value) : abbreviate(value, 1);

  const tooltipMsg = valueCondition(
    children,
    `${itemValue}  |  ${percentValue}% of total count`,
    itemValue,
  );

  return (
    <Tooltip title={tooltipMsg} placement="left" className="count">
      <span>
        {valueCondition(percent, children ? percentParentValue : percentValue, itemValue)}
        {valueCondition(percent, '%')}
      </span>
    </Tooltip>
  );
};

const valueCondition = (condition, value, _default = '') => (condition ? value : _default);

const order = <T extends ChartDataItem>(list: T[], sortBy: string) =>
  (sortBy === 'A/Z'
    ? _sortBy(list, [(item: T) => item.key])
    : _sortBy(list, [(item: T) => -item.value])) as T[];

const calcBarSize = (portion: number, total: number) => (portion / total) * 100;
