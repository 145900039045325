import React from 'react';
import {SortableHandle} from 'react-sortable-hoc';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import DragIndicator from '@material-ui/icons/DragIndicator';

import {styles} from './styles';

interface Props extends WithStyles<typeof styles> {}

const DragHandle = (props: Props) => {
  return <DragIndicator className={props.classes.handle} />;
};

export default withStyles(styles)(SortableHandle(DragHandle));
