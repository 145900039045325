import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      margin: '0 0 30px',
    },
    data: {
      'letterSpacing': -1,
      'fontSize': '1.9rem',
      'fontWeight': 300,
      'marginTop': 6,

      '@media (max-width: 500px)': {
        fontSize: '1.4rem',
      },
    },
    label: {
      fontSize: '0.8rem',
      color: '#666',
      letterSpacing: -0.1,
    },
  });
