import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {textDecoration: 'none'},
    btn: {
      fontSize: '0.8rem',
      fontWeight: 400,
      color: '#666',
      padding: '10px 15px',
      margin: '3px 0',
    },
    btnActive: {
      'color': '#000',
      'fontWeight': 500,

      '&:after': {
        content: '""',
        width: 5,
        height: '70%',
        position: 'absolute',
        right: -14,
        backgroundColor: '#741195',
      },
    },
  });
