import React from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import copy from 'copy-to-clipboard';

import {getOrigin} from 'utils/url';

import SectionTitle from '../SectionTitle';
import {DataType} from './Container';
import {styles} from './styles';
import {useParams} from 'react-router-dom';

type Props = WithStyles<typeof styles> & DataType;

function SectionManageAccess(props: Props) {
  const {id} = useParams();
  const {classes, notify} = props;
  const url = `${getOrigin()}/p/${id}`;

  const handleCopy = () => {
    copy(url);
    notify('Proposal url copied to clipboard');
  };

  return (
    <section className={classes.root} id="manage-details">
      <SectionTitle text="Access Management" />

      <FormControl component={'fieldset' as 'div'} className={classes.formControl}>
        <div className={classes.textFieldGroup}>
          <TextField
            className={classes.textField}
            id="proposal-title-input"
            label="Proposal URL"
            value={url}
            disabled={true}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <div>
            <Button onClick={handleCopy} size="small" className={classes.btn}>
              Copy
            </Button>
          </div>
        </div>
      </FormControl>
    </section>
  );
}

export default withStyles(styles)(SectionManageAccess);
