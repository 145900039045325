import React from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import maxBy from 'lodash.maxby';

import ChartDataItem from 'models/ChartDataItem';
import SortByEnum from 'models/SortByEnum';

import ChartList from './ChartList';
import Options from './Options';
import {styles} from './styles';

export interface HorizontalBarChartProps extends WithStyles<typeof styles> {
  title: string;
  items: ChartDataItem[];
  sortBy: SortByEnum;
  hideOptions?: boolean;
  showExpandButton?: boolean;
  showInfoButton?: boolean;
  percent?: boolean;
  onClickHorizontalInfoButton: (id: string) => void;
  switchButton?: JSX.Element;
}

interface HorizontalBarChartState {
  expandAll: boolean;
  openedItems: string[];
  sortBy: SortByEnum;
  anchorEl: any;
}

export default withStyles(styles, {withTheme: true})(
  class extends React.Component<HorizontalBarChartProps, HorizontalBarChartState> {
    static defaultProps: Partial<HorizontalBarChartProps> = {
      title: '',
      sortBy: SortByEnum.az,
      showExpandButton: false,
      showInfoButton: false,
      hideOptions: false,
      onClickHorizontalInfoButton: () => {
        return;
      },
    };

    constructor(props: HorizontalBarChartProps) {
      super(props);
      this.state = {
        expandAll: true,
        sortBy: props.sortBy,
        openedItems: [],
        anchorEl: {},
      };
    }

    render() {
      const {
        classes,
        title,
        hideOptions,
        items,
        showInfoButton = false,
        percent,
        showExpandButton = false,
        switchButton,
      } = this.props;
      return (
        <div className={classes.root}>
          {title.length > 0 && <h4>{title}</h4>}
          {!hideOptions && (
            <Options
              showExpandButton={showExpandButton}
              expandAll={this.state.expandAll}
              onToggleAll={this.toggleAll}
              onSortTrigger={this.sortBy}
              sortBy={this.state.sortBy}
              switchButton={switchButton}
            />
          )}

          {this.renderCharts(items, showInfoButton, percent)}
        </div>
      );
    }

    toggleAll = () => {
      const {expandAll} = this.state;
      let openedItems: string[] = [];

      if (expandAll) {
        openedItems = this.props.items.map((i) => i.key);
      }

      this.setState({openedItems, expandAll: !expandAll});
    };

    toggleItem = (item: ChartDataItem) => {
      const key = item.key;
      const isOpened = this.state.openedItems.includes(key);

      let {openedItems} = this.state;

      if (isOpened) {
        openedItems = openedItems.filter((obj) => obj !== key);
      } else {
        openedItems.push(key);
      }

      this.setState({openedItems});
    };

    renderCharts = (data: ChartDataItem[], showInfoButton: boolean, percent: boolean = false) => {
      if (data.length === 0) {
        return null;
      }

      const total = data.reduce((a, b) => a + b.value, 0);
      const majorItem = maxBy(data, (item) => item.value);

      const {sortBy, openedItems} = this.state;

      return (
        <ChartList
          {...{
            data,
            majorItem,
            total,
            showInfoButton,
            percent,
            sortBy,
            openedItems,
            toggleItem: this.toggleItem,
            onClickInfoButton: this.onClickHorizontalInfoButton,
          }}
        />
      );
    };

    onClickHorizontalInfoButton = (evt: React.MouseEvent<HTMLElement>, id: string) => {
      evt.preventDefault();
      evt.stopPropagation();
      this.props.onClickHorizontalInfoButton(id);
    };
    sortBy = (param: SortByEnum) => this.setState({sortBy: param});
  },
);
