import React from 'react';
import {$PropertyType, Omit} from 'utility-types';

import IconButton, {IconButtonProps} from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import CleanIcon from '@material-ui/icons/Close';
import PlaceIcon from '@material-ui/icons/Place';
import GridIcon from '@material-ui/icons/ViewModule';
import Pagination, {PaginationProps} from 'components/common/Pagination';

import SurfaceExplorerViewMode from 'models/SurfaceExplorerViewMode';

import {styles} from './styles';

type ToolbarProps = WithStyles<typeof styles> &
  Omit<PaginationProps, 'classes'> & {
    searchTerm: string;
    onClearClick: () => void;
    onSearch: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    viewMode: SurfaceExplorerViewMode;
    onViewModeToggle: () => void;
    showToggleButton?: boolean;
    disabled?: boolean;
  };

const Toolbar = ({
  limit,
  total,
  skip,
  searchTerm,
  classes,
  onChangeSkip,
  onSearch,
  onClearClick,
  viewMode,
  disabled,
  onViewModeToggle,
  showToggleButton,
  minify,
}: ToolbarProps) => (
  <div className={classes.toolbar}>
    <div className={classes.surfaceSearch}>
      <TextField
        className={classes.surfaceSearchInput}
        id="surface-search-input"
        label="Search Surfaces"
        margin="dense"
        variant="outlined"
        value={searchTerm}
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: searchTerm ? <ClearButton onClick={onClearClick} /> : null,
        }}
        onChange={onSearch}
      />
      {showToggleButton && (
        <IconButton color="primary" aria-label="Show view mode" onClick={onViewModeToggle}>
          {viewMode === SurfaceExplorerViewMode.Grid ? <PlaceIcon /> : <GridIcon />}
        </IconButton>
      )}
    </div>

    <Pagination
      classes={{label: classes.paginationLabel}}
      disabled={disabled}
      limit={limit}
      skip={skip}
      total={total}
      onChangeSkip={onChangeSkip}
      model="surface"
      minify={minify}
    />
  </div>
);

function ClearButton(props: {onClick: $PropertyType<IconButtonProps, 'onClick'>}) {
  return (
    <InputAdornment position="end">
      <IconButton aria-label="Clear search" onClick={props.onClick}>
        <CleanIcon fontSize="small" />
      </IconButton>
    </InputAdornment>
  );
}

export default withStyles(styles)(Toolbar);
