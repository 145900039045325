import React from 'react';
import {PureComponent} from 'react';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import S3Uploader from 'components/common/S3Uploader';

import Proposal from 'models/Proposal';
import UploadedFileData from 'models/UploadedFileData';

import {getSignedUrl} from 'utils/aws';
import {blobToFile} from 'utils/file';
import {getImageDimensions, postCanvasToURL, resizeImage} from 'utils/image';

import {styles} from './styles';

export type Props = WithStyles<typeof styles> &
  Pick<Proposal, 'brandImageUrl'> & {
    token: string;
    proposalId: Proposal['id'];
    onUploadFinish: (publicUrl: string) => void;
  };

const initialState = {
  uploading: 0,
  brandImageUrl: '',
};

type State = typeof initialState;
class SectionManageDetails extends PureComponent<Props, State> {
  inputFile: HTMLInputElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      uploading: initialState.uploading,
      brandImageUrl: props.brandImageUrl,
    };
  }

  componentWillReceiveProps({brandImageUrl}: Props) {
    if (brandImageUrl !== this.props.brandImageUrl) {
      this.setState({brandImageUrl});
    }
  }

  handleBrandUploadProgress = (percent: number) => {
    this.setState({uploading: percent || 1});
  };

  handleBrandUploadStart = async (file: File, next: (file: File) => void) => {
    this.setState({
      uploading: 1,
    });
    const processedImage = await this.processImage(file);
    next(processedImage);
    this.setState({
      brandImageUrl: processedImage.preview,
    });
  };

  processImage = async (file: File) => {
    const objectUrl = window.URL.createObjectURL(file);
    const {width, height} = await getImageDimensions(objectUrl);
    const resizedImage = await resizeImage(objectUrl, {
      width: 800,
      height: height * (800 / width),
    });

    const resizedFile = blobToFile(postCanvasToURL(resizedImage, file.type), file.name);
    // const newDimensions = await getImageDimensions(resizedFile.preview);
    // if (newDimensions.height > 100) {
    //   const croppedImage = await cropImage(resizedFile.preview, {width: 250, height: 100});
    //   const croppedFile = blobToFile(postCanvasToURL(croppedImage, 'image/jpeg', 1), file.name);
    //   return croppedFile;
    // }

    return resizedFile;
  };

  handleBrandUploadFinish = (signResult: UploadedFileData) => {
    this.setState({uploading: 0});
    this.props.onUploadFinish(signResult.publicUrl);
  };

  handleRemoveBrand = () => {
    this.setState({uploading: 0, brandImageUrl: ''});
    this.inputFile.value = '';
    this.props.onUploadFinish('');
  };

  setInputRef = (input: HTMLInputElement) => (this.inputFile = input);

  render() {
    const {classes} = this.props;
    const {brandImageUrl, uploading} = this.state;

    return (
      <>
        <UploadSection
          uploading={uploading}
          firstLabel={this.renderPreviewImage()}
          secondLabel={
            <div className={classes.uploadButtonWrapper}>
              <IconButton
                aria-label="Upload brand image"
                component="span"
                disabled={!!uploading}
                classes={{root: `${classes.uploadButton} ${classes.uploadButtonPosition}`}}
              >
                <CloudUploadIcon />
              </IconButton>
              {!!uploading && (
                <CircularProgress
                  size={48}
                  variant="static"
                  value={uploading}
                  classes={{
                    root: `${classes.uploadButtonPosition} ${classes.uploadButtonLoader}`,
                  }}
                  style={{zIndex: 1}}
                />
              )}
            </div>
          }
          footerArea={
            brandImageUrl && (
              <Button
                onClick={this.handleRemoveBrand}
                color="primary"
                size="small"
                classes={{root: classes.removeButton}}
              >
                Remove
              </Button>
            )
          }
        />

        {this.renderUploader()}
      </>
    );
  }

  renderPreviewImage = () => {
    const {classes, brandImageUrl} = this.props;
    const {uploading} = this.state;

    return (
      <div
        className={classNames(classes.brandPreview, {
          [classes.brandPreviewPlaceholder]: !brandImageUrl,
        })}
      >
        {!!brandImageUrl ? (
          <img
            alt="brand"
            className={classNames(classes.brandPreview, classes.brandImage, {
              [classes.brandImageUploading]: uploading,
            })}
            src={brandImageUrl}
          />
        ) : (
          <Typography
            variant="caption"
            className={classNames(classes.brandPreview, {
              [classes.brandPreviewPlaceholder]: !brandImageUrl,
            })}
          >
            Client's Brand Image
          </Typography>
        )}
      </div>
    );
  };
  renderUploader = () => {
    const {proposalId, token} = this.props;
    return (
      <S3Uploader
        id="upload-brand"
        inputRef={this.setInputRef}
        accept=".jpg, .jpeg, .png, .gif, .svg"
        preprocess={this.handleBrandUploadStart}
        style={{display: 'none'}}
        onFinish={this.handleBrandUploadFinish}
        onProgress={this.handleBrandUploadProgress}
        onError={(msg) => {}}
        getSignedUrl={getSignedUrl(
          `proposals/${proposalId}`,
          `brand-${new Date().getTime()}`,
          token,
        )}
      />
    );
  };
}

const UploadSection = ({uploading, firstLabel, secondLabel, footerArea}) => {
  return (
    <div>
      <Typography variant="caption">Upload Client brand</Typography>

      <div style={{margin: '10px 0 30px'}}>
        <div style={{display: 'flex'}}>
          <label
            style={{position: 'relative', display: 'flex'}}
            htmlFor={!uploading ? 'upload-brand' : ''}
          >
            {firstLabel}
          </label>
          <label
            style={{position: 'relative', display: 'flex'}}
            htmlFor={!uploading ? 'upload-brand' : ''}
          >
            {secondLabel}
          </label>
        </div>

        {footerArea}
      </div>
    </div>
  );
};

export default withStyles(styles)(SectionManageDetails);
