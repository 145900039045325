import dayjs from 'dayjs';

import {FileType} from 'models/FileType';
import Indexed from 'models/Indexed';
import PaginatedResponse from 'models/PaginatedResponse';
import {FilterQuery, FilterQueryResponsePanel} from 'models/PanelSearchResponse';
import Proposal from 'models/Proposal';
import {SortEnum} from 'models/SortEnum';

import {sleep} from 'utils/promise';

import environment from 'config/environment';

const proposalBaseUrl = environment.settings.apiUrl.proposal + '/proposal';
const availabilityBase = environment.settings.availability;

type IndexedSortEnum = Indexed<SortEnum>;

type SearchPayload = {
  from?: number;
  limit?: number;
  minify?: boolean;
  sort?: {
    mad?: IndexedSortEnum[];
    audienceSort?: IndexedSortEnum[];
  };
  select?: {
    mad?: string[];
  };
  where?: {
    mad: {
      must: {
        id?: {
          terms: string[];
        };
        search?: {
          query: string;
        };
      };
    };
  };
};

type TryAgainOptions = {maximumRetry?: number; delay?: number; attempt?: number};

export const get = async (id: string, token: string, populate?: boolean) => {
  const populateQuery = populate ? '$populate=collection,owner' : '';
  return fetch(`${proposalBaseUrl}/${id}?${populateQuery}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'X-Tenant-ID': 'ofm',
    },
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(res.json());
  });
};

export const updateProposal = async (proposal: Partial<Proposal>, token: string) => {
  const res = await fetch(`${proposalBaseUrl}/${proposal.id}`, {
    method: 'PATCH',
    body: JSON.stringify(proposal),
    headers: {
      'Authorization': `Bearer ${token}`,
      'X-Tenant-ID': 'ofm',
    },
  });

  if (!res.ok) {
    throw await res.json();
  }

  return res.json() as Promise<Proposal>;
};

const getSignedURL = (url: string, options?: TryAgainOptions) => {
  const {attempt = 1} = options || {};

  return fetch(url).then(async (res) => {
    if (!res.ok) {
      return handleAttempsError(res, 403, options, () =>
        getSignedURL(url, {...options, attempt: attempt + 1}),
      );
    } else {
      return res.json();
    }
  });
};

export async function searchPanels<
  T extends FilterQueryResponsePanel | string = FilterQueryResponsePanel
>(
  proposalId: string,
  payload: SearchPayload,
  token: string,
  options?: TryAgainOptions,
): Promise<FilterQuery<T>> {
  const {attempt = 1, delay = 0} = options || {};

  const res = await fetch(`${proposalBaseUrl}/${proposalId}/panel/search?`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Authorization': `Bearer ${token}`,
      'X-Tenant-ID': 'ofm',
    },
  });

  if (!res.ok) {
    return handleAttempsError(res, 503, options, () =>
      searchPanels<T>(proposalId, payload, token, {...options, attempt: attempt + 1}),
    );
  }

  return res.json().then(async (r) => {
    if (r.status && r.dataUrl) {
      if (r.status === 'IN_PROGRESS') {
        await sleep(delay);
      }
      return getSignedURL(r.dataUrl, options);
    }
    return r;
  });
}

async function handleAttempsError<T extends FilterQueryResponsePanel | string>(
  res: Response,
  attempStatusCode: number,
  options: TryAgainOptions = {},
  callback: () => Promise<FilterQuery<T>>,
) {
  const {maximumRetry = 0, delay = 0, attempt = 0} = options;

  if (res.status !== attempStatusCode || attempt >= maximumRetry) throw res;
  await sleep(delay);
  return callback();
}

export const getAllProposalPanelIds = async (
  proposalId: string,
  token: string,
): Promise<PaginatedResponse<string>> => {
  const res = searchPanels<string>(
    proposalId,
    {from: 0, sort: {mad: [{marketName: SortEnum.asc}]}, limit: 10000, minify: true},
    token,
  );

  return res.then((r) => {
    const {total, data, limit, from: skip} = r.response;
    return {total, skip, limit, data: data.panels};
  });
};

export const auth = async (id: string, email: string) => {
  const encodedEmail = encodeURIComponent(email);
  const res = await fetch(`${proposalBaseUrl}/${id}/auth?$email=${encodedEmail}`, {
    method: 'POST',
  });

  if (res.ok) {
    return res.json();
  } else {
    throw await res.json();
  }
};

export const exists = async (id: string, signal?: AbortSignal) => {
  signal = signal || new AbortController().signal;
  const res = await fetch(`${proposalBaseUrl}/${id}/exists`, {signal});
  const data = await res.json();

  if (!res.ok) {
    throw data;
  }

  return data;
};

export const existsDebounced = (id: string, wait: number = 200) => {
  const controller = new AbortController();
  const signal = controller.signal;
  let timeout: NodeJS.Timeout | number;

  return new Promise<any>((resolve, reject) => {
    const later = async () => {
      timeout = 0;
      try {
        const res = await exists(id, signal);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    };

    if (timeout) {
      controller.abort();
    }
    clearTimeout(timeout as number);
    timeout = setTimeout(later, wait);
  });
};

export const getDemographics = async (proposalId: string, token: string) => {
  const res = await fetch(`${proposalBaseUrl}/${proposalId}/demographics`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'X-Tenant-ID': 'ofm',
    },
  });

  return res.json();
};

export const downloadProposalFile = async (
  id: string,
  type: FileType,
  token: string,
  targetCount?: number,
) => {
  let res: Response | null;

  switch (type) {
    case FileType.csv:
      res = await fetch(`${proposalBaseUrl}/${id}/panel/${type}`, {
        mode: 'cors',
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Tenant-ID': 'ofm',
        },
      });
      break;
    default:
      res = null;
  }

  if (res?.ok) {
    const blob = await res.blob();

    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = `proposal-${id}-surfaces-${dayjs().format('YYYY-MM-DDTHH:mm:ss')}.${type}`;
    document.body.appendChild(a);
    a.click();
    a.remove();

    return new Promise((res) => setTimeout(() => res(), 1000));
  }
};

export const getRealtime = async (ids: string[], startDate: string, endDate: string) => {
  const res = await fetch(`${availabilityBase.url}`, {
    method: 'POST',
    body: JSON.stringify({
      panelid: ids,
      cached_avails: false,
      status_code: 'lowest',
      availability: true,
      min_date: startDate,
      max_date: endDate,
    }),
    headers: {
      'x-api-key': availabilityBase.apiKey,
    },
  });

  return res.json();
};
