import createStyles from '@material-ui/core/styles/createStyles';

export const styles = () =>
  createStyles({
    handle: {
      color: 'rgba(0,0,0,0.2)',
      width: 24,
      height: 24,
      marginTop: 0,
      marginRight: 10,
      cursor: 'move',
    },
  });
