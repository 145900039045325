import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      textTransform: 'uppercase',
      height: 48,
    },
    label: {
      color: '#666',
      padding: 10,
      textTransform: 'uppercase',
    },
    limitButton: {
      minWidth: 20,
      padding: '0 10px',
      fontSize: 12,
    },
    limitMenu: {
      width: 80,
    },
  });
