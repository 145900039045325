import yellow from '@material-ui/core/colors/yellow';
import {createGenerateClassName, createMuiTheme} from '@material-ui/core/styles';

import {beakynPurple} from './colors';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  palette: {
    primary: {
      light: beakynPurple[300],
      main: beakynPurple[500],
      dark: beakynPurple[700],
    },
    secondary: {
      light: yellow[300],
      main: yellow[500],
      dark: yellow[700],
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      root: {
        letterSpacing: -0.1,
      },

      containedPrimary: {
        'backgroundImage': 'linear-gradient(to right, #9425b2 10%, #814ce6 100%)',
        'transition': 'all 0.16s ease-in-out',
        'boxShadow': '0 3px 8px 2px rgba(129, 76, 230, 0)',

        '&:hover': {
          transform: 'scale(1.03)',
          boxShadow: '0 3px 8px 3px rgba(129, 76, 230, 0.2)',
        },
        '&$disabled': {
          'opacity': 0.65,
          'color': '#FFF !important',
          'cursor': 'not-allowed !important',

          '&:hover': {
            transform: 'none',
            boxShadow: 'none',
          },
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: 'rgba(0,0,0,0.4)',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '14px',
        lineHeight: '1.5',
        color: 'rgba(0, 0, 0, 0.5)',
      },
      contained: {
        margin: '8px 12px 0 0',
      },
    },
    MuiListItem: {disabled: {}},
    MuiTypography: {
      body1: {
        lineHeight: '1.46429em',
      },
    },
  },
});

function createPageContext() {
  return {
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // The standard class name generator.
    generateClassName: createGenerateClassName({seed: 'main'}),
  };
}
export type PageContext = ReturnType<typeof createPageContext>;
export default function getPageContext(): PageContext {
  return createPageContext();
}
