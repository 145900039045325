import React from 'react';
import {CSSProperties} from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';

import {abbreviate} from 'utils/number';

import {styles} from './style';

type Props = {children: number; style: CSSProperties} & WithStyles<typeof styles>;

const LegendValue = ({children, classes, style}: Props) => {
  const handleAbbreviation = (value: number) => {
    if (value > 1000000) {
      return abbreviate(value, 2);
    } else {
      return abbreviate(value, 1);
    }
  };
  return (
    <span className={classes.root} style={style}>
      {handleAbbreviation(children)}
    </span>
  );
};

export default withStyles(styles)(LegendValue);
