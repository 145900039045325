import OnUploadFileData from 'models/OnUploadFileData';

export const blobToFile = (src: Blob, fileName: string): OnUploadFileData => {
  const temp: any = src;
  temp.lastModifiedDate = new Date();
  temp.name = fileName;
  temp.preview = URL.createObjectURL(src);

  return temp as OnUploadFileData;
};

export const dataURItoBlob = (dataURI: string) => {
  let byteString;
  if (dataURI.split(',')[0].includes('base64')) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], {type: mimeString});
};
