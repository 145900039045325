import createStyles from '@material-ui/core/styles/createStyles';
import {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    columnClass: {
      border: '1px solid #E6E6E6',
      borderRight: 'none',
      borderBottom: 'none',
    },
    largeCol: {
      maxWidth: 160,
    },
    mediumCol: {
      maxWidth: 125,
    },
    smallCol: {
      maxWidth: 120,
    },
    headRow: {
      width: '102vw',
      borderRight: '1px solid #E6E6E6',
    },
    row: {
      borderRight: '1px solid #E6E6E6',
      width: '102vw',
      height: 'auto',
    },
    last: {
      '&:last-child': {
        borderBottom: '1px solid #E6E6E6',
      },
    },
    grid: {},
  });

export type Styles = WithStyles<typeof styles>;
