import React, {SFC} from 'react';
import {FileType} from 'models/FileType';
import Proposal from 'models/Proposal';

import {downloadProposalFile} from 'services/proposal';

type InternalProps = {
  get?: (proposal: Proposal, token: string, targetCount: number) => void;
  show: (message: string) => void;
  hide: () => void;
};

export default <T extends object>(_type: FileType) => (
  Comp: React.ComponentType<T & InternalProps>,
) => {
  const get = (show, hide) => async (proposal, token, targetCount) => {
    const type = _type.toUpperCase();

    if (proposal) {
      show(`Building ${type} File`);
      await downloadProposalFile(proposal.id, _type, token, targetCount);
      hide();
    }
  };

  const C: SFC<T> = (props) => {
    const {get: _get, show, hide, ...rest} = props as any;

    return <Comp {...{get: get(show, hide), ...rest}} />;
  };

  C.displayName = `withGetProposalFile(${Comp.displayName})`;
  return C;
};
