import Indexed from 'models/Indexed';

export const groupBy = <TItem>(xs: TItem[], key: keyof TItem): {[key: string]: TItem[]} => {
  return xs.reduce((rv, x) => {
    (rv[x[key as string]] = rv[x[key as string]] || []).push(x);
    return rv;
  }, {});
};

export const collectionSlicer = <T>(collection: T[], length: number) => {
  const result = [] as T[][];
  for (let i = 0; i < collection.length; i += length) {
    result.push(collection.slice(i, i + length));
  }
  return result;
};

export const sum = (array: number[] = []) => Math.floor(array.reduce((acc, num) => acc + num, 0));
export const max = (array: number[] = []) => array.reduce((a, b) => Math.max(a, b), 0);
export const min = (array: number[] = []) => array.reduce((a, b) => Math.min(a, b), 0);
export const indexedToArray = <T>(list: Indexed<T>) => Object.keys(list).map((i) => list[i] as T);
export const arrayToIndexed = <T extends {id: string}>(list: T[]) =>
  list.reduce((acc, item) => ({...acc, [item.id]: item}), {} as Indexed<T>);
