import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      margin: '60px 0',
      lineHeight: 1.65,
    },
    topBorder: {
      'width': 'calc(100% + 100px)',
      'borderTop': '1px solid #ddd',
      'margin': '0px 0px 40px -50px',

      '@media print': {
        borderTop: 0,
      },
    },
    content: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 40,
    },
    tile: {
      'marginRight': 70,

      '@media (max-width: 900px)': {
        width: '50%',
        marginRight: 0,
      },
    },
  });
