import {Theme} from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'space-between',
      '@media print': {
        display: 'none',
      },
    },
    content: {
      '& span': {
        'textTransform': 'uppercase',
        'fontSize': '0.7rem',
        'color': theme.palette.primary.dark,

        '&:first-child': {
          color: '#889198',
        },
        '&.options': {
          margin: '10px 5px',
          cursor: 'pointer',
        },
        '&.current': {
          color: theme.palette.text.hint,
        },
      },
      'flex': 1,
    },
    transform: {
      transform: 'translateX(18px)',
    },
  });
