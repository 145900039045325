import React from 'react';
import {PureComponent} from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';

import {toUSLocale} from 'utils/number';

import {styles} from './style';

interface Props extends WithStyles<typeof styles> {
  data: number[][];
  xaxis: string[];
  yaxis: string[];
  range1: number;
  range2: number;
  range3: number;
  timeColumnWidth: number;
  format?: (value: number) => number;
}

const Tablet = (props: {title: string; color: string; className: string}) => {
  return (
    <Tooltip title={props.title}>
      <span className={`${props.className} ${props.color}`}>&nbsp;</span>
    </Tooltip>
  );
};

class Grid extends PureComponent<Props> {
  getColor = (value: number) => {
    const {classes, range1, range2, range3} = this.props;
    if (value === 0 || value < range1) {
      return classes.range1Color;
    }

    if (value < range2) {
      return classes.range2Color;
    }

    if (value < range3) {
      return classes.range3Color;
    }

    return classes.range4Color;
  };

  renderData = (impressionsByHours: number[]) => {
    const {format} = this.props;

    return impressionsByHours.map((h, innerIndex) => (
      <Tablet
        key={`Inner->${innerIndex}->${h}`}
        className={this.props.classes.tablet}
        color={this.getColor(h)}
        title={toUSLocale(format ? format(h) : h)}
      />
    ));
  };

  renderNoData = (index: number) => {
    const {data} = this.props;

    let baseData: number[] = [];
    data.forEach((impressionsByHours) => {
      if (!!impressionsByHours.length) {
        baseData = impressionsByHours;
      }
    });
    return baseData.map((_, innerIndex) => (
      <Tablet
        key={`NoData->${index}->${innerIndex}`}
        className={this.props.classes.tablet}
        color={this.getColor(-1)}
        title="No Data"
      />
    ));
  };

  render() {
    const {classes, xaxis, yaxis, data, timeColumnWidth} = this.props;

    return (
      <div className={classes.chartContent}>
        {data.map((impressionsByHours, index) => (
          <div key={`Outter->${index}`} className={`${classes.chartColumn} ${classes.dayColumn}`}>
            {!!impressionsByHours.length
              ? this.renderData(impressionsByHours)
              : this.renderNoData(index)}
            <span
              key={xaxis[index]}
              className={classes.tablet}
              style={{
                alignSelf: 'center',
              }}
            >
              {xaxis[index]}
            </span>
          </div>
        ))}
        <div
          key={'time'}
          className={`${classes.chartColumn} ${classes.timeColumn}`}
          style={{
            width: timeColumnWidth,
          }}
        >
          {yaxis.map((h, index) => (
            <span key={h} className={classes.tablet}>
              {index % 2 === 0 ? h : '\u00A0'}
            </span>
          ))}
          <span className={classes.tablet}>&nbsp;</span>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Grid);
