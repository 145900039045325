import React from 'react';
import {SFC} from 'react';

interface Props {
  color?: string;
  size?: number | string;
  className?: string;
}

const Circle: SFC<Props> = ({color, size, className}: Props) => (
  <span
    style={{
      height: size,
      width: size,
      backgroundColor: color,
      borderRadius: '50%',
      display: 'inline-block',
    }}
    className={className}
  />
);

Circle.defaultProps = {
  color: 'red',
  size: 'inherit',
};

export default Circle;
