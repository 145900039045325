import React from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';

import {styles} from './styles';

type Props = WithStyles<typeof styles> & {
  picture: string;
};

function ClientBrand(props: Props) {
  const {classes, picture} = props;

  return (
    <div className={classes.root}>
      <img src={picture} height="70" width="auto" alt="Client brand" />
    </div>
  );
}

export default withStyles(styles)(ClientBrand);
