import fetch from 'isomorphic-fetch';

import environment from 'config/environment';
import Log from 'models/Log';

const baseUrl = environment.settings.apiUrl.proposal + '/proposal';

type RespType<T> = {
  data: T[];
  skip: number;
  limit: number;
  total: number;
};
const defaultOptions = {skip: 0, limit: 7};

export const get = async (
  id: string,
  token: string,
  term?: string,
  {limit, skip} = defaultOptions,
): Promise<RespType<Log>> => {
  const populateQuery = `$select=date,resourceId,userEmail,userId,description,ip,type&$from=${skip}&$limit=${limit}${
    term ? `&$q=${term}*` : ''
  }`;

  const res = await fetch(`${baseUrl}/${id}/logs?${populateQuery}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'X-Tenant-ID': 'ofm',
    },
  });

  if (res.ok) {
    return res.json();
  } else {
    throw res;
  }
};
