import {IncomingMessage, ServerResponse} from 'http';
import * as Cookie from 'js-cookie';

import {getDomainFromWindow} from './url';

export const getCookieByContent = (key: string, req?: IncomingMessage) => {
  if (!req) {
    return Cookie.get(key);
  }

  if (req.headers.cookie) {
    const rawCookie = (req.headers.cookie as string)
      .split(';')
      .find((c) => c.trim().startsWith(`${key}=`));

    if (rawCookie) {
      return rawCookie.split('=')[1];
    }
  }
};

export const setCookieByContent = (
  key: string,
  value: string,
  ctx?: {
    req: IncomingMessage;
    res: ServerResponse;
  },
) => {
  Cookie.set(key, value, {domain: getDomainFromWindow()});
};

export const removeAllCookies = () => {
  const domain = getDomainFromWindow();
  Object.keys(Cookie.get()).map((cookie) => Cookie.remove(cookie, {domain}));
};
