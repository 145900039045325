import {createStyles, Theme} from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.typography.body1,
      'paddingBottom': '1rem 0',
      '& h4': {
        fontSize: '15px',
        color: '#666',
        textAlign: 'left',
        fontWeight: 'normal',
      },
    },
  });
