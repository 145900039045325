import React from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import LoggedUser from 'components/common/LoggedUser';
import SectionManageLogs from 'components/common/SectionManageLogs';
import {page, PageProps} from 'hoc/page';

import {get} from 'services/logs';

import {actions} from 'config/redux/logsActivity';
import {RootState} from 'config/redux/rootReducer';

import {styles} from './styles';
import {PrivatePageProps} from 'contexts/privateRoute';

type Props = PrivatePageProps & WithStyles<typeof styles> & PageProps;

class Logs extends React.Component<Props> {
  static pageTransitionDelayEnter = true;

  timeoutId: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  static async getInitialProps({token, query: {id}, store}) {
    const state = store.getState() as RootState;
    const result = state.logs;

    if (!result || !result.total) {
      const resp = await get(id, token);
      store.dispatch(actions.setData(resp));
    }

    return result;
  }

  render() {
    const {classes, loggedUser} = this.props;

    return (
      <div className={classes.root}>
        <main className={classes.main}>
          <LoggedUser email={loggedUser} />
          <SectionManageLogs />
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(page(Logs, 'Logs'));
