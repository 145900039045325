import React from 'react';
import {SortableContainer} from 'react-sortable-hoc';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';

import ListItem, {Props as ListItemProps} from './ListItem';
import {styles} from './styles';

export interface Props extends WithStyles<typeof styles>, Pick<ListItemProps, 'onDelete'> {
  items: string[];
}

const List = (props: Props) => {
  const {classes, items, onDelete} = props;
  return (
    <div className={classes.root}>
      {items &&
        items.map((item, index) => {
          return (
            <ListItem
              key={`item-${index}`}
              order={index + 1}
              index={index}
              item={item}
              onDelete={onDelete}
            />
          );
        })}
    </div>
  );
};

export default withStyles(styles)(SortableContainer(List));
