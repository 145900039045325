import React from 'react';
import {Component} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {ErrorMessage} from 'components/common/Message';
import OutFrontImage from 'components/common/OutFrontImg';
import {page, PageProps} from 'hoc/page';
import debounce from 'lodash.debounce';

import * as service from 'services/proposal';
import {styles} from './styles';

type Props = WithStyles<typeof styles> & RouteComponentProps<{id: string}> & PageProps;
const initialState = {
  value: '',
  showError: false,
  error: 'The Proposal ID is invalid. Contact your sales representative for further help.',
};
type State = typeof initialState;

class ProposalSearch extends Component<Props, State> {
  tryLoad = debounce(async () => {
    this.props.showLoading();
    try {
      const {value: id} = this.state;
      const {history} = this.props;
      const res = await service.existsDebounced(id);

      if (res.id === id) {
        history.push(`/p/${id}`);
      } else {
        throw new Error(initialState.error);
      }
    } catch (e) {
      const {statusCode, message} = e;
      const error = statusCode === 404 ? initialState.error : message;
      this.setState({error, showError: true});
      this.props.hideLoading();
    }
  }, 200);

  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  handleChange<T extends HTMLInputElement>(e: React.ChangeEvent<T>) {
    let error = '';
    const {value: id} = e.target;

    if (id.length > 14) {
      error = 'Value it should have a maximum of 14 characters';
    }
    this.setState({value: id.replace(/ /g, ''), error});
    this.tryLoad();
  }

  render() {
    const {classes} = this.props;
    const {error, showError, value} = this.state;
    return (
      <div className={classes.root}>
        <section className={classes.formSection} id="auth-form">
          <OutFrontImage classes={{root: classes.brand}} />

          <Typography variant="body2" style={{marginBottom: 30}}>
            You must have a valid <b>Proposal ID</b> to proceed. If you don't know what your
            Proposal ID is, contact your sales representative.
          </Typography>

          <FormControl component={'fieldset' as 'div'} className={classes.formControl}>
            <TextField
              className={classes.textField}
              id="proposal-id-input"
              type="text"
              label="Proposal ID"
              margin="normal"
              variant="outlined"
              autoFocus={true}
              InputLabelProps={{
                shrink: true,
              }}
              value={value}
              onChange={(e) => this.handleChange(e as React.ChangeEvent<HTMLInputElement>)}
            />
          </FormControl>

          {showError && !!value && <ErrorMessage message={error} />}
        </section>
      </div>
    );
  }
}

const Page = page(ProposalSearch, 'Proposal Search');
const PageWithRouter = withRouter(Page);
export default withStyles(styles)(PageWithRouter);
