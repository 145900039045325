import React from 'react';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Proposal from 'models/Proposal';

import {searchPanels} from 'services/proposal';

import {sum} from 'utils/array';
import {daysInterval} from 'utils/date';

import DataTitle from '../DataTile';
import SectionTitle from '../SectionTitle';
import {styles} from './styles';

const ShouldRender = ({children, iff}) => <> {iff ? children : null}</>;

const propertiesCollection = (
  startDate: string,
  endDate: string,
  surfaces: number,
  suggestedRate: number,
  discount: number,
  surfaceMean: number,
  days: number,
) => [
  {key: '0', value: `Date range: ${startDate} to ${endDate}`, render: true},
  {
    key: '1',
    render: !!surfaces,
    value: `${surfaces} surface${surfaces > 1 ? 's' : ''} on the proposal`,
  },
  {
    key: '2',
    render: suggestedRate && suggestedRate !== -1,
    value: (
      <div style={{display: 'flex'}}>
        {`Suggested net rate`}
        &nbsp;
        <NumberFormatLabel value={suggestedRate} />
        &nbsp;
        {`based on 1 asset per surface`}
      </div>
    ),
  },
  {key: '3', render: true, value: `${discount}% discounted on the adjusted price`},
  {
    key: '4',
    render: surfaceMean,
    value: (
      <div style={{display: 'flex'}}>
        {`Avarage of`}
        &nbsp;
        <NumberFormatLabel value={surfaceMean} />
        &nbsp;
        {`per surface on the total period`}
      </div>
    ),
  },
  {
    key: '5',
    value: (
      <ShouldRender iff={surfaceMean}>
        <div style={{display: 'flex'}}>
          {`Avarage of`}
          &nbsp;
          <NumberFormatLabel value={surfaceMean / days} />
          &nbsp;
          {`per surface per day`}
        </div>
      </ShouldRender>
    ),
  },
];

const NumberFormatCustom = (props) => {
  const {inputRef, onChange, ...other} = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale
    />
  );
};

const NumberFormatLabel = (props) => {
  return (
    <NumberFormat
      value={props.value}
      renderText={(value) => <div>{value}</div>}
      displayType="text"
      thousandSeparator
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale
      prefix="$ "
    />
  );
};

interface Props extends WithStyles<typeof styles> {
  proposal: Partial<Proposal>;
  token: string;
  onRateChange: (rate: {netRate: number; adjustedRate: number}) => void;
  suggestedRate: number;
  onSuggestedRateChange: (suggestedRate: number) => void;
}

const initialState = {
  netRate: null as number,
  adjustedRate: null as number,
};

type State = typeof initialState;

class SectionManagePricing extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...initialState,
      netRate: props.proposal.netRate,
      adjustedRate: props.proposal.adjustedRate,
    };
  }

  async componentDidMount() {
    if (!this.props.suggestedRate) {
      const {proposal, token, onSuggestedRateChange} = this.props;
      const {id} = proposal;

      try {
        const panels = await searchPanels(
          id,
          {
            limit: 10000,
            select: {mad: ['netrate']},
          },
          token,
        );

        const netRates = panels.response.data.panels.map((p) => p.mad.netrate);
        const suggestedRate = sum(netRates) / 28;

        onSuggestedRateChange(suggestedRate ? suggestedRate : -1);
      } catch (error) {
        onSuggestedRateChange(-1);
      }
    }
  }

  handleNetRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value ? Number(event.target.value) : null;
    const adjustedRate = !!value ? Math.min(value, this.state.adjustedRate) : null;

    this.props.onRateChange({
      adjustedRate,
      netRate: !!value ? value : null,
    });

    this.setState({
      adjustedRate,
      netRate: value,
    });
  };

  handleAdjustedRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value ? Number(event.target.value) : null;
    const adjustedRate = !!value ? Math.min(value, this.state.netRate) : null;

    this.props.onRateChange({
      ...this.state,
      adjustedRate,
    });

    this.setState({
      adjustedRate,
    });
  };

  handleSetNetRate = (suggestedRate: number, adjustedRate: number) => {
    this.setState({
      adjustedRate: Math.min(suggestedRate, adjustedRate),
      netRate: suggestedRate,
    });
  };

  get computedProps() {
    const {proposal} = this.props;
    const {netRate, adjustedRate} = this.state;

    const surfaces = (proposal._collection || {panelCount: null}).panelCount;
    const discount = adjustedRate
      ? parseFloat((100 - (adjustedRate / netRate) * 100).toFixed(2))
      : 0;
    const surfaceMean = surfaces ? netRate / surfaces : null;
    const days =
      proposal.startDate && proposal.endDate
        ? daysInterval(proposal.startDate, proposal.endDate)
        : 1;

    return {discount, days, surfaceMean, surfaces};
  }

  render() {
    const {classes, proposal, suggestedRate} = this.props;
    const {netRate, adjustedRate} = this.state;
    const {days, surfaceMean, discount, surfaces} = this.computedProps;
    const suggestedRateDaysSum = suggestedRate === -1 ? -1 : suggestedRate * days;

    const properties = propertiesCollection(
      proposal.startDate,
      proposal.endDate,
      surfaces,
      suggestedRateDaysSum,
      discount,
      surfaceMean,
      days,
    );

    return (
      <section className={classes.root} id="managePricing">
        <SectionTitle text="Pricing" />

        {this.renderSuggestedRate(classes, suggestedRateDaysSum, adjustedRate)}

        {this.renderRateInputs(classes, netRate, adjustedRate)}

        <Typography className={classes.subHeading}>The proposal present and future</Typography>

        <div className={classes.properties}>
          {properties.map(
            (property) =>
              property.render && (
                <div key={property.key} className={classes.propertiesContent}>
                  <div className={classes.contentCircle} />
                  <div className={classes.contentLabel}>{property.value}</div>
                </div>
              ),
          )}
        </div>
      </section>
    );
  }

  renderSuggestedRate = (classes, suggestedRate, adjustedRate) => (
    <div className={classes.suggestedRateWrapper}>
      {suggestedRate === -1 ? (
        <Typography className={`${classes.subHeading} ${classes.suggestedRateError}`}>
          The suggested rate could not be calculated. Insert your own Net Rate.
        </Typography>
      ) : (
        <>
          <DataTitle
            label="Suggested Rate"
            data={<NumberFormatLabel value={suggestedRate} />}
            loading={!suggestedRate}
          />
          <Button
            color="primary"
            size="small"
            className={classes.suggestedRateButton}
            onClick={() => this.handleSetNetRate(suggestedRate, adjustedRate)}
            disabled={!suggestedRate}
          >
            Apply
          </Button>
        </>
      )}
    </div>
  );

  renderRateInputs = (classes, netRate, adjustedRate) => (
    <div className={classes.formsWrapper}>
      <TextField
        className={classes.formControl}
        value={netRate}
        onChange={this.handleNetRateChange}
        label="Net Rate"
        variant="outlined"
        margin="dense"
        id="net-rate"
        InputProps={{
          inputComponent: NumberFormatCustom,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />

      <TextField
        disabled={!netRate}
        className={classes.formControl}
        value={adjustedRate}
        onChange={this.handleAdjustedRateChange}
        label="Adjusted Rate"
        variant="outlined"
        margin="dense"
        id="adjusted-rate"
        InputProps={{
          inputComponent: NumberFormatCustom,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    </div>
  );
}

export default withStyles(styles)(SectionManagePricing);
