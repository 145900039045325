import {DemographicsDataMapping} from './DemographicsDataMapping';
import data from './mapPersonicx.json';

export default data as DemographicsDataMapping;

export const isPersonicxCluster = (id: string) => {
  for (const key of Object.keys(data)) {
    const cluster = getCulster(key, id);
    if (cluster !== undefined) {
      return !!cluster;
    }
  }
  return false;
};

const getCulster = (key: string, id) => {
  if (data.hasOwnProperty(key)) {
    const val = data[key];
    if (val.id === id) {
      return val.group;
    }
  }
};
