import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import {Props as SingleDatePickerProps} from 'components/common/SingleDatePicker';

import Proposal, {ProposalStatus} from 'models/Proposal';

import SectionTitle from '../SectionTitle';
import {styles} from './styles';

type Props = WithStyles<typeof styles> &
  Pick<Proposal, 'status' | 'arquiveDate'> & {
    onArquiveDateChange: SingleDatePickerProps['onDateChange'];
    onStatusChange: (status: ProposalStatus) => void;
  };

function SectionManagePublication({classes, status, onStatusChange}: Props) {
  const handleStatusChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onStatusChange(evt.target.value as ProposalStatus);
  };

  return (
    <section className={classes.root}>
      <SectionTitle text="Publication" />

      <FormControl component={'fieldset' as any} className={classes.formControl}>
        <FormLabel component={'legend' as any} className={classes.fieldsetLegend}>
          Status
        </FormLabel>

        <RadioGroup
          aria-label="position"
          name="position"
          value={status}
          id="proposal-status-input"
          // [as any explanation]: material-ui need to be update to fix d.ts
          onChange={handleStatusChange as any}
          row
        >
          <FormControlLabel
            value={ProposalStatus.draft}
            control={<Radio color="primary" />}
            label="Draft"
          />
          <FormControlLabel
            value={ProposalStatus.published}
            control={<Radio color="primary" />}
            label="Published"
          />
          <FormControlLabel
            value={ProposalStatus.archived}
            control={<Radio color="primary" />}
            label="Archived"
          />
        </RadioGroup>

        <FormHelperText>
          Authorized users can only open <b>Published</b> proposals.
        </FormHelperText>
      </FormControl>

      {/* <FormControl component="fieldset" className={classes.formControl}>
        <SingleDatePicker
          date={arquiveDate}
          label="Arquive date"
          onDateChange={onArquiveDateChange}
          showClearDate
          helperText={`On this date the proposal will auto-archive and the assigned users will not be able
          to open it anymore until the status is manually changed back to published."`}
        />
      </FormControl> */}
    </section>
  );
}
export default withStyles(styles)(SectionManagePublication);
