import React from 'react';
import {SFC} from 'react';
import classNames from 'classnames';

import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography, {TypographyProps} from '@material-ui/core/Typography';

import {styles} from './styles';

type Props = WithStyles<typeof styles> & TypographyProps;

const Placeholder: SFC<Props> = ({children, classes, ...rest}) => {
  const {loading: loadingClasses, ...otherClasses} = classes;

  return (
    <Typography
      className={classNames('animated-background', loadingClasses)}
      classes={otherClasses}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default withStyles(styles)(Placeholder);
