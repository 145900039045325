import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {bindActionCreators as bindActions} from 'redux';

import {actions as globalActions} from 'config/redux/global';

const mapToState = (dispatch) => ({
  ...bindActions({notify: globalActions.showNotification}, dispatch),
});

export type DataType = ReturnType<typeof mapToState> & RouteComponentProps<{id: string}>;

export default connect(null, mapToState);
