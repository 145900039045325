import purple from '@material-ui/core/colors/purple';
import createStyles from '@material-ui/core/styles/createStyles';

export const styles = () =>
  createStyles({
    legendContent: {
      marginTop: '16px',
    },
    legendTablet: {
      flex: 1,
      margin: '1px',
    },
    legendContentTablet: {
      display: 'flex',
    },
    legendContentValue: {
      position: 'relative',
    },
    range1Color: {
      'backgroundColor': purple['100'],
      '@media print': {
        '-webkit-print-color-adjust': 'exact',
      },
    },
    range2Color: {
      'backgroundColor': purple['200'],
      '@media print': {
        '-webkit-print-color-adjust': 'exact',
      },
    },
    range3Color: {
      'backgroundColor': purple['400'],
      '@media print': {
        '-webkit-print-color-adjust': 'exact',
      },
    },
    range4Color: {
      'backgroundColor': purple['700'],
      '@media print': {
        '-webkit-print-color-adjust': 'exact',
      },
    },
  });
