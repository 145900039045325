import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      'margin': '60px 0',
      'lineHeight': 1.65,
      '@media print': {
        display: 'none',
      },
    },
    content: {
      'display': 'flex',
      'flexDirection': 'column',
      'maxWidth': 900,
      'marginTop': 0,
      '& p': {
        margin: '10px 0',
      },
    },
    link: {
      color: '#64008F',
      textDecoration: 'none',
    },
  });
