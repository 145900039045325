import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {bindActionCreators as bindActions, Dispatch} from 'redux';

import {actions} from 'config/redux/logsActivity';
import {RootState} from 'config/redux/rootReducer';

import Controller from './Controller';

const mapState = (
  {logs, auth: {token}}: RootState,
  external: RouteComponentProps<{id: string}>,
) => ({
  ...external,
  ...logs,
  token,
});

const mapToState = (dispatch: Dispatch) => ({
  ...bindActions({...actions}, dispatch),
});

const mergeProps = (
  stateProps: ReturnType<typeof mapState>,
  actionsProps: ReturnType<typeof mapToState>,
) => ({
  ...stateProps,
  ...actionsProps,
  ...new Controller({...stateProps, ...actionsProps}),
});

export type StateType = ReturnType<typeof mapState> & ReturnType<typeof mapToState>;
export type DataType = ReturnType<typeof mergeProps>;

export default connect(mapState, mapToState, mergeProps);
