import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';

import {actions} from './actions';

type Notification = '' | 'loading' | 'confirming' | 'info' | 'reload';

export const initialState = {
  notification: {
    message: '',
    type: '' as Notification,
    shouldShowNotification: false,
  },
};

const notification = createReducer({}, initialState.notification)
  .on(actions.showNotificationLoading, (_state, message) => ({
    message,
    type: 'loading',
    shouldShowNotification: true,
  }))
  .on(actions.showNotificationConfirming, (_state, message) => ({
    message,
    type: 'confirming',
    shouldShowNotification: true,
  }))
  .on(actions.showNotificationInfo, (_state, message) => ({
    message,
    type: 'info',
    shouldShowNotification: true,
  }))
  .on(actions.showNotificationReload, (_state, message) => ({
    message,
    type: 'reload',
    shouldShowNotification: true,
  }))

  .on(actions.showNotification, (_state, message) => ({
    message,
    type: '',
    shouldShowNotification: true,
  }))
  .on(actions.hideNotification, (_state) => ({..._state, shouldShowNotification: false}));

export default combineReducers({notification});
