import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';

import {styles} from './styles';

type Props = WithStyles<typeof styles>;

function SectionManageLogs(props: Props) {
  const {classes} = props;

  return (
    <section className={classes.root}>
      <main className={classes.main}>
        <div className={classes.paginationProgress}>
          <CircularProgress className={classes.progress} />
        </div>
      </main>
    </section>
  );
}

export default withStyles(styles)(SectionManageLogs);
