import React from 'react';
import Button from '@material-ui/core/Button';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import {useAuth} from 'contexts/auth';

import {styles} from './styles';

type Props = WithStyles<typeof styles> & {
  email: string;
};

function LoggedUser({classes, email}: Props) {
  const {logout} = useAuth();

  return (
    <div className={classes.root} id="logged-user">
      <Typography variant="caption" style={{color: '#666'}}>
        You are logged in as
      </Typography>

      <Typography variant="body1">{email}</Typography>

      <Button size="small" color="primary" className={classes.btn} onClick={logout}>
        Logout
      </Button>
    </div>
  );
}

export default withStyles(styles)(LoggedUser);
