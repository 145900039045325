import React from 'react';
import {SFC} from 'react';
import Select from 'react-select';
// import {ActionMeta} from 'react-select/lib/types';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import {SvgIconProps} from '@material-ui/core/SvgIcon';

import {selectOptionStyles, selectValueRemoveStyles} from 'utils/select';

import ControlSubComponent from './components/Control';
import MultiValueSubComponent from './components/MultiValue';
import NoOptionsMessageSubComponent from './components/NoOptionsMessage';
import OptionSubComponent from './components/Option';
import PlaceholderSubComponent from './components/Placeholder';
import SingleValueSubComponent from './components/SingleValue';
import ValueContainerSubComponent from './components/ValueContainer';
import {styles} from './styles';

export interface Option {
  label: string;
  value: string;
  picture?: string | React.ComponentType<SvgIconProps>;
}

export interface Props extends WithStyles<typeof styles> {
  label?: string;
  placeholder?: string;
  isMulti?: boolean;
  value: Option[];
  onChange?: (value: Option[], action: string) => void;
  options: Option[];
  maxMenuHeight?: number;
  menuPlacement?: 'auto' | 'top' | 'bottom';
}

function IndicatorSeparator() {
  return null;
}

function NoOptionsMessage(props: any) {
  return NoOptionsMessageSubComponent(props);
}

function inputComponent({inputRef, ...props}: any) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: any) {
  return ControlSubComponent(props, inputComponent);
}

function OptionComponent(props: {
  data: Option;
  innerRef: any;
  isFocused: boolean;
  isSelected: boolean;
  innerProps: any;
}) {
  return OptionSubComponent(props);
}

function Placeholder(props: any) {
  return PlaceholderSubComponent(props);
}

function SingleValue(props: any) {
  return SingleValueSubComponent(props);
}

function MultiValue(props: any) {
  return MultiValueSubComponent(props);
}

function ValueContainer(props: any) {
  return ValueContainerSubComponent(props);
}

function filterOption(op: {data: Option}, filterString: string) {
  if (!filterString) {
    return true;
  }

  return !!op.data.label && op.data.label.toLowerCase().includes(filterString.toLowerCase());
}

const AutoComplete: SFC<Props> = ({onChange, ...props}) => {
  const customComponents = {
    IndicatorSeparator,
    Option: OptionComponent,
    Control,
    NoOptionsMessage,
    Placeholder,
    SingleValue,
    ValueContainer,
    MultiValue,
  };

  return (
    <Select
      {...props}
      onChange={onChange as any}
      instanceId="auto-complete"
      className={props.classes.root}
      components={customComponents}
      filterOption={(o, s) => filterOption(o, s)}
      menuShouldScrollIntoView
      styles={{
        option: selectOptionStyles,
        multiValueRemove: selectValueRemoveStyles,
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#8007d4',
        },
      })}
    />
  );
};

AutoComplete.defaultProps = {
  menuPlacement: 'auto',
  isMulti: false,
  label: '',
};

export default withStyles(styles)(AutoComplete);
