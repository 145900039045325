import React from 'react';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import withMobileDialog, {
  InjectedProps as WithMobileDialogProps,
} from '@material-ui/core/withMobileDialog';

import Definitions from 'models/Definitions';

import {styles} from './styles';

type Props = WithMobileDialogProps &
  WithStyles<typeof styles> & {
    open: boolean;
    loading: boolean;
    title: string;
    definitions: Definitions;
    onClose: () => void;
  };

class DemographicsDates extends React.Component<Props> {
  render() {
    const {classes, open, definitions, title, onClose, loading, fullScreen} = this.props;
    return (
      <div>
        <Dialog
          open={open}
          onClose={onClose}
          fullScreen={fullScreen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {loading ? <span>&nbsp;</span> : definitions.name}
          </DialogTitle>
          <DialogContent
            classes={{
              root: classNames(classes.dialogContent, {
                [classes.dialogContentLg]: !fullScreen,
              }),
            }}
          >
            {loading ? (
              <Loading classes={classes} />
            ) : (
              <Content classes={classes} definitions={definitions} title={title} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function Content(props: Pick<Props, 'definitions' | 'title' | 'classes'>) {
  const {definitions, title, classes} = props;
  return (
    <div>
      <Typography
        className="moreInfoCaption"
        variant="caption"
        gutterBottom
        classes={{root: classes.caption}}
      >
        {title}
      </Typography>

      <div className={classes.moreInfoSection}>
        <Typography variant="subtitle1" gutterBottom>
          Introduction
        </Typography>
        <Typography>{definitions.introduction}</Typography>
      </div>

      <section className={classes.moreInfoSection}>
        <Typography variant="subtitle1" gutterBottom>
          About
        </Typography>
        <Typography>{definitions.about}</Typography>
      </section>

      <section className={classes.moreInfoSection}>
        <Typography variant="subtitle1" gutterBottom>
          Summary
        </Typography>
        <Typography component="div">
          <table className={classes.moreInfoSideTable}>
            {definitions.summary && (
              <tbody>
                <Row title="Age" value={definitions.summary[0].age} />
                <Row title="Income" value={definitions.summary[0].income} />
                <Row title="Income Rank" value={definitions.summary[0].income_rank} />
                <Row title="Children" value={definitions.summary[0].children} />
                <Row title="Urbanicity" value={definitions.summary[0].urbanicity} />
                <Row title="Urbanicity Rank" value={definitions.summary[0].urbanicity_rank} />
                <Row title="Martial Status" value={definitions.summary[0].martial_status} />
                <Row title="Networth" value={definitions.summary[0].networth} />
                <Row title="Networth Rank" value={definitions.summary[0].networth_rank} />
                <Row title="Home Ownership" value={definitions.summary[0].home_ownership} />
              </tbody>
            )}
          </table>
        </Typography>
      </section>
    </div>
  );
}

function Row(props: {title: string; value: string | number}) {
  return (
    <tr>
      <td>{props.title}</td>
      <td>{props.value}</td>
    </tr>
  );
}

function Loading(props: Pick<Props, 'classes'>) {
  return (
    <div className={props.classes.loading}>
      <CircularProgress size={24} thickness={5} />
    </div>
  );
}

export default withStyles(styles)(withMobileDialog<Props>()(DemographicsDates));
