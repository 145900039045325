import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      height: 2,
      zIndex: 100,
    },
  });
