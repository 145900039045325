import React from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import {styles} from './styles';

type Props = WithStyles<typeof styles>;

const TOS: React.SFC<Props> = ({classes}) => (
  <>
    <Typography className={classes.tosContent}>
      By clicking "Submit" or otherwise accessing or using the smartSCOUT services or reports, you
      agree to the{' '}
      <a
        href="https://www.outfrontmedia.com/pages/termsofuse.aspx"
        target="_blank"
        rel="noopener noreferrer"
        title="Read the Terms of Use"
      >
        Terms of Use
      </a>{' '}
      and{' '}
      <a
        href="https://www.outfrontmedia.com/pages/privacyhighlights.aspx"
        target="_blank"
        rel="noopener noreferrer"
        title="Read the Privacy Policy"
      >
        Privacy Policy
      </a>{' '}
      and the Additional Terms below. Please read them carefully.
    </Typography>

    <Typography className={classes.tosContent}>
      The smartSCOUT service is a pre-production "beta" release that is still undergoing development
      and testing. The smartSCOUT website, its software, the smartSCOUT reports and all content are
      provided on an "as is" and "as available" basis and you may encounter errors, lack of
      functionality, inaccuracies in reports and other issues as we continue testing and refinement
      of features.
    </Typography>

    <Typography className={classes.tosContent}>
      You agree to use the smartSCOUT service, the smartSCOUT reports and the information contained
      in the reports solely for purposes of evaluating the proposed advertising campaign and you
      will not use or rely upon the information contained on the smartSCOUT website or in the
      reports for any other purpose. OUTFRONT Media does not give any warranties, whether express or
      implied, as to the suitability, accuracy or usability of the smartSCOUT website, its software,
      the reports or any of their contents, or that the use of guidance in the reports will lead to
      any particular outcome or result. Additionally, nothing on the smartSCOUT website or in the
      reports modifies the rights or obligations under your Advertising Agreements with us.
    </Typography>

    <Typography className={classes.tosContent}>
      You are permitted to use the smartSCOUT reports and their contents solely within your
      organization and will not transmit, share, publish or otherwise disclose the reports or the
      content of the reports or the smartSCOUT website to any third parties for any reason without
      our permission.
    </Typography>

    <Typography className={classes.tosContent}>
      Should you encounter any bugs, lack of functionality or other problems, please email us here
      so we can correct the issue. Please note that we may freely use the feedback and other
      information you submit to us in accordance with the Terms of Use.
    </Typography>

    <Typography>We appreciate your participation in the beta launch of smartSCOUT!</Typography>
  </>
);

export default withStyles(styles)(TOS);
