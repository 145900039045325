import React from 'react';
import TextField from '@material-ui/core/TextField';

const Control = (props, inputComponent) => (
  <TextField
    fullWidth
    variant="outlined"
    multiline
    label={props.selectProps.label}
    InputLabelProps={{
      shrink: true,
      className: props.selectProps.classes.inputLabel,
    }}
    InputProps={{
      inputComponent,
      className: props.selectProps.classes.controlInputProps,
      classes: {notchedOutline: props.selectProps.classes.inputNotchedOutline},
      inputProps: {
        className: props.selectProps.classes.input,
        ref: props.innerRef,
        children: props.children,
        ...props.innerProps,
      },
    }}
  />
);

export default Control;
