import fetch from 'isomorphic-fetch';

import environment from 'config/environment';
import UploadedFileData from 'models/UploadedFileData';
import {getURLSearchParams} from 'utils/url';

type Callback = (r: UploadedFileData) => void;

export const getSignedUrl = (path: string, rename: string, token: string) => (
  file: File,
  callback: Callback,
) => {
  upload(file.name, file.type, path, rename, token)
    .then((data) => {
      callback(data);
    })
    .catch((error) => {});
};

const upload = async (
  file: string,
  contentType: string,
  path: string,
  rename: string,
  token: string,
): Promise<UploadedFileData> => {
  const params = {
    name: file,
    type: contentType,
    path,
    rename,
  };

  const baseUrl = environment.settings.apiUrl.upload;
  const res = await fetch(`${baseUrl}/upload?${getURLSearchParams(params)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
};
