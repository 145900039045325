import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {Link} from 'react-router-dom';
import dayjs from 'dayjs';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import _debounce from 'lodash.debounce';

import User from 'models/User';

import SectionTitle from '../SectionTitle';
import {DataType} from './Container';
import {EmailField} from './EmailField';
import {MenuEmailItem} from './MenuEmailItem';
import {styles} from './styles';

type Props = WithStyles<typeof styles> & RouteComponentProps<{id: string}> & DataType;
type State = {
  email: string;
  error?: string | false;
  sendEmailChecked?: boolean;
};

const formtDate = (date: string) =>
  date ? `Added ${dayjs(date).format('MMM DD, YYYY [at] H:mm A')}` : '';

const AddEmailAccess = ({
  classes,
  error,
  isPublished,
  email,
  handleAdd,
  handleEmailChange,
  handleCheckSendEmail,
}) => (
  <>
    <div className={classes.textFieldGroup}>
      <EmailField
        placeholder="Allow access by email"
        className={classes.textField}
        error={!!error}
        helperText={error}
        value={email}
        onChange={handleEmailChange}
        onEnter={handleAdd}
      />

      <div>
        <Button onClick={handleAdd} size="small" className={classes.btn} disabled={!!error}>
          Add
        </Button>
      </div>
    </div>
    {isPublished && (
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            defaultChecked={true}
            onChange={({target: {checked}}) => handleCheckSendEmail(checked)}
            disabled={!!error}
          />
        }
        label="Send Login Link"
      />
    )}
  </>
);

export default class extends Component<Props, State> {
  private checkUsedEmailDebounce;

  constructor(props: Props) {
    super(props);
    this.state = {email: '', sendEmailChecked: props.isPublished};
    this.checkUsedEmailDebounce = _debounce(this.checkUsedEmail, 500);
  }

  checkUsedEmail = (email: string) => {
    if (this.props.users.some(({email: e}) => e === email)) {
      this.setState({error: 'This email is already included'});
      return true;
    }
    return false;
  };

  checkValidEmail = (email: string) => {
    if (email.match(/\S+@\S+\.\S+/)) {
      this.setState({email: '', error: false});
      return true;
    }

    this.setState({error: 'Invalid email'});
    return false;
  };

  handleAdd = async () => {
    const {
      users,
      token,
      addUser,
      match: {
        params: {id},
      },
    } = this.props;
    const {email, sendEmailChecked} = this.state;

    if (this.checkUsedEmail(email)) {
      return;
    }

    if (this.checkValidEmail(email)) {
      await addUser({email} as User, {id, users}, token);

      if (sendEmailChecked) {
        await this.handleSend({email} as User);
      }
    }
  };

  handleRemove = (user: User) => {
    const {
      token,
      users,
      removeUser,
      match: {
        params: {id},
      },
    } = this.props;

    removeUser(user, {id, users}, token);
  };

  handleSend = (user: User) => {
    const {
      sendEmail,
      match: {
        params: {id},
      },
    } = this.props;
    sendEmail(id, user);
  };

  handleEmailChange = ({target: {value: email}}: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({email, error: false});
    this.checkUsedEmailDebounce(email);
  };

  renderUserList = () => {
    const {users, classes, isPublished} = this.props;
    return (
      <List>
        {users.map((user) => (
          <ListItem key={user.email} className={classes.listItem}>
            <ListItemText primary={user.email} secondary={formtDate(user.updatedAt)} />

            <ListItemSecondaryAction>
              <MenuEmailItem
                canSendEmail={isPublished}
                user={user}
                onRemove={this.handleRemove}
                onSend={this.handleSend}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  };

  render() {
    const {
      classes,
      users,
      isPublished,
      match: {
        params: {id},
      },
    } = this.props;
    const {error, email} = this.state;

    return (
      <section className={classes.root} id="manage-details">
        <SectionTitle text="Authorized Emails" />

        <FormControl component={'fieldset' as 'div'} className={classes.formControl}>
          <AddEmailAccess
            classes={classes}
            error={error}
            email={email}
            isPublished={isPublished}
            handleAdd={this.handleAdd}
            handleEmailChange={this.handleEmailChange}
            handleCheckSendEmail={(sendEmailChecked) => this.setState({sendEmailChecked})}
          />
          <div className={classes.list} id="email-list">
            {!isPublished && (
              <Typography variant="body2" className={classes.textFieldMessage} gutterBottom>
                This proposal is not published. You can add emails but they will not be allowed to
                log in. Change the proposal status on the <Link to={`/p/${id}/manage`}>Manage</Link>{' '}
                section.
                <br />
                <br />
              </Typography>
            )}

            {!users.length && (
              <Typography variant="body2" className={classes.textFieldMessage} gutterBottom>
                No emails are authorized to access this proposal yet.
                <br />
              </Typography>
            )}

            {this.renderUserList()}
          </div>
        </FormControl>
      </section>
    );
  }
}
