import React from 'react';
import {Component} from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import _debounce from 'lodash.debounce';
import _get from 'lodash.get';

import {Availability} from 'models/Availability';
import Indexed from 'models/Indexed';
import {Panel} from 'models/Panel';
import Proposal from 'models/Proposal';

import {getDemographics} from 'services/demographics';
import {getPanel} from 'services/mad';
import {getRealtime} from 'services/proposal';

import {toUSLocale} from 'utils/number';
import {isEmpty} from 'utils/object';

import DataTile from '../DataTile';
import SectionTitle from '../SectionTitle';
import SurfaceExplorer, {Props as SurfaceExplorerProps} from '../SurfaceExplorer';
import connect, {DataType} from './Container';
import {styles} from './styles';

interface Props
  extends DataType,
    WithStyles<typeof styles>,
    Pick<SurfaceExplorerProps, 'onClickPersonicxInfoButton'>,
    Pick<Proposal, 'startDate' | 'endDate'> {
  proposalId: Proposal['id'];
  token: string;
  noPagination: boolean;
  noLazy: boolean;
  noMap: boolean;
}

const initialState = {
  openDetailView: false,
  cachedSurfaces: {} as Indexed<SurfaceExplorerProps['selectedSurface']>,
  selectedSurface: {} as SurfaceExplorerProps['selectedSurface'],
  demographics: {} as SurfaceExplorerProps['demographics'],
  loadingSurface: true,
  loadingDemographics: true,
  loadingAvailability: true,
  availability: {} as Availability,
};
type State = typeof initialState;

type Func = () => void;

class SectionOverview extends Component<Props, State> {
  debounceFuncs;

  constructor(props: Props) {
    super(props);
    this.state = initialState;
    this.debounceFuncs = _debounce(this.executeFunctions, 500);
  }

  executeFunctions(funcs: Func[]) {
    funcs.map((f) => f());
  }

  renderDataTiles = (classes, startDate, endDate, panelCount) => (
    <>
      <div className={classes.tile}>
        <DataTile type="date" label="Start Date" data={startDate} />
      </div>
      <div className={classes.tile}>
        <DataTile type="date" label="End Date" data={endDate} />
      </div>
      <div className={classes.tile}>
        <DataTile
          label="Surface Count"
          loading={panelCount === null}
          data={toUSLocale(panelCount)}
        />
      </div>
    </>
  );

  render() {
    const {
      classes,
      startDate,
      endDate,
      panelCount,
      onClickPersonicxInfoButton,
      noLazy,
      noPagination,
      noMap,
      proposalId,
      token,
    } = this.props;
    const {
      demographics,
      openDetailView,
      selectedSurface,
      loadingSurface,
      loadingDemographics,
      loadingAvailability,
      availability,
    } = this.state;
    return (
      <section className={classes.root} id="overview">
        <SectionTitle text="Overview" />
        <div className={classes.content}>
          {this.renderDataTiles(classes, startDate, endDate, panelCount)}
        </div>
        <SurfaceExplorer
          token={token}
          proposalId={proposalId}
          openDetailView={openDetailView}
          selectedSurface={selectedSurface}
          demographics={demographics}
          loadingSurface={loadingSurface}
          loadingDemographics={loadingDemographics}
          loadingAvailability={loadingAvailability}
          availability={availability}
          onSurfaceClick={this.handleSurfaceClick}
          onCloseDetailView={this.handleCloseDetailView}
          onClickPersonicxInfoButton={onClickPersonicxInfoButton}
          startDate={startDate}
          endDate={endDate}
          noLazy={noLazy}
          noPagination={noPagination}
          noMap={noMap}
        />
      </section>
    );
  }

  handleSurfaceClick = async (surface: Partial<Panel>) => {
    const cachedSurface = this.state.cachedSurfaces[surface.id];
    const isSurfaceEmpty = isEmpty(cachedSurface);

    document.body.classList.add(this.props.classes.overlayOpen);
    this.setState({
      openDetailView: true,
      selectedSurface: !isSurfaceEmpty ? cachedSurface : surface,
      loadingSurface: !isSurfaceEmpty ? false : true,
      loadingDemographics: true,
      loadingAvailability: true,
    });
    const {proposalId, token} = this.props;

    this.debounceFuncs([
      () => {
        this.getSurface(proposalId, surface, token);
        this.getDemographics(surface.id);
      },
    ]);
  };

  getSurface = async (proposalId: string, surface: Partial<Panel>, token: string) => {
    const surfaceResult = await getPanel(surface.id, token, [
      'locationDescription',
      'marketingImageUrl',
      'mediaName',
      'location',
      'size',
      'areaType',
      'illuminated',
      'format',
      'prime',
      'facing',
      'netrate',
    ]);

    if (!_get(surfaceResult, 'id')) {
      this.props.showNotificationReload('Proposal was changed');
      this.handleCloseDetailView();
    } else if (_get(this.state.selectedSurface, 'id', '') === _get(surfaceResult, 'id', '')) {
      this.setState((state) => ({
        cachedSurfaces: {...state.cachedSurfaces, [surfaceResult.id]: surfaceResult},
        selectedSurface: {...state.selectedSurface, ...surfaceResult},
        loadingSurface: false,
      }));

      this.getAvailability(_get(surfaceResult, 'id'));
    }
  };

  getDemographics = async (surfaceId: string) => {
    const demographicsResult = await getDemographics(surfaceId);

    if (_get(this.state.selectedSurface, 'id', '') === surfaceId) {
      this.setState({
        demographics: demographicsResult,
        loadingDemographics: false,
      });
    }
  };

  getAvailability = async (surfaceId: string) => {
    const {startDate, endDate} = this.props;
    const result = await getRealtime([surfaceId], startDate, endDate);
    if (_get(this.state.selectedSurface, 'id', '') === surfaceId) {
      this.setState({
        availability: _get(result, 'data[0].availability', {}),
        loadingAvailability: false,
      });
    }
  };

  handleCloseDetailView = () => {
    document.body.classList.remove(this.props.classes.overlayOpen);
    this.debounceFuncs.cancel();
    this.setState({openDetailView: false, selectedSurface: {}, loadingSurface: true});
  };
}

export default withStyles(styles)(connect(SectionOverview));
