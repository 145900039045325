import {createAction} from 'redux-act';

import Proposal from 'models/Proposal';
import User from 'models/User';

export const actions = {
  setProposal: createAction<Proposal>('proposal/SET_PROPOSAL'),
  updateRawProposal: createAction<Partial<Proposal>>('proposal/UPDATE_RAW_PROPOSAL'),
  addUser: createAction<User>('proposal/ADD_USER'),
  removeUser: createAction<User>('proposal/REMOVE_USER'),
};
