import React from 'react';
import {PureComponent} from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import {max, min} from 'utils/array';

import Grid from './Grid';
import Legend from './Legend';
import {styles} from './style';

interface Props extends WithStyles<typeof styles> {
  data: number[][];
  xaxis: string[];
  yaxis: string[];
  format?: (value: number) => number;
}
const timeColumnWidth = 40;

class HeatChart extends PureComponent<Props> {
  getColor = (value: number, range1: number, range2: number, range3: number) => {
    const {classes} = this.props;
    if (value < range1) {
      return classes.range1Color;
    }

    if (value < range2) {
      return classes.range2Color;
    }

    if (value < range3) {
      return classes.range3Color;
    }

    return classes.range4Color;
  };

  render() {
    const {classes, xaxis, yaxis, data = [], format} = this.props;
    const maxValue = max(data.map((v) => max(v)));
    const minValue = min(data.map((v) => min(v)));

    const range = (Math.ceil(maxValue) - Math.floor(minValue)) / 4;

    const range1 = minValue + range;
    const range2 = range1 + range;
    const range3 = range2 + range;

    return (
      <Typography component="div" classes={{root: classes.root}}>
        <Grid
          data={data}
          range1={range1}
          range2={range2}
          range3={range3}
          xaxis={xaxis}
          yaxis={yaxis}
          format={format}
          timeColumnWidth={timeColumnWidth}
          classes={{
            range1Color: classes.range1Color,
            range2Color: classes.range2Color,
            range3Color: classes.range3Color,
            range4Color: classes.range4Color,
          }}
        />
        <Legend
          timeColumnWidth={timeColumnWidth}
          minValue={minValue}
          maxValue={maxValue}
          range1={range1}
          range2={range2}
          range3={range3}
          format={format}
          classes={{
            range1Color: classes.range1Color,
            range2Color: classes.range2Color,
            range3Color: classes.range3Color,
            range4Color: classes.range4Color,
          }}
        />
      </Typography>
    );
  }
}

export default withStyles(styles, {withTheme: true})(HeatChart);
