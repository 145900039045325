import {useLocation, useRouteMatch} from 'react-router-dom';

export function useQuery() {
  const query = new URLSearchParams(useLocation().search);
  const {id: urlProposalId = ''} = useRouteMatch<{id: string}>('/p/:id')?.params ?? {};

  const noPagination = query.get('$noPagination') === 'true';
  const noLazy = query.get('$noLazy') === 'true';
  const noMap = query.get('$noMap') === 'true';
  const qsToken = query.get('$token');

  return {noPagination, noLazy, noMap, qsToken, urlProposalId};
}
