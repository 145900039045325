import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: '0.8rem',
    },
  });
