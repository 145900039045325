import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      maxWidth: 650,
      margin: '30px 0',
      lineHeight: 1.65,
    },
  });
