import {createAction} from 'redux-act';

import {CsvField} from 'models/Csv';
import Proposal from 'models/Proposal';

export const actions = {
  setName: createAction<Proposal['name']>('manage/SET_NAME'),
  setDescription: createAction<Proposal['description']>('manage/SET_DESCRIPTION'),
  setStatus: createAction<Proposal['status']>('manage/SET_STATUS'),
  setDateRange: createAction<Pick<Proposal, 'startDate' | 'endDate'>>('manage/SET_RANGE_DATE'),
  setBrandImageUrl: createAction<Proposal['brandImageUrl']>('manage/SET_BRAND_IMAGE'),
  setArquiveDate: createAction<Proposal['arquiveDate']>('manage/SET_ARQUIVE_DATE'),
  setEndDate: createAction<Proposal['endDate']>('manage/SET_END_DATE'),
  setRate: createAction<Pick<Proposal, 'netRate' | 'adjustedRate'>>('manage/SET_RATE'),
  setProposal: createAction<Proposal>('manage/SET_PROPOSAL'),
  setSuggestedRate: createAction<number>('manage/SET_SUGGESTED_RATE'),
  addCsvFields: createAction<CsvField[]>('manage/ADD_CSV_FIELD'),
  setCsvFields: createAction<CsvField[]>('manage/SET_CSV_FIELDS'),
  deleteCsvField: createAction<CsvField>('manage/DELETE_CSV_FIELD'),
};
