import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      margin: '60px 0',
      lineHeight: 1.65,
    },
    formControl: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: 500,
      margin: '20px 0',
    },
    textField: {
      width: '100%',
      marginBottom: 30,
    },
    brandField: {
      '& span': {
        color: '#666',
      },
    },
    brandPreview: {
      height: 80,
      width: 250,
      backgroundColor: '#EEE',
      margin: '10px 0 30px',
      borderRadius: 8,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
