import React from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import LoggedUser from 'components/common/LoggedUser';
import SectionManageCasesFiles from 'components/common/SectionManageCasesFiles';
import SectionManageCasesSummary from 'components/common/SectionManageCasesSummary';
import {page, PageProps} from 'hoc/page';

import {styles} from './styles';
import {PrivatePageProps} from 'contexts/privateRoute';

type Props = PrivatePageProps & WithStyles<typeof styles> & PageProps;

class Cases extends React.Component<Props> {
  render() {
    const {classes, loggedUser} = this.props;

    return (
      <div className={classes.root}>
        <main className={classes.main}>
          <LoggedUser email={loggedUser} />
          <SectionManageCasesSummary />
          <SectionManageCasesFiles />
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(page(Cases, 'Manage Cases'));
