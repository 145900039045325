import {createStyles} from '@material-ui/core/styles';

import {ProposalStatus} from 'models/Proposal';

export const styles = () =>
  createStyles({
    root: {
      'width': 200,
      'height': '100vh',
      'borderRight': '1px solid #E5E5E5',
      'padding': 20,
      'display': 'flex',
      'flexDirection': 'column',
      'alignItems': 'flex-end',
      'position': 'fixed',
      'overflowY': 'auto',
      'overflowX': 'hidden',
      '-webkit-overflow-scrolling': 'touch',

      '@media print': {
        display: 'none',
      },
    },
    saveDisabled: {
      color: '#fff',
      backgroundColor: '#e0e0e0',
      backgroundImage: 'linear-gradient(to right, #e0e0e0 10%, #e0e0e0 100%)',
    },
    menuClosed: {
      'opacity': 1,
      'transition': 'all 400ms ease',
      '@media (max-width: 500px)': {
        opacity: 0,
      },
    },
    menuOpen: {
      opacity: 1,
      transition: 'all 400ms ease',
    },
    status: {
      textTransform: 'uppercase',
      textAlign: 'right',
      fontWeight: 700,
      fontSize: 12,
      marginTop: 8,
      marginRight: 10,
      color: '#FF9800',
    },
    [ProposalStatus.draft]: {
      color: '#FF9800',
    },
    [ProposalStatus.published]: {
      color: '#4CAF50',
    },
    [ProposalStatus.archived]: {
      color: '#9E9E9E',
    },
    navContext: {
      position: 'absolute',
      display: 'flex',
      left: 0,
      top: 100,
      transition: 'all 0.25s ease-out',
    },
    sectionGroup: {
      width: 200,
      paddingRight: 14,
      transition: 'all 0.15s',
    },
    section: {
      margin: '20px 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    menu: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    group: {
      position: 'sticky',
    },
    actionBtn: {
      fontSize: '0.8rem',
      fontWeight: 400,
      padding: '12px 20px',
      boxShadow: 'none',
    },
    btnSm: {
      minWidth: 40,
    },
  });
