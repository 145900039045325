import React from 'react';
import classNames from 'classnames';

import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';

import {styles} from './styles';

type Props = WithStyles<typeof styles> & {
  menuOpen: boolean;
};

class Container extends React.Component<Props> {
  render() {
    const {menuOpen, children, classes} = this.props;
    return <div className={classNames(classes.root, menuOpen && classes.menuOpen)}>{children}</div>;
  }
}

export default withStyles(styles)(Container);
