import React from 'react';
import {Component} from 'react';
import PageLinearProgress from 'components/common/PageLinearProgress';

type Props = {};
const initialState = {
  loading: false,
};
type State = typeof initialState;

export default class RouterLinearProgress extends Component<Props, State> {
  timer: number = 0;
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  showLoading = () => {
    this.timer = window.setTimeout(() => this.setState({loading: true}), 300);
  };

  hideLoading = () => {
    clearTimeout(this.timer);
    this.setState({loading: false});
  };

  render() {
    const {loading} = this.state;
    return loading ? <PageLinearProgress /> : null;
  }
}
