import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Layout from 'components/common/Layout';
import ManageAccessPage from 'components/pages/Access';
import AuthPage from 'components/pages/Auth';
import ManageCasesPage from 'components/pages/Cases';
import ManageLogsPage from 'components/pages/LogActivity';
import ManageProposalPage from 'components/pages/Manage';
import ProposalPage from 'components/pages/Proposal';
import ProposalSearchPage from 'components/pages/ProposalSearch';
import {AuthProvider} from 'contexts/auth';
import {PrivateRoute} from 'contexts/privateRoute';

export const App: React.FunctionComponent = () => (
  <AuthProvider>
    <Switch>
      <Route exact path="/p/:id/auth" component={AuthPage} />
      <Route exact path="/" component={ProposalSearchPage} />

      <Route path="/p/:id">
        <Layout>
          <Switch>
            <PrivateRoute exact path="/p/:id" component={ProposalPage} />
            <PrivateRoute exact path="/p/:id/manage" component={ManageProposalPage} />
            <PrivateRoute exact path="/p/:id/manage/access" component={ManageAccessPage} />
            <PrivateRoute exact path="/p/:id/manage/cases" component={ManageCasesPage} />
            <PrivateRoute exact path="/p/:id/manage/logs" component={ManageLogsPage} />
          </Switch>
        </Layout>
      </Route>

      <Redirect to="/" />
    </Switch>
  </AuthProvider>
);
