import {IncomingMessage} from 'http';
import {stringify} from 'querystring';

import UrlEncodedQuery from 'models/UrlEncodedQuery';

import environment from 'config/environment';

import Redirection from '../models/Redirection';

export const paramsToQueryParam = (params: UrlEncodedQuery) => {
  const keys = Object.keys(params);

  return keys.reduce((query, key) => `${query}${key}=${params[key]}&`, '');
};

export function getOrigin(_req?: IncomingMessage) {
  return window.location.origin;
}

export function getDomain(host: string) {
  if (window.location.hostname === 'localhost') {
    return 'localhost';
  }

  const regexParse = new RegExp('[a-z-0-9]{2,63}.[a-z.]{2,5}$');
  return regexParse.exec(host)?.[0];
}

export function getDomainFromWindow() {
  return getDomain(window.location.hostname);
}

export function getDomainFromReq(req: IncomingMessage) {
  const hostName = req.headers.host?.includes(':')
    ? req.headers.host.split(':')[0]
    : req.headers.host || '';

  return getDomain(hostName);
}

export const getAccountLink = () => {
  let link = '';

  if (environment.currentType !== 'production') {
    if (window.location.hostname === 'localhost') {
      link = 'http://localhost';
    } else {
      link = generateLink('account');
    }

    return `${link}:9000`;
  } else {
    return generateLink('account');
  }
};

export const generateLink = (app: string) => {
  const prefix = getPrefix();
  const domain = getDomainFromWindow();
  const protocol = `${window.location.protocol}//`;

  return `${protocol}${prefix}${app}.${domain}`;
};

export const getPrefix = () => {
  const subdomain = getSubdomain();
  if (subdomain === 'proposal.') {
    return '';
  }

  return subdomain;
};

export const getSubdomain = () => {
  if (window.location.hostname === 'localhost') {
    return '';
  }

  const regexParse = /(?:http[s]*:\/\/)*(.*?)\.(?=[^/]*\..{2,5})/i;
  return regexParse.exec(window.location.hostname)?.[0];
};

export function getRedirectionToProposal(id: string, params?: {}) {
  if (params) {
    const stringfiedParams = stringify(params);
    return new Redirection(
      `/proposal?id=${id}?${stringfiedParams}`,
      `/p/${id}?${stringfiedParams}`,
    );
  } else {
    return new Redirection(`/proposal?id=${id}`, `/p/${id}`);
  }
}

export function getRedirectionToAuth(id: string) {
  return new Redirection(`/auth?id=${id}`, `/p/${id}/auth`);
}

export function getRedirectionToIndex() {
  return new Redirection('/index', '/');
}

export function getURLSearchParams(params: object) {
  return new URLSearchParams(Object.entries(params)).toString();
}

export const getDMPCollectionUrl = (collectionId: string) =>
  `https://${getPrefix()}dmp.${getDomainFromWindow()}/#/collections?collection=${collectionId}`;
