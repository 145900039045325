import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {$Call} from 'utility-types';

import {actions} from 'config/redux/global/actions';
import {RootState} from 'config/redux/rootReducer';

const mapState = ({
  app: {
    notification: {message, type, shouldShowNotification},
  },
}: RootState) => ({message, type, shouldShowNotification});

const mapToState = (dispatch) => bindActionCreators({...actions}, dispatch);

export type DataType = $Call<typeof mapState> & $Call<typeof mapToState>;

export default connect(mapState, mapToState);
