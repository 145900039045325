import fetch from 'isomorphic-fetch';

import environment from 'config/environment';

const baseUrl = environment.settings.apiUrl.adi;
const headers = (token) => ({
  'Authorization': `Bearer ${token}`,
  'X-Tenant-ID': 'ofm',
});

const filter = 'name,introduction,about,summary';

export const getDefinitions = async (id: string, token: string, isCluster: boolean) => {
  const type = isCluster ? 'cluster' : 'lsg';

  const res = await fetch(`${baseUrl}/${type}/${id}?_source=${filter}`, {
    headers: headers(token),
  });

  return res.json();
};
