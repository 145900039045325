import React from 'react';
import {PureComponent} from 'react';
import {arrayMove} from 'react-sortable-hoc';
import classNames from 'classnames';

import Button from '@material-ui/core/Button/';
import Grid from '@material-ui/core/Grid';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import AutoComplete, {Option} from 'components/common/AutoComplete';
import {CSV as CsvButton} from 'components/common/Download';
import SectionTitle from 'components/common/SectionTitle';

import List, {Props as ListProps} from './List';
import {styles} from './styles';

interface Props extends WithStyles<typeof styles>, Pick<ListProps, 'items' | 'onDelete'> {
  globalItems: string[];
  labels?: {[key: string]: string};
  onAdd: (items: string[]) => void;
  onChange: (items: string[]) => void;
}

const initialState = {
  selectState: [] as Option[],
};

type State = typeof initialState;

class EditableLessonTable extends PureComponent<Props, State> {
  state = initialState;

  onSortEnd = ({oldIndex, newIndex}) => {
    const {items, onChange} = this.props;
    const newOrder = arrayMove(items, oldIndex, newIndex);
    onChange(newOrder);
  };

  addItems = () => {
    this.props.onAdd(this.state.selectState.map((item) => item.value));
    this.setState(initialState);
  };

  deleteItem = (item: string) => {
    const {labels, onDelete} = this.props;
    if (!!labels) {
      Object.entries(labels).forEach((entry) => {
        if (item === entry[1]) {
          onDelete(entry[0]);
        }
      });
    } else {
      onDelete(item);
    }
  };

  render() {
    const {classes, items, globalItems, labels} = this.props;
    const filteredItems = globalItems.filter((item) => !items.includes(item));

    return (
      <>
        <Grid item id="manageCsv" className={classNames(classes.grid, classes.gridVerticalMargin)}>
          <SectionTitle text="CSV" />
          <Typography className={classes.reducedTopSpace}>
            Add and organize the relevante columns to be exported with the CSV file.
          </Typography>
          <CsvButton size="small" variant="outlined" color="primary" className={classes.topSpace}>
            Download CSV
          </CsvButton>
          {!!items.length && (
            <div className={classes.topSpace}>
              <List
                items={items.map((item) => (labels ? labels[item] : item))}
                onSortEnd={this.onSortEnd}
                onDelete={this.deleteItem}
                lockAxis="y"
                useDragHandle={true}
              />
            </div>
          )}
        </Grid>

        <Grid container justify="space-between" alignItems="center">
          <AutoComplete
            label="Add Column"
            placeholder=""
            isMulti={true}
            onChange={this.onChangeSelect}
            value={this.state.selectState}
            options={filteredItems.map((item) => ({
              label: labels ? labels[item] : item,
              value: item,
            }))}
            classes={{
              root: classes.select,
              controlInputProps: classes.input,
              optionWrapper: classes.option,
              optionSelected: classes.optionSelected,
            }}
          />

          <Button color="primary" onClick={this.addItems}>
            Add
          </Button>
        </Grid>
      </>
    );
  }

  onChangeSelect = (options: Option[]) => {
    this.setState({
      selectState: options,
    });
  };
}

export default withStyles(styles)(EditableLessonTable);
