import createStyles from '@material-ui/core/styles/createStyles';

export const styles = () =>
  createStyles({
    doughtnutHover: {
      backgroundColor: '#ddd',
      borderRadius: '4px',
      transition: 'all 0.2s',
    },
    legendsContent: {
      marginLeft: '0.3rem',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    legendItem: {
      padding: '0 10px',
      alignSelf: 'flex-start',
      display: 'flex',
    },
    legendItemLoading: {
      alignSelf: 'auto',
    },
    legendLoading: {
      marginBottom: 1,
      width: '100%',
    },
    icon: {
      'borderRadius': '50%',
      'display': 'inline-block',
      'width': '10px',
      'minWidth': '10px',
      'height': '10px',
      'marginTop': '4px',
      'marginRight': '5px',
      '@media print': {
        '-webkit-print-color-adjust': 'exact',
      },
    },
    legend: {
      color: '#666666',
      fontSize: '12px',
      display: 'inline-block',
    },
    maxlegendsMessage: {
      cursor: 'pointer',
      marginTop: 10,
    },
  });
