import {connect as connectRedux} from 'react-redux';
import {combineReducers, Reducer} from 'redux';

import auth from './auth/reducers';
import app from './global/reducers';
import {logs} from './logsActivity';
import manage from './manage/reducers';
import proposal from './proposal/reducers';
import surfaceExplorer from './surfaceExplorer/reducers';

const rootObject = {auth, app, proposal, manage, surfaceExplorer, logs};

export type RootState = {
  readonly [P in keyof typeof rootObject]: ReturnType<typeof rootObject[P]>;
};

export const getState = <A, B>(fn: (state: RootState, external?: A) => B) => fn;

export function connect<S, D, E>(
  mapState: (state: RootState, external?: E) => S,
  mapDispatch: (dispatch: any) => D,
) {
  return connectRedux(mapState, mapDispatch);
}

export type GetConnectType<T> = T extends (
  mapState?: (state: RootState, external?: any) => infer A,
  mapDispatch?: (dispatch: any) => infer B,
) => any
  ? A & B
  : T;

type CombineReducers = {[P in keyof RootState]: Reducer<RootState[P]>};
export const rootReducer = combineReducers(rootObject as CombineReducers);
