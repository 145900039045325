import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';

import SectionTitle from '../SectionTitle';

import {styles} from './styles';

type Props = WithStyles<typeof styles>;

function SectionManageCasesSummary(props: Props) {
  const {classes} = props;

  return (
    <section className={classes.root} id="manage-cases-summary">
      <SectionTitle text="Case Studies" />

      <FormControl component={'fieldset' as 'div'} className={classes.formControl}>
        <TextField
          id="proposal-cases-summary"
          label="Summary"
          multiline
          rows="4"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
    </section>
  );
}

export default withStyles(styles)(SectionManageCasesSummary);
