import React from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import SectionTitle from '../SectionTitle';
import {styles} from './styles';

interface Props extends WithStyles<typeof styles> {
  ownerName: string;
  ownerEmail: string;
  proposalTitle: string;
}

type OutLink = {url?: string} & WithStyles<typeof styles> &
  React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

function OutFrontLink({classes, url = '', children, ...rest}: OutLink) {
  return (
    <Typography variant="body1">
      <a
        href={`https://www.outfrontmedia.com/${url}`}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
        {...rest}
      >
        {children}
      </a>
    </Typography>
  );
}

function SectionMore(props: Props) {
  const {classes, ownerName, ownerEmail, proposalTitle} = props;

  return (
    <section className={classes.root} id="more">
      <SectionTitle text="More" />

      <div className={classes.content}>
        <Typography variant="body1">
          <a
            href={`mailto:${ownerName}<${ownerEmail}>?subject=Contact%20from%20proposal%3A ${proposalTitle}&body=Write%20your%20message`}
            className={classes.link}
            title={`Contact the Proposal Manager ${ownerName}`}
          >
            Contact the Proposal Manager
          </a>
        </Typography>

        <OutFrontLink url="pages/termsofuse.aspx" classes={classes} title="Read the Terms of Use">
          Terms of Use
        </OutFrontLink>

        <OutFrontLink
          url="pages/privacyhighlights.aspx"
          classes={classes}
          title="Read the Privacy Policy"
        >
          Privacy Policy
        </OutFrontLink>

        <OutFrontLink classes={classes} title="Go to Outfrontmedia.com">
          {'https://outfrontmedia.com'}
        </OutFrontLink>
      </div>
    </section>
  );
}

export default withStyles(styles)(SectionMore);
