import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import LoggedUser from 'components/common/LoggedUser';
import SectionManageDetails from 'components/common/SectionManageDetails';
import SectionManagePricing from 'components/common/SectionManagePricing';
import SectionManagePublication from 'components/common/SectionManagePublication';
import {page} from 'hoc/page';

import connect, {DataType} from './Container';
import {styles} from './styles';

type Props = DataType;

class Manage extends React.Component<Props> {
  render() {
    const {
      token,
      classes,
      loggedUser,
      setDescription,
      setName,
      proposal,
      setDateRange,
      setBrandImageUrl,
      csvColumns,
      addCsvFields,
      setCsvFields,
      deleteCsvField,
    } = this.props;

    return (
      <div className={classes.root} id="manage-main">
        <main className={classes.main}>
          <div id="managePublication">
            <LoggedUser email={loggedUser} />
            {this.renderManagePublication()}
          </div>
          <SectionManageDetails
            token={token}
            proposalId={proposal.id}
            title={proposal.name || ''}
            brandImageUrl={proposal.brandImageUrl}
            onTitleChange={setName}
            description={proposal.description}
            onDescriptionChange={setDescription}
            startDate={proposal.startDate}
            endDate={proposal.endDate}
            collectionId={proposal.collectionId}
            collectionName={proposal._collection ? proposal._collection.name : ''}
            onRangeChange={setDateRange}
            onBrandUploadFinish={setBrandImageUrl}
            csvColumns={csvColumns}
            addCsvFields={addCsvFields}
            setCsvFields={setCsvFields}
            deleteCsvField={deleteCsvField}
          />
          {this.renderManagePricingSection()}
        </main>
      </div>
    );
  }

  renderManagePublication = () => {
    const {proposal, setStatus, setArquiveDate} = this.props;
    return (
      <SectionManagePublication
        status={proposal.status}
        onStatusChange={setStatus}
        arquiveDate={proposal.arquiveDate}
        onArquiveDateChange={setArquiveDate}
      />
    );
  };

  renderManagePricingSection = () => {
    const {proposal, setRate, token, suggestedRate, setSuggestedRate} = this.props;
    return (
      <SectionManagePricing
        proposal={proposal}
        onRateChange={setRate}
        token={token}
        suggestedRate={suggestedRate}
        onSuggestedRateChange={setSuggestedRate}
      />
    );
  };
}

export default withStyles(styles)(page(connect(Manage), 'Manage'));
