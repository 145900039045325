import React from 'react';
import {Component} from 'react';
import {ChartData, Doughnut} from 'react-chartjs-2';
import equal from 'fast-deep-equal';

import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import * as chartjs from 'chart.js';
import Circle from 'components/common/Circle';

import ChartDataItem from 'models/ChartDataItem';

import {sum} from 'utils/array';
import {convertToPercentage, toUSLocale} from 'utils/number';

import Legends from './Legends';
import {styles} from './style';

interface Props extends WithStyles<typeof styles> {
  title: string;
  header: string;
  items: ChartDataItem[];
  width?: number;
  height?: number;
  maxlegends?: number;
  maxlegendsMessage?: string;
  loading?: boolean;
  loadingLegends?: number;
  viewAllDialogTitle?: string;
  showAsPercent?: boolean;
}

const initialState = {
  hoverIndex: -1,
  openViewAll: false,
  zeroValues: false,
};
type State = typeof initialState;

const colors = [
  '#D1524D',
  '#BD69E8',
  '#5E44B9',
  '#748EE9',
  '#93DDD8',
  '#EFD77C',
  '#D68A42',
  '#AAAAAA',
  '#FF8A65',
  '#A1887F',
  '#EC407A',
  '#7E57C2',
  '#42A5F5',
  '#26C6DA',
  '#66BB6A',
  '#D4E157',
  '#FFCA28',
  '#FFCA28',
  '#4DD0E1',
  '#A5D6A7',
];

const emptyColor = '#ccc';

class DoughnutChart extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    width: 180,
    height: 180,
    loadingLegends: 4,
  };

  private options: chartjs.ChartOptions;
  private chart: Doughnut = {chartInstance: null} as Doughnut;
  private data: ChartData<chartjs.ChartData>;

  constructor(props: Props) {
    super(props);
    this.state = initialState;

    this.options = {
      responsive: false,
      legend: {
        display: false,
        position: 'right',
        labels: {
          usePointStyle: true,
        },
      },
      onHover: this.onOptionMouseHover,
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            let value: string;
            if (this.state.zeroValues) {
              value = '0';
            } else {
              value = toUSLocale(data.datasets[0].data[tooltipItem.index]);
            }
            return this.props.showAsPercent ? value + '%' : value;
          },
        },
      },
    };
    this.data = {
      datasets: [
        {
          data: [] as number[],
          backgroundColor: colors,
        },
      ],
      labels: [] as string[],
    };
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !equal(nextProps, this.props) || !equal(nextState, this.state);
  }

  componentDidMount() {
    this.setZeroValues(this.props.items);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!equal(nextProps.items, this.props.items)) {
      this.setZeroValues(nextProps.items);
    }
  }

  setZeroValues = (items: Props['items']) => {
    this.setState({zeroValues: (items || []).every((i) => i.value === 0)});
  };

  onOptionMouseHover = (e: MouseEvent) => {
    if (this.chart.chartInstance == null) {
      return;
    }
    let hoverIndex = -1;
    const element = this.chart.chartInstance.getElementAtEvent(e)[0];
    const currentHoverIndex = this.state.hoverIndex;

    if (element) {
      hoverIndex = element._index;
    }

    if (currentHoverIndex !== hoverIndex) {
      this.setState({hoverIndex});
    }
  };

  closeViewAll = () => this.setState({openViewAll: false});
  openViewAll = () => this.setState({openViewAll: true});

  setLabelsAndDataSet = () => {
    const {items, maxlegends, showAsPercent: isPercentValue} = this.props;

    const sliceIndex = !maxlegends || maxlegends + 1 === items.length ? items.length : maxlegends;

    this.data.labels = items.slice(0, sliceIndex).map((i) => i.key);
    this.data.datasets[0].data = items.slice(0, sliceIndex).map((i) => i.value);

    if (maxlegends && maxlegends + 1 < items.length) {
      const everythingElse = sum(items.map((i) => i.value).slice(sliceIndex));
      this.data.datasets[0].data.push(everythingElse);
      this.data.labels.push('Everything else');
    }

    if (isPercentValue) {
      const total = sum(this.data.datasets[0].data);
      this.data.datasets[0].data = this.data.datasets[0].data.map((i) =>
        convertToPercentage(i, total),
      );
    }

    if (this.state.zeroValues) {
      this.data.datasets[0].data = [1];
      this.data.datasets[0].backgroundColor = Array(colors.length).fill(emptyColor);
    } else {
      this.data.datasets[0].backgroundColor = colors;
    }
  };

  render() {
    const {
      classes,
      items,
      height,
      title,
      header,
      width,
      maxlegends,
      maxlegendsMessage,
      loading,
      loadingLegends,
    } = this.props;

    this.setLabelsAndDataSet();

    const backgroundColors = this.data.datasets[0].backgroundColor;

    const {hoverIndex, openViewAll} = this.state;

    return (
      <div className={classes.root}>
        {header && (
          <Typography classes={{root: classes.header}} variant="h5">
            {header}
          </Typography>
        )}
        {title && (
          <Typography classes={{root: classes.title}} variant="h4">
            {title}
          </Typography>
        )}
        <div className={classes.chartContent}>
          {loading ? (
            <Circle size={width} color={''} className="loading-text-line" />
          ) : (
            <Doughnut
              ref={(ref) => (this.chart = ref ?? ({chartInstance: null} as Doughnut))}
              data={this.data}
              options={this.options}
              width={width}
              height={height}
            />
          )}
          <Legends
            loading={loading}
            loadingLegends={loadingLegends}
            maxlegends={maxlegends}
            maxlegendsMessage={maxlegendsMessage}
            items={items}
            backgroundColors={backgroundColors}
            hoverIndex={this.state.zeroValues ? initialState.hoverIndex : hoverIndex}
            openViewAll={this.openViewAll}
            labels={this.data.labels}
          />
        </div>
        <ViewMoreDialog open={openViewAll} onClose={this.closeViewAll} {...this.props} />
      </div>
    );
  }
}

type ViewMoreDialogProps = {open: boolean; onClose: () => void} & Props;

const ViewMoreDialog = ({
  viewAllDialogTitle,
  open,
  onClose,
  classes,
  ...otherProps
}: ViewMoreDialogProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {viewAllDialogTitle && <DialogTitle id="alert-dialog-title">{viewAllDialogTitle}</DialogTitle>}
    <DialogContent className={classes.dialogContent}>
      <DoughnutChart
        {...otherProps}
        classes={classes}
        maxlegends={undefined}
        width={200}
        height={200}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" autoFocus>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);
export default withStyles(styles)(DoughnutChart);
