import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';

import Proposal from 'models/Proposal';
import User from 'models/User';

import {actions as authActions} from '../auth/actions';
import {actions as surfaceExplorerActions} from '../surfaceExplorer';
import {actions} from './actions';

export const initialState = {
  item: {} as Proposal,
  panelCount: null as number | null,
};

const editUsers = (state: Proposal, users: User[]) => (!state ? state : {...state, users});

const item = createReducer({}, initialState.item)
  .on(authActions.clearUserInfo, (_state) => initialState.item)
  .on(actions.addUser, (state, user) => editUsers(state, [...(state.users || []), user]))
  .on(actions.removeUser, (state, {email}) =>
    editUsers(
      state,
      (state.users || []).filter(({email: eMail}) => email !== eMail),
    ),
  )
  .on(actions.updateRawProposal, (_state, payload) => {
    const {_owner, _collection, ...rawProposal} = payload;
    return {..._state, ...rawProposal};
  })
  .on(actions.setProposal, (_state, payload) => payload);

const panelCount = createReducer({}, initialState.panelCount).on(
  surfaceExplorerActions.setMapPanelsCache,
  (_state, payload) => Object.keys(payload).length,
);

export default combineReducers({item, panelCount});
