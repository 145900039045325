import React from 'react';
import {Fragment} from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import SectionTitle from '../SectionTitle';

import {styles} from './styles';

type Props = WithStyles<typeof styles>;

function SectionManageCasesFiles(props: Props) {
  const {classes} = props;

  return (
    <Fragment>
      <section className={classes.root} id="manage-cases-add">
        <SectionTitle text="Add Case" />

        <FormControl component={'fieldset' as 'div'} className={classes.formControl}>
          <div className={classes.uploadField}>
            <Typography variant="caption">Upload case file</Typography>

            <IconButton aria-label="Upload case file">
              <CloudUploadIcon />
            </IconButton>

            <FormHelperText>Acceptable files: Images, PowerPoint, PDF, and ZIP.</FormHelperText>
          </div>

          <div className={classes.textFieldGroup}>
            <TextField
              className={classes.textField}
              id="proposal-case-title-input"
              label="Case Title"
              required={true}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <div>
              <Button size="small" className={classes.btn}>
                Add
              </Button>
            </div>
          </div>
        </FormControl>
      </section>

      <FileList />
    </Fragment>
  );
}

export default withStyles(styles)(SectionManageCasesFiles);

type FileListProps = WithStyles<typeof styles>;

const FileList = withStyles(styles)(({classes}: FileListProps) => {
  return (
    <section className={classes.root} id="manage-cases-list">
      <SectionTitle text="Proposal Cases" />

      <div className={classes.list} id="cases-list">
        <Typography variant="body2" gutterBottom>
          No cases added to this proposal yet.
          <br />
        </Typography>

        <List>
          {[0, 1, 2, 3].map((value) => (
            <ListItem key={value} className={classes.listItem}>
              <ListItemText
                primary={`Line item ${value + 1}`}
                secondary="Added Oct 25, 2018 at 3:45 PM"
              />

              <ListItemSecondaryAction>
                <IconButton aria-label="Open case menu">
                  <MoreVertIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </section>
  );
});
