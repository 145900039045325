import React from 'react';
import dayjs from 'dayjs';

import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import {styles} from './styles';

type Props = WithStyles<typeof styles> & {
  text: string;
  updateDate: string;
};

const format = 'MMMM DD, YYYY [at] H:mm A';

function MainTitle(props: Props) {
  const {classes, text, updateDate} = props;

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        {text}
      </Typography>

      <Typography variant="body1" className={classes.caption}>
        This proposal was last updated on{' '}
        <time dateTime={updateDate}>{dayjs(updateDate).format(format)}</time>
      </Typography>
    </div>
  );
}

export default withStyles(styles)(MainTitle);
