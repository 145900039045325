import {
  GroupItems,
  InfoBaseChartData,
} from 'components/common/DetailView/Demographics/GeneralInfo/InfoBase/InfoBaseChart';
import _get from 'lodash.get';
import groupby from 'lodash.groupby';

import ChartDataItem from 'models/ChartDataItem';
import Demographics, {DemographicsDay, InfoBase, LiveGroup} from 'models/Demographics';
import {DemographicsDaysEnum} from 'models/Demographics';

import mapFinancialCountData from 'utils/demographics/mapFinancialCount';
import mapInfoBaseData from 'utils/demographics/mapInfoBaseData';
import mapInterestCountData from 'utils/demographics/mapInterestCount';
import mapPersonicxData from 'utils/demographics/mapPersonicxData';
import {isEmpty} from 'utils/object';

const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const avgKeys = [
  'Mean_Age_of_First_Individual',
  'Median_Age_of_First_Individual',
  'Mean_Household_Size',
];

const processWeekDaysAvgInfoBase = (
  demographics: Demographics,
  panelCount: number,
): Demographics => {
  const weekDaysAvg = weekDays.reduce((result, day) => {
    const demographicsDay = demographics[day];
    const demographicsDayInfoBase = demographicsDay.infoBaseCount;

    const avg = avgKeys.reduce(
      (acc, key) => ({...acc, [key]: demographicsDayInfoBase[key] / panelCount}),
      {},
    );

    demographicsDay.infoBaseCount = {...demographicsDayInfoBase, ...avg};

    return {...result, [day]: demographicsDay};
  }, {});

  return {...demographics, ...weekDaysAvg};
};

const processSummaryAvgInfoBase = (demographics: Demographics) => {
  const avgInfoBase = avgKeys.reduce(
    (acc, key) => ({
      ...acc,
      [key]:
        weekDays.reduce((sum, day) => sum + demographics[day].infoBaseCount[key], 0) /
        weekDays.length,
    }),
    {},
  );

  demographics.summary.infoBaseCount = {
    ...demographics.summary.infoBaseCount,
    ...avgInfoBase,
  };

  return demographics;
};

const defaultDay = {
  totPopCount: 0,
  popCountByHour: [],
  liveGroupCount: {} as LiveGroup,
  infoBaseCount: {} as InfoBase,
  financialCount: {} as InfoBase,
  interestsCount: {} as InfoBase,
  claritasLifeGroupCount: {} as LiveGroup,
} as DemographicsDay;

export const processDefaultValues = (demographics: Demographics = {}) => {
  if (!demographics.summary) {
    demographics.summary = defaultDay;
    impressionsDaysOptions.forEach((day) => (demographics[day] = defaultDay));
  } else {
    impressionsDaysOptions.forEach((day) => {
      if (!demographics[day]) {
        demographics[day] = defaultDay;
      }
    });
  }

  return demographics;
};

export const processAvgInfoBase = (demographics: Demographics, panelCount: number) => {
  if (panelCount > 1) {
    demographics = processWeekDaysAvgInfoBase(demographics, panelCount);
  }
  if (demographics.summary) {
    demographics = processSummaryAvgInfoBase(demographics);
  } else {
    demographics.summary = {
      totPopCount: 0,
      popCountByHour: [],
      liveGroupCount: {},
      infoBaseCount: {},
      financialCount: {},
      interestsCount: {},
    } as any;
  }
  return demographics;
};

export const mapLiveGroupProps = (data: LiveGroup = {}): ChartDataItem[] =>
  Object.keys(data).map((key) => {
    const item = data[key];

    let sum = 0;

    const children = Object.keys(item.clusters).map((clusterKey) => {
      const value = Math.floor(item.clusters[clusterKey]);
      sum += value;
      const mapPersonicxDataClusterItem = mapPersonicxData[`${key}.clusters.${clusterKey}`] || {
        id: clusterKey,
        label: clusterKey.replace(/_/g, ' '),
      };
      return {
        id: mapPersonicxDataClusterItem.id,
        key: mapPersonicxDataClusterItem.label,
        value,
      };
    });

    const mapPersonicxDataItem = mapPersonicxData[`${key}.sum`] || {
      id: key,
      label: key.replace(/_/g, ' '),
    };
    return {
      id: mapPersonicxDataItem.id,
      key: mapPersonicxDataItem.label,
      value: Math.floor(sum),
      children,
    } as ChartDataItem;
  });

const GroupOrder = [
  'Income',
  'Ethnicity',
  'Net Worth',
  'Interests',
  'Adult Age',
  'Marital Status',
  'Number of People',
  'Mean Household Size',
  'Number of Adults',
  'Presence of Children',
  'Number of Children',
  'Child Age',
  'Generations',
  'First Individual Education',
  'First Individual Age',
  'Political Party',
];

export const mapInfoBaseProps = (data: InfoBase): InfoBaseChartData => {
  const base = {...mapInfoBaseData, ...mapInterestCountData, ...mapFinancialCountData};
  const dataArray = Object.keys(data)
    .filter((key) => base[key])
    .map((key) => {
      return {
        key: base[key].label,
        value: Math.floor(data[key]),
        group: base[key].group,
      };
    });
  const groupedItems = groupby(dataArray, (i) => i.group);
  const groups = Object.keys(groupedItems).map((key) => {
    return {
      key,
      items: groupedItems[key],
    } as GroupItems;
  });

  const groupsOrdered = GroupOrder.filter((key) => groupedItems[key]).map(
    (key) => ({key, items: groupedItems[key]} as GroupItems),
  );

  return {
    individualItems: _get(
      groups.find((g) => g.key === ''),
      'items',
      [],
    ),
    groups: groupsOrdered,
  } as InfoBaseChartData;
};

export const hasInfoBaseProps = (data: InfoBase) => {
  const {individualItems, groups} = mapInfoBaseProps(data);

  return !!individualItems.length && !!groups.length;
};

const impressionsDaysOptions = [
  DemographicsDaysEnum.monday,
  DemographicsDaysEnum.tuesday,
  DemographicsDaysEnum.wednesday,
  DemographicsDaysEnum.thursday,
  DemographicsDaysEnum.friday,
  DemographicsDaysEnum.saturday,
  DemographicsDaysEnum.sunday,
];

export const getImpressionsByDayByHour = (data: Demographics) => {
  if (!isEmpty(data)) {
    return impressionsDaysOptions.map((day) => data[day].popCountByHour);
  }
};
