import React from 'react';
import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import FormatIndentDecreaseIcon from '@material-ui/icons/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';

import SortByEnum from 'models/SortByEnum';

import {styles} from './styles';

const isSelected = (option: string) => (sortBy: string) =>
  classNames('options', {
    current: sortBy === option,
  });

type Props = {
  expandAll: boolean;
  showExpandButton: boolean;
  sortBy: string;
  onToggleAll: () => void;
  onSortTrigger: (type: SortByEnum) => void;
  switchButton?: JSX.Element;
} & WithStyles<typeof styles>;

export default withStyles(styles, {withTheme: true})(
  ({
    classes,
    sortBy,
    onSortTrigger,
    showExpandButton,
    expandAll,
    onToggleAll,
    switchButton,
  }: Props) => (
    <div className={classes.root}>
      <div className={classes.content}>
        <span>SORT BY:</span>
        <span
          onClick={() => onSortTrigger(SortByEnum.az)}
          className={isSelected(SortByEnum.az)(sortBy)}
        >
          A/Z
        </span>
        |
        <span
          onClick={() => onSortTrigger(SortByEnum.count)}
          className={isSelected(SortByEnum.count)(sortBy)}
        >
          Count
        </span>
      </div>
      {showExpandButton && (
        <Tooltip title={expandAll ? 'Expand All' : 'Collapse All'} placement="left">
          <IconButton color="primary" className={classes.transform} onClick={onToggleAll}>
            {expandAll ? <FormatIndentIncreaseIcon /> : <FormatIndentDecreaseIcon />}
          </IconButton>
        </Tooltip>
      )}
      {!!switchButton && switchButton}
    </div>
  ),
);
