import React from 'react';
import {SFC} from 'react';
import {CSSProperties} from 'react';
import * as Uploader from 'react-s3-uploader';
import UploadedFileData from 'models/UploadedFileData';

interface S3UploaderProps {
  id?: string;
  getSignedUrl: (file: File, cb: (result: UploadedFileData) => void) => void;
  style?: CSSProperties;
  accept: string;
  preprocess: (file: File, next: (file: File) => void) => void;
  onFinish: (result: UploadedFileData) => void;
  onError: (message: string, file: File) => void;
  onProgress: (percent: number) => void;
  inputRef?: (input: HTMLInputElement) => void;
}

const S3Uploader: SFC<S3UploaderProps> = (props: S3UploaderProps) => {
  return (
    <Uploader
      {...props}
      uploadRequestHeaders={{
        'x-amz-acl': 'public-read',
      }}
      contentDisposition="auto"
    />
  );
};

S3Uploader.displayName = 'S3Uploader';

S3Uploader.defaultProps = {
  preprocess: () => null,
};

export default S3Uploader;
