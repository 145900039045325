import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      display: 'flex',
    },
    main: {
      'marginLeft': 200,
      'padding': '40px 50px',
      'width': '100%',
      'position': 'relative',
      'backgroundColor': '#FFF',

      '@media (max-width: 500px)': {
        marginLeft: 0,
        padding: 20,
      },
    },
  });
