import {createStyles, Theme} from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      'margin': '60px 0',
      'lineHeight': 1.65,

      '@media print': {
        breakBefore: 'page',
      },
    },
    content: {
      'maxWidth': 900,
      'margin': '30px 0',
      '@media print': {
        maxWidth: '100%',
      },
    },
    printableGrid: {
      '@media print': {
        flex: 1,
      },
    },
    titleContent: {
      'display': 'flex',
      'alignItems': 'center',
      '@media (max-width: 500px)': {
        flexDirection: 'column',
        alignItems: 'initial',
      },
    },
    formRoot: {
      'display': 'flex',
      'flexWrap': 'wrap',
      '@media print': {
        display: 'none',
      },
    },
    formControl: {
      'margin': theme.spacing.unit,
      'marginLeft': 20,
      '@media (max-width: 500px)': {
        marginLeft: 0,
        marginBottom: 20,
      },
      'minWidth': 120,
    },
    weekSelectMenu: {
      paddingLeft: 16,
      paddingTop: 11,
      paddingBottom: 11,
    },
    chartTitle: {
      'fontSize': '1.1rem',

      'padding': '20px 0',
      'color': '#666',
      'backgroundColor': 'rgba(255,255,255,0.98)',
      'fontWeight': 400,
      'letterSpacing': -0.8,

      '@media (max-width: 500px)': {
        fontSize: '1.1rem',
        width: 'calc(100% + 40px)',
        padding: 20,
        transform: 'translateX(-20px)',
      },
    },
    doughnutChart: {
      width: 360,
    },
    infoBaseRoot: {
      '@media print': {
        breakBefore: 'page',
        marginTop: 30,
      },
    },
    infoBaseWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    infoBaseContent: {
      'display': 'flex',
      'flexWrap': 'wrap',
      '@media print': {
        width: '120%',
      },
    },
    screen: {
      '@media print': {
        display: 'none',
      },
    },
    print: {
      'display': 'none',

      '@media print': {
        display: 'inline',
      },
    },
  });
