import fetch from 'isomorphic-fetch';

import environment from 'config/environment';
import PaginatedResponse from 'models/PaginatedResponse';
import {Panel} from 'models/Panel';
import UrlEncodedQuery from 'models/UrlEncodedQuery';
import {paramsToQueryParam} from 'utils/url';

const madBaseUrl = environment.settings.apiUrl.mad;

type PartialPanel = Partial<Panel> & Pick<Panel, 'id'>;

const getFilterKeepingOrderQuery = (params: UrlEncodedQuery) => {
  const ids = params.$ids;
  const sort = params.$sort ? [{[params.$sort.split(':')[0]]: params.$sort.split(':')[1]}] : [];

  return {
    _source: params.$select ? params.$select.split(',') : false,
    sort,
    from: params.$from,
    size: params.$limit || 10000,
    query: {
      function_score: {
        boost_mode: 'replace',
        query: {
          bool: {
            must: [
              {
                query_string: {
                  query: params.$q ? `${params.$q}` : '*',
                  default_operator: params.$operator || 'and',
                },
              },
            ],
            filter: {
              ids: {
                values: ids,
              },
            },
          },
        },
        script_score: {
          script: {
            params: {
              ids,
            },
            source: 'params.ids.length - params.ids.indexOf(doc["_id"].value)',
          },
        },
      },
    },
  };
};

const filterKeepingOrder = async (
  params: UrlEncodedQuery,
  token: string,
): Promise<PaginatedResponse<PartialPanel | string>> => {
  const query = getFilterKeepingOrderQuery(params);

  const {scroll, minify} = params;
  const urlParams = paramsToQueryParam(Object.assign({}, scroll && {scroll}, minify && {minify}));

  const res = await fetch(`${madBaseUrl}/search?${urlParams}`, {
    method: 'POST',
    body: JSON.stringify(query),
    headers: {
      'Authorization': `Bearer ${token}`,
      'X-Tenant-ID': 'ofm',
    },
  });

  if (!res.ok) {
    throw res;
  }

  return res.json();
};

export const filterPanelsKeepingOrder = async (
  params: Omit<UrlEncodedQuery, 'scroll' | 'minify' | '$ids'> &
    Required<Pick<UrlEncodedQuery, '$ids'>>,
  token: string,
): Promise<PaginatedResponse<PartialPanel>> => {
  const res = filterKeepingOrder(params, token);

  return res as Promise<PaginatedResponse<PartialPanel>>;
};

type PanelKeys = keyof Panel;

export const getPanel = async (
  panelId: string,
  token: string,
  source: PanelKeys[] = [],
): Promise<Partial<Panel>> => {
  const res = await fetch(`${madBaseUrl}/${panelId}?_source=${source.join(',')}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'X-Tenant-ID': 'ofm',
    },
  });

  if (!res.ok) {
    throw res;
  }

  return res.json();
};
