import createStyles from '@material-ui/core/styles/createStyles';

export const styles = () =>
  createStyles({
    grid: {
      maxWidth: 650,
      width: '100%',
    },
    gridVerticalMargin: {
      marginTop: 30,
      marginBottom: 30,
    },
    title: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 26,
      fontWeight: 700,
      letterSpacing: -1,
      lineHeight: 1.4,
    },
    topSpace: {
      marginTop: '1.5rem',
    },
    reducedTopSpace: {
      marginTop: -10,
      marginBottom: -10,
    },
    select: {
      flex: 1,
      minWidth: '75%',
      marginRight: 20,
      zIndex: 10,
    },
    option: {
      '&:hover': {
        backgroundColor: '#f2f2f2 !important',
      },
      'height': 18,
      'padding': '8px 12px',
    },
    optionSelected: {
      backgroundColor: '#f2f2f2 !important',
    },
    input: {
      minHeight: 49,
    },
  });
