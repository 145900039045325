import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      'fontSize': '1.6rem',
      'fontWeight': 700,
      'letterSpacing': -1,
      'lineHeight': 1.4,
      'padding': '20px 0',

      '@media (max-width: 500px)': {
        fontSize: '1.5rem',
      },
    },
  });
