import React from 'react';
import {generatePath, matchPath, RouteComponentProps, withRouter} from 'react-router-dom';
import {animateScroll, scroller} from 'react-scroll';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {CSV as CsvButton} from 'components/common/Download';
// import {PDF as PdfButton} from 'components/common/Download';
import OutFrontImage from 'components/common/OutFrontImg';

import ColoredIndicator from '../ColoredIndicator';
import MainNavMenu from '../MainNavMenu';
import connect, {DataType} from './Container';
import {HashEnum, ManageMainNavMenus, PageEnum, PublicMainNavMenus} from './NavMenus';
import {styles} from './styles';

const NavWithLogo = ({
  children,
  classes,
  proposalStatus,
  showStatus,
  menuOpen,
  manageMenuIsOpened,
}) => (
  <nav
    className={classNames(classes.root, menuOpen ? classes.menuOpen : classes.menuClosed)}
    id="main-nav"
  >
    <OutFrontImage height="20" />

    {showStatus && (
      <Typography variant="body1" className={`${classes.status} ${classes[proposalStatus]}`}>
        {proposalStatus}
      </Typography>
    )}

    <div
      className={classes.navContext}
      style={{
        transform: manageMenuIsOpened ? 'translateX(-200px)' : 'translateX(0)',
      }}
    >
      {children}
    </div>
  </nav>
);

type Props = WithStyles<typeof styles> &
  DataType &
  RouteComponentProps<{id: string}> & {
    showManage?: boolean;
    menuOpen: boolean;
    handleMenuClick: () => void;
  };
type State = {
  menuActive: PageEnum | HashEnum;
};

class MainNav extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      menuActive: HashEnum.overview,
    };
  }

  componentDidMount() {
    const {location} = this.props;
    this.updateSelectedMenuByRoute(location.pathname);

    window.addEventListener('beforeunload', this.beforeunload);
    window.onscroll = this.onScroll;
  }

  componentWillReceiveProps(nextProps: Props) {
    window.onhashchange = () => {
      const hash = (window.location.hash as HashEnum) || HashEnum.overview;
      this.updateMenuAndScroll(hash);
    };
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.updateSelectedMenuByRoute(nextProps.location.pathname);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload);
    window.onscroll = null;
  }

  updateMenuAndScroll = (elementId: PageEnum | HashEnum) => {
    this.setState({menuActive: elementId});
    this.scrollTo(elementId, elementId);
  };

  updateSelectedMenuByRoute(route: string) {
    if (matchPath(route, {path: PageEnum.proposal, exact: true})) {
      const hash = (window.location.hash as HashEnum) || HashEnum.overview;
      this.setState({menuActive: hash});
    } else if (matchPath(route, {path: PageEnum.manage})) {
      const hash = (window.location.hash as HashEnum) || HashEnum.managePublication;
      this.setState({menuActive: hash});
    } else {
      this.setState({menuActive: route as PageEnum});
    }
    animateScroll.scrollToTop();
  }

  beforeunload = (e) => {
    if (this.props.hasChangesToSave) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
    }
  };

  onScroll = () => {
    Object.keys(HashEnum).forEach((id) => {
      const element = document.getElementById(id);
      if (!!element) {
        const top = element.getBoundingClientRect().top;
        if (
          top <= 0 &&
          Math.abs(top) < element.scrollHeight &&
          this.state.menuActive !== HashEnum[id]
        ) {
          this.setState({menuActive: HashEnum[id]});
        } else if (
          !!document.getElementById('proposal-main') &&
          window.scrollY + window.innerHeight + 40 >=
            document.getElementById('proposal-main').offsetHeight &&
          !!document.getElementById('pricing') &&
          this.state.menuActive !== HashEnum.pricing
        ) {
          this.setState({menuActive: HashEnum.pricing});
        } else if (
          !!document.getElementById('manage-main') &&
          window.scrollY + window.innerHeight + 40 >=
            document.getElementById('manage-main').offsetHeight &&
          !!document.getElementById('managePricing') &&
          this.state.menuActive !== HashEnum.managePricing
        ) {
          this.setState({menuActive: HashEnum.managePricing});
        }
      }
    });
  };

  scrollTo = (elementId: string, menuActive: string) => (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    window.onscroll = null;
    e.preventDefault();
    this.props.handleMenuClick();
    let delayValue = 0;
    if (window.innerWidth < 500) {
      delayValue = 400;
    }
    scroller.scrollTo(elementId, {
      duration: 500,
      smooth: 'easeInOutQuart',
      spy: true,
      delay: delayValue,
    });
    this.setState({menuActive: `#${menuActive}` as HashEnum});
    setTimeout(() => (window.onscroll = this.onScroll), 600);
  };

  closeManageMenu = () => {
    const {history, match} = this.props;
    const {id} = match.params;
    history.push(`/p/${id}`);
    this.setState({menuActive: HashEnum.overview});
    this.scrollTo('proposal-main', 'overview');
  };

  updateMenuActive = (page: PageEnum | HashEnum) => () => {
    this.setState({menuActive: page});
    this.props.handleMenuClick();
  };

  handleSaveClick = () => {
    const {manageForm, proposalId, token, save} = this.props;
    save({id: proposalId, ...manageForm}, token);
  };

  render() {
    const {
      classes,
      match,
      proposalStatus,
      isAdmin,
      isOwner,
      showManage,
      menuOpen,
      hasPricing,
      location,
    } = this.props;
    const {menuActive} = this.state;
    const {id} = match.params;
    const manageMenuIsOpened = matchPath(location.pathname, {path: PageEnum.manage});

    return (
      <NavWithLogo
        showStatus={isAdmin || isOwner}
        classes={classes}
        proposalStatus={proposalStatus}
        manageMenuIsOpened={manageMenuIsOpened}
        menuOpen={menuOpen}
      >
        <div className={classes.sectionGroup} style={{opacity: manageMenuIsOpened ? 0 : 1}}>
          <div className={`${classes.section} ${classes.menu}`}>
            <PublicMainNavMenus
              id={id}
              menuActive={menuActive}
              scrollTo={this.scrollTo}
              hasPricing={hasPricing}
            />
            {showManage && (
              <MainNavMenu
                href={generatePath(PageEnum.manage, {id})}
                asHref={generatePath(PageEnum.manage, {id})}
                aria-label="Open Manage menu"
              >
                Manage
              </MainNavMenu>
            )}
          </div>

          {this.renderDownloadButtonSection()}
        </div>
        {showManage && this.renderManageMenu()}
      </NavWithLogo>
    );
  }

  renderDownloadButtonSection = () => {
    const {classes} = this.props;
    return (
      <div className={classes.section}>
        <Typography variant="caption" gutterBottom style={{color: '#666'}}>
          Download as
        </Typography>

        <div style={{display: 'flex'}}>
          <CsvButton size="small" color="primary" className={classes.btnSm} />
        </div>
      </div>
    );
  };

  renderManageMenu = () => {
    const {classes, location, match, hasChangesToSave} = this.props;
    const {menuActive} = this.state;
    const {id} = match.params;

    const isAnotherPageActive =
      matchPath(location.pathname, {path: PageEnum.access}) ||
      matchPath(location.pathname, {path: PageEnum.logs});

    return (
      <div className={classes.sectionGroup} style={{transform: 'translateY(-48px)'}}>
        <div className={`${classes.section} ${classes.menu}`}>
          <IconButton color="primary" aria-label="Back to main menu" onClick={this.closeManageMenu}>
            <ArrowBackIcon />
          </IconButton>
          {isAnotherPageActive ? (
            <MainNavMenu
              href={generatePath(PageEnum.manage, {id})}
              asHref={generatePath(PageEnum.manage, {id})}
              active={Boolean(matchPath(location.pathname, {path: PageEnum.manage, exact: true}))}
              onClick={this.updateMenuActive(PageEnum.manage)}
            >
              Manage
            </MainNavMenu>
          ) : (
            <ManageMainNavMenus id={id} menuActive={menuActive} scrollTo={this.scrollTo} />
          )}
          <MainNavMenu
            href={generatePath(PageEnum.access, {id})}
            asHref={generatePath(PageEnum.access, {id})}
            active={Boolean(matchPath(location.pathname, {path: PageEnum.access, exact: true}))}
            onClick={this.updateMenuActive(PageEnum.access)}
          >
            Access
          </MainNavMenu>
          <MainNavMenu
            href={generatePath(PageEnum.logs, {id})}
            asHref={generatePath(PageEnum.logs, {id})}
            active={Boolean(matchPath(location.pathname, {path: PageEnum.logs, exact: true}))}
            onClick={this.updateMenuActive(PageEnum.logs)}
          >
            Logs
          </MainNavMenu>
          <div className={classes.section}>
            {!matchPath(location.pathname, {path: PageEnum.access, exact: true}) &&
              !matchPath(location.pathname, {path: PageEnum.logs, exact: true}) && (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <ColoredIndicator on={hasChangesToSave} />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.actionBtn}
                    classes={{
                      disabled: classes.saveDisabled,
                    }}
                    onClick={this.handleSaveClick}
                    disabled={!hasChangesToSave}
                  >
                    Save
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(withRouter(connect(MainNav)));
