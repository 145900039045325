import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    formControl: {
      'width': '100%',
      'margin': '30px 0',
      '@media (max-width: 900px)': {
        marginTop: 10,
      },
    },
    textField: {
      width: '100%',
    },
    actionBtn: {
      fontSize: '0.8rem',
      fontWeight: 400,
      padding: '12px 20px',
      boxShadow: 'none',
    },
  });
