import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    toolbar: {
      'padding': '20px 50px',
      'display': 'flex',
      'alignItems': 'center',

      '@media (max-width: 400px)': {
        padding: 20,
      },

      '@media (max-width: 800px)': {
        paddingBottom: '0px',
        flexDirection: 'column',
        alignItems: 'initial',
      },

      '@media print': {
        display: 'none',
      },
    },
    surfaceSearch: {
      'flex': 1,
      'display': 'flex',
      'alignItems': 'center',

      '@media (max-width: 400px)': {
        maxWidth: '80%',
      },

      '@media print': {
        display: 'none',
      },
    },
    surfaceSearchInput: {
      width: '100%',
      maxWidth: 400,
      marginRight: 10,
    },
    paginationLabel: {
      'paddingLeft': 50,
      '@media (max-width: 800px)': {
        paddingLeft: 0,
      },
    },
  });
