import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {actions as globalActions} from 'config/redux/global/actions';
import {getState} from 'config/redux/rootReducer';

const mapStateToProps = getState(({proposal: {panelCount}}) => ({
  panelCount,
}));

const mapDispatch = (dispatch) => bindActionCreators({...globalActions}, dispatch);

export type DataType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatch>;

export default connect(mapStateToProps, mapDispatch);
