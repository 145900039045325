import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      margin: '60px 0',
      lineHeight: 1.65,
    },
    content: {
      display: 'flex',
      height: 1000,
      maxWidth: 900,
      backgroundColor: '#EEE',
      margin: '30px 0',
    },
  });
