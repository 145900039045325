import createStyles from '@material-ui/core/styles/createStyles';

export const styles = () =>
  createStyles({
    root: {
      listStyleType: 'none',
      paddingLeft: 0,
    },
    inside: {
      'margin': '4px 0 0 10px',

      '& .$itemDetails': {
        paddingLeft: '10px',
        cursor: 'default',
      },
    },
    item: {
      'padding': 0,
      'position': 'relative',
      'minHeight': '35px',
      'marginBottom': '3px',
      'borderRadius': '3px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
      },
      '& .info-button': {
        'color': 'rgba(0, 0, 0, 0.1)',
        '@media print': {
          display: 'none',
        },
      },
    },
    itemDetails: {
      'position': 'relative',
      'paddingLeft': '0',
      'fontSize': '12px',
      'display': 'flex',
      'height': '35px',
      'alignItems': 'center',
      'padding': '0 5px',
      'cursor': 'pointer',
      '& .count': {
        lineHeight: '20px',
        width: '40px',
        textAlign: 'right',
      },
      '& .infoButton': {
        display: 'none',
        transform: 'translateX(10px)',
      },
      '&:hover .infobutton': {
        display: 'block',
        transform: 'translateX(10px)',
      },
    },
    itemLabel: {
      lineHeight: '20px',
      flex: 1,
    },
    subItemLabel: {
      paddingLeft: 7,
    },
    itemBarIcon: {
      'color': '#e8ad3f',
      'transition': 'all 0.2s',
      'fontSize': '20px',
      '&.open': {
        transform: 'rotate(90deg)',
      },
    },
    bar: {
      'background': 'linear-gradient(to left, #ffde7c, #fff1c8)',
      'borderRadius': '3px',
      'position': 'absolute',
      'margin': 0,
      'top': 0,
      'opacity': 0,
      'height': '35px',
      'animation': '1s chart-horizontal-animation ease forwards',
      'transformOrigin': '0% 50%',
      'maxWidth': '100%',
      'transition': 'all 0.2s',
      '@media print': {
        'background': 'linear-gradient(to left, #ffde7c, #fff1c8)',
        '-webkit-print-color-adjust': 'exact',
      },
    },
    barInside: {
      background: 'linear-gradient(to left, #d4ebff, #e5f3ff)',
    },
    empty: {
      background: 'rgba(0,0,0,0.05)',
    },
  });
