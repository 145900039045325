import React from 'react';
import {Helmet} from 'react-helmet';
import {Subtract} from 'utility-types';

import PageLinearProgress from 'components/common/PageLinearProgress';

export interface PageProps {
  loading: boolean;
  pageTitle: string;
  showLoading(): void;
  hideLoading(): void;
}

const initialState = {
  loading: false,
};

type State = typeof initialState;

export const page = <P extends PageProps>(Component: React.ComponentType<P>, pageTitle?: string) =>
  class Page extends React.Component<Subtract<P, PageProps>, State> {
    static displayName = `Page(${Component.displayName})`;
    state: State = initialState;

    showLoading = () => {
      this.setState({
        loading: true,
      });
    };

    hideLoading = () => {
      this.setState({
        loading: false,
      });
    };

    render() {
      const {loading} = this.state;

      return (
        <>
          {loading && <PageLinearProgress />}

          <Helmet>
            <title>{pageTitle ?? ''} | OUTFRONT Media Proposal</title>
          </Helmet>

          <Component
            {...(this.props as P)}
            loading={loading}
            pageTitle={pageTitle}
            showLoading={this.showLoading}
            hideLoading={this.hideLoading}
          />
        </>
      );
    }
  };
