import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import IconDone from '@material-ui/icons/Done';
import IconInfo from '@material-ui/icons/Info';

import connect, {DataType} from './Container';

const getMessage = (text: string) => text.split(':')[0];
const getDescription = (text: string) => text.split(':')[1];

const refresh = () => window.location.reload();

const actions = {
  loading: () => <CircularProgress color="inherit" size={24} thickness={5} />,
  confirming: () => <IconDone color="inherit" />,
  info: (text = '') => <IconInfo color="inherit" titleAccess={getDescription(text)} />,
  reload: () => (
    <Button color="secondary" size="small" onClick={refresh}>
      Refresh
    </Button>
  ),
} as {[index in DataType['type']]: (text?: string) => React.ReactNode};

const onClose = (callback) => (event, reason) => {
  if (reason !== 'clickaway') {
    callback();
  }
};

export default connect(({message, type, hideNotification, shouldShowNotification}: DataType) => (
  <Snackbar
    key={message}
    open={shouldShowNotification}
    autoHideDuration={['loading', 'reload'].includes(type) ? undefined : 3000}
    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
    onClose={onClose(hideNotification)}
    action={actions[type] ? actions[type](message) : undefined}
    message={<span>{getMessage(message)}</span>}
  />
));
