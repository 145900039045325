import React, {PureComponent, Suspense} from 'react';
import {ButtonProps} from 'react-scroll/modules/components/Button';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import TextField, {TextFieldProps} from '@material-ui/core/TextField';
import {Props as DateRangePickerProps} from 'components/common/DateRangePicker';
import EditableList from 'components/common/EditableList';
import Loader from 'components/common/Loader';
import SectionTitle from 'components/common/SectionTitle';

import {CsvField} from 'models/Csv';
import Proposal, {ProposalCollection} from 'models/Proposal';

import {csvMapping} from 'utils/csv';
import {getDMPCollectionUrl} from 'utils/url';
import {openNewTab} from 'utils/window';

import {styles} from './styles';
import UploadBrand, {Props as UploadBrandProps} from './UploadBrand';

const DateRangePickerLazy = React.lazy(() => import('components/common/DateRangePicker'));

const DateRangePicker = (props) => (
  <Suspense fallback={<Loader />}>
    <DateRangePickerLazy {...props} />
  </Suspense>
);

type Props = WithStyles<typeof styles> &
  Pick<
    Proposal,
    'description' | 'startDate' | 'endDate' | 'collectionId' | 'brandImageUrl' | 'csvColumns'
  > & {
    proposalId: Proposal['id'];
    title: Proposal['name'];
    collectionName: ProposalCollection['name'];
    token: string;
    onTitleChange: (name: string) => void;
    onDescriptionChange: (description: string) => void;
    onBrandUploadFinish: UploadBrandProps['onUploadFinish'];
    onRangeChange: DateRangePickerProps['onDatesChange'];
    addCsvFields: (field: CsvField[]) => void;
    setCsvFields: (fields: CsvField[]) => void;
    deleteCsvField: (field: CsvField) => void;
  };

class SectionManageDetails extends PureComponent<Props> {
  handleOpenCollectionClick = () => {
    openNewTab(getDMPCollectionUrl(this.props.collectionId));
  };

  handleTitleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onTitleChange(evt.target.value);
  };

  handleDescriptionChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onDescriptionChange(evt.target.value);
  };

  render() {
    const {
      proposalId,
      token,
      classes,
      title,
      brandImageUrl,
      onBrandUploadFinish,
      csvColumns,
      addCsvFields,
      setCsvFields,
      deleteCsvField,
    } = this.props;

    return (
      <section className={classes.root} id="manageDetails">
        <SectionTitle text="Details" />

        <FormControl component={'fieldset' as 'div'} className={classes.formControl}>
          <CustomTextField label="Title" required value={title} onChange={this.handleTitleChange} />

          <UploadBrand
            proposalId={proposalId}
            brandImageUrl={brandImageUrl}
            token={token}
            onUploadFinish={onBrandUploadFinish}
          />

          {this.renderFields()}

          <OpenCollectionButton onClick={this.handleOpenCollectionClick} />

          <EditableList
            items={csvColumns}
            globalItems={Object.keys(csvMapping)}
            labels={csvMapping}
            onAdd={addCsvFields as any}
            onChange={setCsvFields as any}
            onDelete={deleteCsvField as any}
          />
        </FormControl>
      </section>
    );
  }

  renderFields = () => {
    const {startDate, endDate, description, collectionName, onRangeChange} = this.props;
    return (
      <>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onRangeChange}
          id="proposal-date-range-input"
          label="Date Range"
          required
        />

        <CustomTextField
          label="Description"
          multiline
          rows="4"
          value={description}
          onChange={this.handleDescriptionChange}
        />

        <CustomTextField
          label="Collection"
          value={collectionName}
          disabled={true}
          helperText={`This proposal is bound to a collection on Smart Scout.
      Update the surfaces on the collection to change the assets on this proposal.`}
        />
      </>
    );
  };
}

const CustomTextField = withStyles(
  styles,
)(({classes, ...others}: TextFieldProps & WithStyles<typeof styles>) => (
  <TextField className={classes.textField} {...others} />
));

CustomTextField.defaultProps = {
  margin: 'normal',
  variant: 'outlined',
  InputLabelProps: {
    shrink: true,
  },
};

const OpenCollectionButton = (props: Pick<ButtonProps, 'onClick'>) => (
  <Button size="small" variant="outlined" color="primary" onClick={props.onClick}>
    Open Collection
  </Button>
);

export default withStyles(styles)(SectionManageDetails);
