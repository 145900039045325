import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import {ErrorMessage, SuccessMessage} from 'components/common/Message';
import OutFrontImage from 'components/common/OutFrontImg';
import SectionTitle from 'components/common/SectionTitle';
import {page, PageProps} from 'hoc/page';

import {auth} from 'services/proposal';

import {getAccountLink} from 'utils/url';

import Form from './Form';
import {styles} from './styles';
import TOS from './TOS';

type Props = WithStyles<typeof styles> & RouteComponentProps<{id: string}> & PageProps;

const initialState = {
  email: '',
  terms: false,
  unauthorized: false,
  success: false,
};
type State = typeof initialState;

class Auth extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  handleOnEmailChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({email: evt.target.value, unauthorized: false, success: false});
  };

  handleOnTermsChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({terms: evt.target.checked});
  };

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    this.props.showLoading();
    event.preventDefault();
    const {
      history,
      match: {params},
    } = this.props;
    try {
      const response = await auth(params.id, this.state.email);

      if (response.code === 'MAGIC_LINK_SENT') {
        this.setState({success: true});
      } else if (response.code === 'OWNER_DETECTED') {
        const accountLink = getAccountLink();
        window.location.assign(
          `${accountLink}/login?redirectTo=${window.location.origin}/p/${params.id}`,
        );
      }
    } catch (error) {
      if (error.statusCode === 404) {
        history.push('/');
      } else if (error.statusCode === 401) {
        this.setState({unauthorized: true});
      }
    }

    this.props.hideLoading();
  };

  render() {
    const {classes, loading} = this.props;
    const {email, terms, unauthorized, success} = this.state;

    return (
      <div className={classes.root}>
        <section className={classes.formSection} id="auth-form">
          <OutFrontImage classes={{root: classes.brand}} height="30" />
          <Form
            loading={loading}
            terms={terms}
            email={email}
            onSubmit={this.handleSubmit}
            onEmailChange={this.handleOnEmailChange}
            onTermsChange={this.handleOnTermsChange}
          />
          {unauthorized && (
            <ErrorMessage
              message={
                'The email provided is not authorized to open this resource. ' +
                'Contact your sales representative for further help.'
              }
            />
          )}
          {success && (
            <SuccessMessage message="An access link for this resource was sent to your email. It will be valid for 24 hours." />
          )}
        </section>

        <section className={classes.tosSection} id="auth-tos">
          <SectionTitle text="Terms Of Use" />

          <TOS />
        </section>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(page(Auth, 'Auth')));
