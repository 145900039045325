import {dataURItoBlob} from './file';

interface ImageDimensions {
  width: number;
  height: number;
}

export const getImageDimensions = (src: string): Promise<ImageDimensions> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({width: img.width, height: img.height});
    img.onerror = reject;
    img.src = src;
  });

export const isImageValid = async (imageUrl: string) => {
  if (!imageUrl) {
    return false;
  }
  try {
    const img = await getImageDimensions(imageUrl);
    return img.width > 1;
  } catch (error) {
    return false;
  }
};

export const resizeImage = (src: string, size: ImageDimensions): Promise<HTMLCanvasElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = size.width;
      canvas.height = size.height;
      ctx?.drawImage(img as any, 0, 0, size.width, size.height);
      resolve(canvas);
    };
    img.onerror = reject;
    img.src = src;
  });
};

export const cropImage = (src: string, size: ImageDimensions): Promise<HTMLCanvasElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const {width, height} = await getImageDimensions(src);
      canvas.width = size.width;
      canvas.height = size.height;
      ctx?.drawImage(
        img as any,
        Math.max(0, (width - size.width) / 2),
        Math.max(0, (height - size.height) / 2),
        size.width,
        size.height,
        0,
        0,
        size.width,
        size.height,
      );
      resolve(canvas);
    };
    img.onerror = reject;
    img.src = src;
  });
};

export const postCanvasToURL = (canvas: HTMLCanvasElement, type?: string, quality?: number) => {
  const img = canvas.toDataURL(type, quality);
  return dataURItoBlob(img);
};
