import React from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import {styles} from './styles';

type Props = WithStyles<typeof styles> & {
  loading: boolean;
  email: string;
  terms: boolean;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onEmailChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onTermsChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
};
const Form = ({classes, email, terms, loading, onSubmit, onEmailChange, onTermsChange}: Props) => (
  <form onSubmit={onSubmit}>
    <Typography variant="body2" style={{marginBottom: 30}}>
      Your email must be authorized to access this resource.
    </Typography>

    <FormControl component={'fieldset' as 'div'} className={classes.formControl}>
      <TextField
        className={classes.textField}
        id="proposal-email-input"
        type="email"
        label="Email Address"
        margin="normal"
        variant="outlined"
        autoFocus={true}
        InputLabelProps={{
          shrink: true,
        }}
        value={email}
        onChange={onEmailChange}
      />

      <FormControlLabel
        control={<Checkbox color="primary" value="tos" checked={terms} onChange={onTermsChange} />}
        label="I accept the terms of use."
      />
    </FormControl>

    <Button
      variant="contained"
      color="primary"
      type="submit"
      className={classes.actionBtn}
      disabled={!terms || !email || loading}
    >
      Submit
    </Button>
  </form>
);

export default withStyles(styles)(Form);
