import {bindActionCreators as bindActions} from 'redux';

import {ButtonProps} from '@material-ui/core/Button';

import {actions} from 'config/redux/global/actions';
import {connect} from 'config/redux/rootReducer';
import {getState} from 'config/redux/rootReducer';

const mapState = getState(
  ({auth: {token}, proposal: {item: proposal}}, external?: ButtonProps) => ({
    token,
    proposal,
    ...external,
  }),
);

const mapToState = (dispatch) =>
  bindActions(
    {
      show: actions.showNotificationLoading,
      hide: actions.hideNotification,
    },
    dispatch,
  );

export type DataType = ReturnType<typeof mapState> & ReturnType<typeof mapToState>;

export default connect(mapState, mapToState);
