export const csvMapping = {
  'id': 'ID',
  'marketName': 'Market Name',
  'marketCode': 'Market Code',
  'mediaName': 'Media Type',
  'marketingImageUrl': 'Marketing Image',
  'panelStatus': 'Status',
  'locationDescription': 'Location',
  'location.primaryStreet': 'Primary Street',
  'location.crossStreet': 'Cross Street',
  'location.neighborhood': 'Neighborhood',
  'location.zip': 'Zip',
  'location.locality': 'Locality',
  'location.county': 'Country',
  'location.state': 'State',
  'location.csa': 'CSA',
  'location.cbsa': 'CBSA',
  'location.blockGroupId': 'Census Block Group',
  'location.censusTractId': 'Census Tract',
  'location.centerGeoPoint': 'Lat/Lon',
  'location.bearing': 'Bearing',
  'facing': 'Facing',
  'format': 'Format',
  'prime': 'Prime',
  'size': 'Size',
  'geopathId': 'Geopath ID',
  'geopathImpressions18': 'Geopath Impressions (18+)',
  'illuminated': 'Illuminated',
  'spotLength': 'Spot Length',
  'loopLength': 'Loop Length',
  'installationCharge': 'Installation Cost',
  'productionCharge': 'Production Cost',
  'productionForced': 'Production Forced',
  'extensionsAllowed': 'Extensions Allowed',
  'netRate': 'Net Rate',
  'grossRate': 'Gross Rate',
};

export const defaultCsvFields: Array<keyof typeof csvMapping> = [
  'id',
  'marketName',
  'marketCode',
  'mediaName',
  'locationDescription',
  'panelStatus',
  'location.centerGeoPoint',
  'location.bearing',
];
