import React from 'react';
import {PureComponent} from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';

import LegendValue from './LegendValue';
import {styles} from './style';

interface Props extends WithStyles<typeof styles> {
  minValue: number;
  maxValue: number;
  timeColumnWidth: number;
  range1: number;
  range2: number;
  range3: number;
  format?: (value: number) => number;
}

class Legend extends PureComponent<Props> {
  getValue = (value: number) => {
    const {format} = this.props;
    return format ? format(value) : value;
  };

  renderTablets = () => {
    const {classes, range2, range3, maxValue} = this.props;
    return (
      <div className={classes.legendContentTablet}>
        <span className={`${classes.legendTablet} ${classes.range1Color}`}>&nbsp;</span>
        {!!range2 && (
          <span className={`${classes.legendTablet} ${classes.range2Color}`}>&nbsp;</span>
        )}
        {!!range3 && (
          <span className={`${classes.legendTablet} ${classes.range3Color}`}>&nbsp;</span>
        )}
        {!!maxValue && (
          <span className={`${classes.legendTablet} ${classes.range4Color}`}>&nbsp;</span>
        )}
      </div>
    );
  };

  render() {
    const {classes, minValue, maxValue, timeColumnWidth, range1, range2, range3} = this.props;

    return (
      <div
        className={classes.legendContent}
        style={{
          marginRight: timeColumnWidth,
        }}
      >
        {this.renderTablets()}

        <div className={classes.legendContentValue}>
          <LegendValue style={{left: '0px'}}>{this.getValue(minValue)}</LegendValue>

          {!!range1 && (
            <LegendValue
              style={{
                left: '0px',
                width: '50%',
                textAlign: 'center',
              }}
            >
              {this.getValue(range1)}
            </LegendValue>
          )}
          {!!range2 && (
            <LegendValue
              style={{
                left: '0px',
                right: '0px',
                textAlign: 'center',
              }}
            >
              {this.getValue(range2)}
            </LegendValue>
          )}

          {!!range3 && (
            <LegendValue
              style={{
                right: '0px',
                width: '50%',
                textAlign: 'center',
              }}
            >
              {this.getValue(range3)}
            </LegendValue>
          )}
          <LegendValue style={{right: '0px'}}>{this.getValue(maxValue)}</LegendValue>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(Legend);
