import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider} from '@material-ui/core/styles';
import ConnectionStatusBar from 'components/common/ConnectionStatusBar';
import NotificationStatusBar from 'components/common/NotificationStatusBar';
import RouterLinearProgress from 'components/common/RouterLinearProgress';

import configureStore from 'config/redux/store';

import {App} from './App';
import getPageContext from 'styles/getPageContext';

const store = configureStore();

const pageContext = getPageContext();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <MuiThemeProvider theme={pageContext.theme} sheetsManager={pageContext.sheetsManager}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />

          <RouterLinearProgress />

          <ConnectionStatusBar />
          <NotificationStatusBar />

          <App />
        </MuiThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
