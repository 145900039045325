import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      'visibility': 'hidden',
      'position': 'fixed',
      'right': 20,
      'top': 20,
      'zIndex': 11,
      'boxShadow': 'none',

      '@media (max-width: 500px)': {
        visibility: 'visible',
      },
    },
  });
