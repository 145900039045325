import React from 'react';
import {SortableElement} from 'react-sortable-hoc';
import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';

import DragHandle from './DragHandle';
import {styles} from './styles';

export interface Props extends WithStyles<typeof styles> {
  item: string;
  order: number;
  onDelete: (id: string) => void;
}

const ListItem = (props: Props) => {
  const {classes, item, order, onDelete} = props;
  const onClick = () => onDelete(item);

  return (
    <div className={classes.lessonRow} key={order}>
      <DragHandle />
      <Typography variant="body2" className={classes.titleFont}>
        {order && `${order}.`} {item && `${item}`}
      </Typography>
      <div className={classNames(classes.rightDisposedItem)}>
        <IconButton onClick={onClick}>
          <ClearIcon color="primary" />
        </IconButton>
      </div>
    </div>
  );
};

export default withStyles(styles)(SortableElement(ListItem));
