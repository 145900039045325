import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {},
    columns: {
      display: 'flex',
    },
    title: {
      color: '#666',
      letterSpacing: -0.1,
      fontSize: '0.8rem',
      marginBottom: '10px',
    },
    avatar: {
      margin: '5px 12px 0 0',
      width: 45,
      height: 45,
    },
    link: {
      color: '#64008F',
      textDecoration: 'none',
    },
    content: {
      minWidth: 150,
      minHeight: 66,
    },
  });
