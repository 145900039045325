import React from 'react';
import MUSnackbar, {SnackbarProps as MUSnackbarProps} from '@material-ui/core/Snackbar';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';

import {styles} from './styles';

type Props = WithStyles<typeof styles> &
  MUSnackbarProps & {
    Icon?: any;
  };

const Snackbar: React.SFC<Props> = (props: Props) => {
  const {classes, Icon, message, ...otherProps} = props;

  return (
    <MUSnackbar
      message={
        <span className={classes.message}>
          <Icon className={classes.icon} />
          {message}
        </span>
      }
      {...otherProps}
    />
  );
};

Snackbar.defaultProps = {
  autoHideDuration: 3000,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
};

export default withStyles(styles)(Snackbar);
