import {createAction} from 'redux-act';

import {InitialState} from './reducers';

export const actions = {
  showLoading: createAction('surfaceExplorer/SHOW_LOADING'),
  hideLoading: createAction('surfaceExplorer/HIDE_LOADING'),
  setTotal: createAction<InitialState['total']>('surfaceExplorer/SET_TOTAL'),
  setSearchTerm: createAction<InitialState['searchTerm']>('surfaceExplorer/SET_SEARCHTERM'),
  updateState: createAction<Partial<InitialState>>('surfaceExplorer/UPDATE_STATE'),
  setMapPanelsCache: createAction<InitialState['mapCachedPanels']>('surfaceExplorer/SET_CACHE'),
  setMapPanels: createAction<InitialState['mapPanels']>('surfaceExplorer/SET_MAP_PANELS'),
  setMapPopUpPanels: createAction<InitialState['mapPopUpPanels']>(
    'surfaceExplorer/SET_MAP_POPUP_PANELS',
  ),
  addMapPopUpCachedPanel: createAction<InitialState['mapPopUpPanels']>(
    'surfaceExplorer/ADD_MAP_POPUP_CACHED_PANEL',
  ),
  clearMapPopUpPanels: createAction('surfaceExplorer/CLEAR_MAP_POPUP_PANELS'),
  setGridPanels: createAction<InitialState['gridPanels']>('surfaceExplorer/SET_GRID_PANELS'),
  setQuery: createAction<InitialState['query']>('surfaceExplorer/SET_QUERY'),
  setGridPagesCache: createAction<InitialState['gridCachedPages']>(
    'surfaceExplorer/SET_PAGES_CACHE',
  ),
  showMapResetButton: createAction('surfaceExplorer/SHOW_MAP_RESET_BUTTON'),
  hideMapResetButton: createAction('surfaceExplorer/HIDE_MAP_RESET_BUTTON'),
  setMapInitialPosition: createAction<InitialState['mapInitialPosition']>(
    'surfaceExplorer/SET_MAP_INITIAL_POSITION',
  ),
  setViewMode: createAction<InitialState['viewMode']>('surfaceExplorer/SET_VIEWMODE'),
  setAudienceSortRequest: createAction<InitialState['audienceSortRequest']>(
    'surfaceExplorer/SET_AUDIENCE_SORT_REQUEST',
  ),
};
