import {scroller} from 'react-scroll';
import equal from 'fast-deep-equal';

import _debounce from 'lodash.debounce';

export function scrollToTopOfList(elementId: string) {
  scroller.scrollTo(elementId, {
    duration: 500,
    smooth: 'easeInOutQuart',
    spy: true,
    offset: 1,
  });
}

type Func = () => void;
export const debounceFuncs = _debounce((funcs: Func[]) => {
  funcs.map((f) => f());
}, 500);

export const equalList = <T extends {id?: string}>(pl1: T[], pl2: T[] = []) => {
  const orderList = (list: T[]) =>
    list
      .slice()
      .map((p) => p.id)
      .sort();

  return equal(orderList(pl1), orderList(pl2));
};
