import Indexed from 'models/Indexed';
import {FilterQueryResponsePanel} from 'models/PanelSearchResponse';

import {searchPanels as internalSearchPanels} from 'services/proposal';

import {indexedToArray} from 'utils/array';

type Props<P> = {
  token: string;
  query: {sort: any};
  searchTerm: string;
  showLoading: () => void;
  mapCachedPanels: Indexed<P>;
  updateState: (p: {loading: boolean; mapPanels: Partial<P>[]}) => void;
  proposalId: string;
};

export class FetchError extends Error {
  static handleThrowError(e: Error) {
    if (e instanceof FetchError) {
      throw e;
    } else {
      throw new Error(`Failed: Request Unavailable`);
    }
  }
}

export const MapPanels = async <P, T extends Props<P>>({
  showLoading,
  searchTerm,
  proposalId,
  query,
  mapCachedPanels,
  token,
  updateState,
}: T) => {
  showLoading();

  let mapPanels: Partial<P>[] = [];

  if (searchTerm) {
    try {
      mapPanels = await getMapPanels({
        proposalId,
        query,
        mapCachedPanels,
        searchTerm,
        token,
      });
    } catch (e) {
      FetchError.handleThrowError(e);
    }
  } else {
    mapPanels = indexedToArray(mapCachedPanels);
  }

  updateState({loading: false, mapPanels});
};

async function getMapPanels<
  P,
  T extends Pick<Props<P>, 'proposalId' | 'query' | 'mapCachedPanels' | 'searchTerm' | 'token'>
>({proposalId, query, mapCachedPanels, searchTerm, token}: T) {
  const {response} = await searchPanels<string>(proposalId, token, {
    limit: 10000,
    minify: true,
    sort: query.sort,
    where: {
      mad: {
        must: {
          id: {terms: Object.keys(mapCachedPanels)},
          search: {query: searchTerm},
        },
      },
    },
  });

  const result = (response.data.panels || []).map((id) => mapCachedPanels[id]);
  // If some result is not in mapCachedPanels means collection has changed.
  if (result.some((p) => !p)) {
    throw new FetchError('Proposal was changed');
  }

  return result;
}

type Payload = Parameters<typeof internalSearchPanels>['1'];
type R = FilterQueryResponsePanel;

export const searchPanels = <T extends R | string = R>(
  proposal: string,
  token: string,
  payload: Payload,
) => {
  return internalSearchPanels<T>(proposal, payload, token, {
    maximumRetry: 10,
    delay: 3000,
  });
};

export const madGridSelect = [
  'location.bearing',
  'location.center',
  'marketCode',
  'mediaName',
  'marketName',
  'marketingImageUrl',
  'locationDescription',
  'panelStatus',
];
