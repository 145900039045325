import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    root: {
      'minHeight': '500px',
      'width': 'calc(100% + 100px)',
      'marginLeft': -50,
      'borderTop': '1px solid #DDD',
      'borderBottom': '1px solid #DDD',
      'position': 'relative',

      '@media (max-width: 1280px)': {
        minHeight: 0,
      },

      '@media (max-width: 500px)': {
        width: 'calc(100% + 40px)',
        marginLeft: -20,
      },

      '@media print': {
        borderTop: 0,
        borderBottom: 0,
      },
    },
    header: {
      backgroundColor: 'rgba(255,255,255,0.98)',
    },
    sticky: {
      position: 'sticky',
      top: 0,
      zIndex: 10,
    },
    progress: {
      height: 1,
      width: '100%',
      position: 'absolute',
    },
    paginationLabel: {
      'paddingLeft': 50,
      '@media (max-width: 800px)': {
        paddingLeft: 0,
      },
    },
    hidden: {
      visibility: 'hidden',
      minHeight: 0,
      height: 0,
    },
    tableView: {
      'height': '105vh',
      'display': 'none',
      '@media print': {
        display: 'grid',
      },
    },
    tableWrapper: {
      '@media print': {
        breakBefore: 'page',
        marginTop: 30,
        padding: '0 30px',
      },
    },
  });
