import React from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import {Omit} from 'utility-types';

import {styles} from './styles';

type Props = WithStyles<typeof styles> & Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'>;

const OutFrontImage: React.SFC<Props> = (props: Props) => {
  const {classes, ...otherProps} = props;

  return (
    <img
      alt="outfront"
      src="https://s3.amazonaws.com/beakyn-ui/OUT_PRIMARY_LOGO_POS_RGB_BLACK.svg"
      className={classes.root}
      {...otherProps}
    />
  );
};

OutFrontImage.defaultProps = {
  height: 30,
};

export default withStyles(styles)(OutFrontImage);
