import {Dispatch} from 'redux';
import {$Call} from 'utility-types';

import {actions as globalActions} from 'config/redux/global';
import {actions as proposalActions} from 'config/redux/proposal';
import {connect, getState} from 'config/redux/rootReducer';
import Proposal from 'models/Proposal';
import User from 'models/User';
import {auth, updateProposal} from 'services/proposal';

import {ProposalStatus} from 'models/Proposal';

const removeUser = (dispatch: Dispatch) => async (
  user: User,
  {id, users: allUsers}: Partial<Proposal>,
  token: string,
) => {
  try {
    dispatch(proposalActions.removeUser(user));
    const users = allUsers.filter(({email}) => email !== user.email);
    const updated = await updateProposal({id, users}, token);

    dispatch(proposalActions.updateRawProposal(updated));
  } catch (err) {
    dispatch(globalActions.showNotificationInfo(`Failed:${err.message || err}`));
    dispatch(proposalActions.addUser(user));
  }
};

const addUser = (dispatch: Dispatch) => async (
  user: User,
  {id, users}: Partial<Proposal>,
  token: string,
) => {
  try {
    dispatch(proposalActions.addUser(user));
    const updated = await updateProposal({id, users: [...users, user]}, token);

    dispatch(proposalActions.updateRawProposal(updated));
  } catch (err) {
    dispatch(globalActions.showNotificationInfo(`Failed:${err.message || err}`));
    dispatch(proposalActions.removeUser(user));
  }
};

const sendEmail = (dispatch: Dispatch) => async (proposal: string, {email}: User) => {
  dispatch(globalActions.showNotificationLoading('Sending emails ...'));
  try {
    await auth(proposal, email);

    dispatch(globalActions.showNotificationConfirming(`Login link sent to ${email}`));
  } catch (err) {
    dispatch(globalActions.hideNotification());
    dispatch(globalActions.showNotificationInfo(`Magic link failed:${err.message || err}`));
  }
};

const mapState = getState(
  ({
    auth: {token},
    proposal: {
      item: {users = [], status, ...proposal},
    },
  }) => ({
    users,
    token,
    isPublished: status === ProposalStatus.published,
  }),
);

const mapToState = (dispatch) => ({
  removeUser: removeUser(dispatch),
  addUser: addUser(dispatch),
  sendEmail: sendEmail(dispatch),
});

export type DataType = $Call<typeof mapState> & $Call<typeof mapToState>;

export default connect(mapState, mapToState);
