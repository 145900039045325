import React from 'react';
import {SFC} from 'react';
import classNames from 'classnames';

import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';

import {styles} from './styles';

interface Props extends WithStyles<typeof styles> {
  on: boolean;
}

const ColoredIndicator: SFC<Props> = (props) => {
  const {classes, on} = props;

  return (
    <div
      className={classNames(classes.root, {
        [classes.on]: on,
      })}
    />
  );
};

export default withStyles(styles)(ColoredIndicator);
