import Demographics, {DemographicsDaysEnum} from 'models/Demographics';
import {sum} from 'utils/array';
import {mapInfoBaseProps, mapLiveGroupProps} from 'utils/demographics/utils';

export const getDayData = (demographics: Demographics, day: DemographicsDaysEnum) => {
  const demographicsDay = demographics && demographics[day];
  if (demographicsDay) {
    const data = {
      ...demographicsDay.infoBaseCount,
      ...demographicsDay.financialCount,
      ...demographicsDay.interestsCount,
    };
    return {
      liveGroup: mapLiveGroupProps(demographicsDay.liveGroupCount),
      infoBase: mapInfoBaseProps(data),
      popCount: Math.floor(demographicsDay.totPopCount || sum(demographicsDay.popCountByHour)),
      popCountByHour: demographicsDay.popCountByHour,
    };
  }
};
