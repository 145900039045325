import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import BarChart from 'components/common/Charts/BarChart';
import HeatChart from 'components/common/Charts/HeatChart';

import {ChartEnum} from 'models/Chart';

import {styles} from './style';

const impressionsXaxis = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const impressionsYaxis = [
  '12am',
  '1am',
  '2am',
  '3am',
  '4am',
  '5am',
  '6am',
  '7am',
  '8am',
  '9am',
  '10am',
  '11am',
  '12pm',
  '1pm',
  '2pm',
  '3pm',
  '4pm',
  '5pm',
  '6pm',
  '7pm',
  '8pm',
  '9pm',
  '10pm',
  '11pm',
];

interface Props extends WithStyles<typeof styles> {
  title?: string;
  data: number[][];
  popCountByHour: number[];
  showButtonLeftMessage?: boolean;
  buttonLeftMessage?: JSX.Element;
  chart: ChartEnum;
  onChartSwitch: () => void;
}

const ChartSwitchButton = (props) => {
  const {classes, chart, onClick, translate} = props;
  return (
    <Tooltip
      style={{transform: `translateX(${translate}px)`}}
      title={chart === ChartEnum.bar ? 'Heat chart' : 'Bar chart'}
      placement="right"
    >
      <IconButton onClick={onClick} color="primary" className={classes.icon}>
        {chart === ChartEnum.bar ? <ViewModuleIcon /> : <InsertChartIcon />}
      </IconButton>
    </Tooltip>
  );
};

const ImpressionsByHourChart = (props: Props) => {
  const {
    classes,
    title,
    data,
    popCountByHour,
    showButtonLeftMessage,
    buttonLeftMessage,
    chart,
    onChartSwitch,
  } = props;

  return (
    <>
      {title && <Typography variant="subtitle1">{title}</Typography>}

      {showButtonLeftMessage ? (
        <div className={classes.leftMessageWrapper}>
          <div className={classes.leftMessage}>{buttonLeftMessage}</div>
          <ChartSwitchButton
            classes={classes}
            chart={chart}
            onClick={onChartSwitch}
            translate="12"
          />
        </div>
      ) : (
        <ChartSwitchButton
          classes={classes}
          chart={chart}
          onClick={onChartSwitch}
          translate="-12"
        />
      )}

      {chart === ChartEnum.heat && (
        <HeatChart
          classes={{root: `${classes.chart} ${classes.heatChart}`}}
          xaxis={impressionsXaxis}
          yaxis={impressionsYaxis}
          data={data}
          format={Math.floor}
        />
      )}
      {chart === ChartEnum.bar && popCountByHour && (
        <BarChart
          classes={{root: `${classes.chart} ${classes.barChart}`}}
          values={popCountByHour}
        />
      )}
    </>
  );
};
export default withStyles(styles)(ImpressionsByHourChart);
