import createStyles from '@material-ui/core/styles/createStyles';

export const styles = () =>
  createStyles({
    chart: {
      marginTop: '1rem',
    },
    icon: {
      '@media print': {
        display: 'none',
      },
    },
    leftMessageWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    leftMessage: {
      flex: 1,
      marginRight: 20,
    },
    barChart: {},
    heatChart: {},
  });
