import React, {Component} from 'react';
import Waypoint from 'react-waypoint';
import dayjs from 'dayjs';

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import _debounce from 'lodash.debounce';

import SectionTitle from '../SectionTitle';
import {DataType} from './Container';
import {styles} from './styles';

type Props = WithStyles<typeof styles> & DataType;
type State = {loading: boolean; isMoreLoading: boolean};

class SectionManageLogs extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {isMoreLoading: false, loading: false};
    this.searchLogs = _debounce(this.searchLogs.bind(this), 1000);
  }

  componentDidMount() {
    this.loadMore();
  }

  async searchLogs(value: string) {
    this.setState({loading: true});
    await this.props.searchLogs(value);
    this.setState({loading: false});
  }

  handleChange = ({target: {value}}) => {
    this.props.setSearch(value);
    this.searchLogs(value);
  };

  loadMore = async () => {
    this.setState({loading: true});
    await this.props.loadMore();
    this.setState({loading: false});
  };

  render() {
    const {classes, data, total, skip, limit, search} = this.props;
    const {loading} = this.state;
    const isMoreToLoading = total > skip + limit;

    return (
      <section className={classes.root} id="manage-logs">
        <SectionTitle text="Activity Logs" />

        <div className={classes.list}>
          <TextField
            key="surface-search-input"
            className={classes.textField}
            id="surface-search-input"
            label="Search Logs"
            margin="dense"
            variant="outlined"
            type="search"
            InputLabelProps={{
              shrink: true,
            }}
            value={search}
            onChange={this.handleChange}
          />

          {data.map((log, index) => (
            <div className={classes.listItem} key={index}>
              <Typography variant="body2" className={classes.caption}>
                <time dateTime={log.date}>{dayjs(log.date).format('MMM DD, YYYY H:mm A')}</time>
              </Typography>

              <Typography variant="body2" className={classes.caption}>
                {log.userEmail}
              </Typography>

              <Typography variant="body1" className={classes.eventText}>
                {log.description}
              </Typography>
            </div>
          ))}
          {isMoreToLoading && <Waypoint onEnter={this.loadMore} />}
          {loading && (
            <div className={classes.paginationProgress}>
              <CircularProgress className={classes.progress} />
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default withStyles(styles)(SectionManageLogs);
