import React from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import {styles} from './styles';

type Props = WithStyles<typeof styles> & {
  text: string;
};

function SectionTitle(props: Props) {
  const {classes, text} = props;

  return (
    <Typography variant="h2" className={classes.root}>
      {text}
    </Typography>
  );
}

export default withStyles(styles)(SectionTitle);
