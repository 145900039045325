import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    uploadButtonWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      marginLeft: 5,
    },
    uploadButton: {
      color: '#666',
    },
    uploadButtonPosition: {
      maxHeight: 48,
    },
    brandPreview: {
      backgroundColor: '#f2f2f2',
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23dddddd' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E")`,
      width: 250,
      maxWidth: 250,
      padding: 10,
      borderRadius: 8,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    brandPreviewPlaceholder: {
      minHeight: 85,
    },
    uploadButtonLoader: {
      marginLeft: -48,
    },
    brandImage: {
      width: '100%',
      height: 'auto',
      maxWidth: 250,
      margin: 'auto',
      backgroundcolor: 'transparent',
    },
    brandImageUploading: {
      opacity: 0.3,
    },
    removeButton: {
      padding: 5,
      marginTop: 5,
    },
  });
