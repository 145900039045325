import React from 'react';
import classNames from 'classnames';

import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import ChartDataItem from 'models/ChartDataItem';

import {styles} from './style';

interface Props extends WithStyles<typeof styles> {
  loading?: boolean;
  loadingLegends?: number;
  maxlegends?: number;
  maxlegendsMessage?: string;
  items: ChartDataItem[];
  hoverIndex: number;
  labels: string[];
  backgroundColors: string[];
  openViewAll: () => void;
}

const Legends = ({
  classes,
  loading,
  loadingLegends = 0,
  maxlegends,
  maxlegendsMessage,
  items,
  labels,
  hoverIndex,
  openViewAll,
  backgroundColors,
}: Props) => (
  <div className={classes.legendsContent}>
    {loading &&
      Array.from({length: loadingLegends}, (l, i) => (
        <Legend key={i} classes={classes} loading={true} />
      ))}

    {!loading &&
      labels.map((label, index) => (
        <Legend
          classes={classes}
          key={index}
          active={index === hoverIndex}
          color={backgroundColors[index]}
        >
          {label}
        </Legend>
      ))}
    {!loading && maxlegends && maxlegends + 1 < items.length && (
      <div className={classes.legendItem}>
        <Typography
          color="primary"
          classes={{root: classes.maxlegendsMessage}}
          onClick={openViewAll}
        >
          {maxlegendsMessage}
        </Typography>
      </div>
    )}
  </div>
);

type LegendProps = Pick<Props, 'loading'> &
  WithStyles<typeof styles> & {color?: string; active?: boolean};

const Legend: React.SFC<LegendProps> = ({classes, loading, color, active, children}) => (
  <div
    className={classNames(classes.legendItem, {
      [classes.doughtnutHover]: active,
      [classes.legendItemLoading]: loading,
    })}
  >
    {color && <span className={classes.icon} style={{backgroundColor: color}} />}
    <Typography
      component="span"
      classes={{
        root: classNames(classes.legend, {
          'loading-text-line': loading,
          [classes.legendLoading]: loading,
        }),
      }}
    >
      {loading ? <span>&nbsp;</span> : children}
    </Typography>
  </div>
);

export default withStyles(styles)(Legends);
