import mapboxgl from 'mapbox-gl';
import {createReducer} from 'redux-act';

import Indexed from 'models/Indexed';
import {Panel} from 'models/Panel';
import {FilterQuery} from 'models/PanelSearchResponse';
import {SortEnum} from 'models/SortEnum';
import SurfaceExplorerViewMode from 'models/SurfaceExplorerViewMode';

import {actions} from './actions';

type PartialPanel = Partial<Panel>;
type PanelInfo = Partial<Panel> &
  Pick<FilterQuery['response']['data']['panels'][number], 'audienceSort'>;
type MapInitialPosition = Pick<mapboxgl.MapboxOptions, 'bearing' | 'center'>;

export const initialState = {
  loading: false,
  total: 0,
  searchTerm: '',
  mapCachedPanels: {} as Indexed<PanelInfo>,
  mapPanels: [] as PartialPanel[],
  mapResetButton: false,
  mapPopUpPanels: [] as PartialPanel[],
  mapPopUpCachedPanels: {} as Indexed<PartialPanel>,
  mapInitialPosition: null as MapInitialPosition | null,
  gridPanels: [] as PartialPanel[],
  query: {
    limit: 60,
    skip: 0,
    sort: {mad: [{marketName: SortEnum.asc}]},
  },
  gridCachedPages: {} as Indexed<PartialPanel[]>,
  viewMode: SurfaceExplorerViewMode.Grid,
  audienceSortRequest: null as FilterQuery['request']['where']['audienceSort'] | null,
};

export default createReducer<typeof initialState>({}, initialState)
  .on(actions.updateState, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .on(actions.showLoading, (state) => ({
    ...state,
    loading: true,
  }))
  .on(actions.hideLoading, (state) => ({
    ...state,
    loading: false,
  }))
  .on(actions.setTotal, (state, payload) => ({
    ...state,
    total: payload,
  }))
  .on(actions.setSearchTerm, (state, payload) => ({
    ...state,
    searchTerm: payload,
  }))
  .on(actions.setMapPanelsCache, (state, payload) => ({
    ...state,
    mapCachedPanels: payload,
  }))
  .on(actions.setMapPanels, (state, payload) => ({
    ...state,
    mapPanels: payload,
  }))
  .on(actions.setMapPopUpPanels, (state, payload) => ({
    ...state,
    mapPopUpPanels: payload,
  }))
  .on(actions.addMapPopUpCachedPanel, (state, payload) => ({
    ...state,
    mapPopUpCachedPanels: {
      ...state.mapPopUpCachedPanels,
      ...payload.reduce(
        (acc, curr) => ({...acc, [curr.id as string]: curr}),
        {} as Indexed<Partial<PartialPanel>>,
      ),
    },
  }))
  .on(actions.clearMapPopUpPanels, (state) => ({
    ...state,
    mapPopUpPanels: initialState.mapPopUpPanels,
  }))
  .on(actions.showMapResetButton, (state) => ({
    ...state,
    mapResetButton: true,
  }))
  .on(actions.hideMapResetButton, (state) => ({
    ...state,
    mapResetButton: false,
  }))
  .on(actions.setGridPanels, (state, payload) => ({
    ...state,
    gridPanels: payload,
  }))
  .on(actions.setQuery, (state, payload) => ({
    ...state,
    query: payload,
  }))
  .on(actions.setGridPagesCache, (state, payload) => ({
    ...state,
    gridCachedPages: payload,
  }))
  .on(actions.setMapInitialPosition, (state, payload) => ({
    ...state,
    mapInitialPosition: payload,
  }))
  .on(actions.setViewMode, (state, payload) => ({
    ...state,
    viewMode: payload,
  }))
  .on(actions.setAudienceSortRequest, (state, payload) => ({
    ...state,
    audienceSortRequest: payload,
  }));
export type InitialState = typeof initialState;
