import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    message: {
      'margin': '30px 0',

      '& svg': {
        fontSize: 34,
      },
      '& p': {
        fontWeight: 400,
      },
    },
    success: {
      '& p': {
        color: '#339a37',
      },
      '& svg': {
        color: '#339a37',
      },
    },
    error: {
      '& p': {
        color: '#BC1D1D',
      },
      '& svg': {
        color: '#BC1D1D',
      },
    },
  });
