import React from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import LinearProgress from '@material-ui/core/LinearProgress';

import {styles} from './styles';

type Props = WithStyles<typeof styles>;
export default withStyles(styles)(({classes}: Props) => (
  <LinearProgress className={classes.root} />
));
