import {StateType} from './Container';
import {get} from 'services/logs';

export default class {
  constructor(private _store: StateType) {}

  loadMore = async () => {
    try {
      const {match, token, search, data, setData, limit, skip: oldSkip} = this._store;
      const skip = oldSkip + limit;
      const result = await get(match.params.id, token, search, {skip, limit});
      setData({...result, data: [...data, ...result.data]});
    } catch (error) {}
  };

  searchLogs = async (value: string) => {
    try {
      const {match, token, setData} = this._store;
      const result = await get(match.params.id, token, value);
      setData(result);
    } catch (error) {}
  };
}
