import React, {Component, Suspense} from 'react';
import {$PropertyType} from 'utility-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import {SelectInputProps} from '@material-ui/core/Select/SelectInput';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import DoughnutChart from 'components/common/Charts/DoughnutChart';
import HorizontalBarChart, {
  HorizontalBarChartProps,
} from 'components/common/Charts/HorizontalBarChart';
import ImpressionsByHourChart from 'components/common/Charts/ImpressionsByHourChart';
import {
  GroupItems,
  InfoBaseChartData,
} from 'components/common/DetailView/Demographics/GeneralInfo/InfoBase/InfoBaseChart';
import Loader from 'components/common/Loader';
import {ErrorMessage} from 'components/common/Message';
import SectionTitle from 'components/common/SectionTitle';

import {ChartEnum} from 'models/Chart';
import Demographics, {DemographicsDaysEnum} from 'models/Demographics';
import Option from 'models/Option';
import SortByEnum from 'models/SortByEnum';

import {getDemographics} from 'services/proposal';

import {collectionSlicer} from 'utils/array';
import {getImpressionsByDayByHour} from 'utils/demographics/utils';
import {toUSLocale} from 'utils/number';
import {isEmpty} from 'utils/object';

import {styles} from './styles';
import {getDayData} from './utils';

const DataTileLazy = React.lazy(() => import('../DataTile'));

const DataTile = (props) => (
  <Suspense fallback={<Loader />}>
    <DataTileLazy {...props} />
  </Suspense>
);

const dayOptions: Option[] = [
  {text: 'Weekly', value: DemographicsDaysEnum.summary},
  {text: 'Monday', value: DemographicsDaysEnum.monday},
  {text: 'Tuesday', value: DemographicsDaysEnum.tuesday},
  {text: 'Wednesday', value: DemographicsDaysEnum.wednesday},
  {text: 'Thursday', value: DemographicsDaysEnum.thursday},
  {text: 'Friday', value: DemographicsDaysEnum.friday},
  {text: 'Saturday', value: DemographicsDaysEnum.saturday},
  {text: 'Sunday', value: DemographicsDaysEnum.sunday},
];

type Props = WithStyles<typeof styles> & {
  proposalId: string;
  token: string;
  onClickPersonicxInfoButton: $PropertyType<HorizontalBarChartProps, 'onClickHorizontalInfoButton'>;
};

const initialState = {
  impressionsByDayByHour: undefined as number[][] | undefined,
  sortBy: SortByEnum.az,
  demographics: {} as Demographics,
  day: DemographicsDaysEnum.summary,
  errorMessage: '',
  loading: true,
  chart: ChartEnum.heat,
};
type State = typeof initialState;
class SectionAudience extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  async componentDidMount() {
    const {proposalId, token} = this.props;
    try {
      const result = await getDemographics(proposalId, token);
      const errorMessage = this.handleGetDemographicsErrors(result);
      if (errorMessage) {
        throw new Error(errorMessage);
      }
      const days = result.days || {};
      const impressionsByDayByHour = getImpressionsByDayByHour(days);
      this.setState({demographics: days, impressionsByDayByHour, loading: false});
    } catch (error) {
      this.setState({errorMessage: error.message, loading: false});
    }
  }

  handleGetDemographicsErrors = (demographics: {statusCode: number; message: string}) => {
    if (isEmpty(demographics)) {
      return 'Demographics data is unavailable';
    } else if (demographics.statusCode === 500) {
      return demographics.message;
    }
  };

  handleSwitchClick = () => {
    const {chart} = this.state;

    this.setState({
      chart: chart === ChartEnum.bar ? ChartEnum.heat : ChartEnum.bar,
    });
  };

  renderImpressionsByDayByHour = () => {
    const {classes} = this.props;
    const {impressionsByDayByHour = [], demographics, day, chart} = this.state;

    const dayData = getDayData(demographics, day);

    const chartLabel =
      chart === ChartEnum.heat
        ? 'Impressions by day and hour'
        : day === DemographicsDaysEnum.summary
        ? 'Aggregated weekly impressions by hour'
        : 'Impressions by hour';

    return (
      <Grid item xs={12} md={6} className={classes.printableGrid}>
        <Typography variant="h4" classes={{root: classes.chartTitle}}>
          {chartLabel}
        </Typography>
        <ImpressionsByHourChart
          data={impressionsByDayByHour}
          popCountByHour={dayData?.popCountByHour ?? []}
          chart={chart}
          onChartSwitch={this.handleSwitchClick}
        />
      </Grid>
    );
  };

  render() {
    const {classes, onClickPersonicxInfoButton} = this.props;
    const {impressionsByDayByHour, sortBy, day, demographics, errorMessage, loading} = this.state;

    const dayData = getDayData(demographics, day);

    return (
      <section className={classes.root} id="audience">
        <div className={classes.titleContent}>
          <Title loading={loading} />
          {dayData && <WeekSelect classes={classes} day={day} onChange={this.setDay} />}
        </div>

        {errorMessage && <ErrorMessage message={errorMessage} />}

        {dayData && (
          <>
            <DataTile
              label={`Average ${day === DemographicsDaysEnum.summary ? 'weekly' : 'daily'} reach`}
              data={toUSLocale(dayData.popCount)}
            />
            <div className={classes.content}>
              <Grid container spacing={40}>
                {impressionsByDayByHour && this.renderImpressionsByDayByHour()}

                <Grid item xs={12} md={6} className={classes.printableGrid}>
                  <Typography variant="h4" classes={{root: classes.chartTitle}}>
                    Personicx
                  </Typography>
                  <HorizontalBarChart
                    sortBy={sortBy}
                    showExpandButton
                    showInfoButton
                    percent
                    items={dayData && dayData.liveGroup}
                    onClickHorizontalInfoButton={onClickPersonicxInfoButton}
                  />
                </Grid>
              </Grid>
            </div>

            <InfoBase classes={classes} infoBase={dayData.infoBase} />
          </>
        )}
      </section>
    );
  }

  setDay = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({day: evt.target.value as DemographicsDaysEnum});
  };
}

type InfoBaseProps = Pick<Props, 'classes'> & {infoBase: InfoBaseChartData};

const doughnutChartRender = (group: GroupItems, classes: InfoBaseProps['classes']) => (
  <DoughnutChart
    classes={{root: classes.doughnutChart}}
    key={group.key}
    title={group.key}
    header="Household with"
    items={group.items}
  />
);

const InfoBase = ({classes, infoBase}: InfoBaseProps) => {
  return (
    <div className={classes.infoBaseRoot}>
      <Typography variant="h4" classes={{root: classes.chartTitle}}>
        Infobase
      </Typography>
      <div className={classes.screen}>
        <div className={classes.infoBaseContent}>
          {infoBase.groups.map((group) => doughnutChartRender(group, classes))}
        </div>
      </div>
      <div className={classes.print}>
        {[infoBase.groups.slice(0, 6), ...collectionSlicer(infoBase.groups.slice(6), 8)].map(
          (groupCollection, index) => (
            <div
              key={`infoBase-${index}`}
              className={`${index !== 0 && classes.infoBaseRoot} ${classes.infoBaseContent}`}
            >
              {groupCollection.map((group) => doughnutChartRender(group, classes))}
            </div>
          ),
        )}
      </div>
    </div>
  );
};

const Title = (props: Pick<State, 'loading'>) => (
  <div style={{display: 'flex', alignItems: 'center'}}>
    <SectionTitle text="Audience & Demographics" />
    {props.loading && <CircularProgress size={16} thickness={5} style={{marginLeft: 40}} />}
  </div>
);

type WeekSelectProps = Pick<State, 'day'> &
  Pick<Props, 'classes'> &
  Pick<SelectInputProps, 'onChange'>;

const WeekSelect = ({day, onChange, classes}: WeekSelectProps) => (
  <form className={classes.formRoot} autoComplete="off">
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        value={day}
        onChange={onChange}
        input={<OutlinedInput labelWidth={0} />}
        classes={{selectMenu: classes.weekSelectMenu}}
      >
        {dayOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </form>
);

export default withStyles(styles)(SectionAudience);
