import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';

const Option = (props) => (
  <MenuItem
    buttonRef={props.innerRef}
    selected={props.isFocused}
    component="div"
    classes={{
      root: classNames(
        props.isSelected ? props.selectProps.classes.boldFont : props.selectProps.classes.lightFont,
        props.selectProps.classes.optionWrapper,
      ),
      selected: props.selectProps.classes.optionSelected,
    }}
    {...props.innerProps}
  >
    {props.data.picture &&
      (typeof props.data.picture === 'string' ? (
        <Avatar
          alt={props.data.label}
          src={props.data.picture}
          className={props.selectProps.classes.avatar}
        />
      ) : (
        <props.data.picture className={props.selectProps.classes.icon} />
      ))}

    <ListItemText
      primary={<span className={props.selectProps.classes.optionLabel}>{props.data.label}</span>}
    />
  </MenuItem>
);

export default Option;
