import equal from 'fast-deep-equal';

import {isWidthDown} from '@material-ui/core/withWidth';
import _debounce from 'lodash.debounce';
import _get from 'lodash.get';

import PaginatedResponse from 'models/PaginatedResponse';
import {Panel} from 'models/Panel';
import {FilterQueryResponsePanel} from 'models/PanelSearchResponse';
import SurfaceExplorerViewMode from 'models/SurfaceExplorerViewMode';

import {filterPanelsKeepingOrder} from 'services/mad';

import {arrayToIndexed, indexedToArray} from 'utils/array';

import * as fetchs from './fetchs';
import {Props} from './Presentational';
import {debounceFuncs, equalList, scrollToTopOfList} from './utils';

export default class Controller {
  private map: mapboxgl.Map;

  fetchMapPopUpPanelsDebounce;
  surfaceExplorerMap;

  constructor(public props: Props) {
    this.fetchMapPopUpPanelsDebounce = _debounce(this.fetchMapPopUpPanels, 500);
  }

  onMapLoad = (map: mapboxgl.Map) => {
    this.map = map;
  };

  onMapResetClick = () => {
    const {updateState, mapCachedPanels, mapPanels: currentMapPanels} = this.props;
    const mapPanels = indexedToArray(mapCachedPanels);

    this.handleSearchChange('');
    updateState({mapPanels});

    if (equal(currentMapPanels, mapPanels)) {
      this.surfaceExplorerMap.panelMapPresential.centerOnPanels(mapPanels);
    }
  };

  onMapIdle = () => {
    const {mapPanels, mapCachedPanels, mapInitialPosition, updateState} = this.props;

    if (!mapInitialPosition) {
      const cachedPanels = indexedToArray(mapCachedPanels);

      if (cachedPanels.length === mapPanels.length) {
        updateState({
          mapInitialPosition: {bearing: this.map.getBearing(), center: this.map.getCenter()},
        });
      }
    }
  };

  openMapPopUp = (panels: Props['mapPopUpPanels']) => {
    const {setMapPopUpPanels, mapPopUpCachedPanels} = this.props;
    const panelsSet = (panels || []).map((panel) => mapPopUpCachedPanels[panel.id] || panel);
    const hasCachedData = (panelsSet || []).reduce((acc, curr) => acc && !!curr.mediaName, true);
    setMapPopUpPanels(panelsSet);
    if (!hasCachedData) {
      this.fetchMapPopUpPanelsDebounce(panels);
    }
  };

  onMapChange = () => {
    const {showMapResetButton, mapInitialPosition, hideMapResetButton} = this.props;
    if (mapInitialPosition) {
      const bearing = this.map.getBearing();
      const center = this.map.getCenter();
      if (!equal(mapInitialPosition, {bearing, center})) {
        showMapResetButton();
      } else {
        hideMapResetButton();
      }
    }
  };

  loadMapPanels = () => {
    const {
      proposalId,
      token,
      query,
      gridPanels,
      showNotification,
      setMapPanelsCache,
      setAudienceSortRequest,
      showNotificationLoading,
      hideNotification,
    } = this.props;

    const promise = fetchs.searchPanels(proposalId, token, {
      limit: 10000,
      sort: query.sort,
      select: {mad: ['location.center', 'location.bearing']},
    });

    showNotificationLoading('Fetching data');
    const myVar = setTimeout(
      () => showNotificationLoading('This is a long query, please wait'),
      30000,
    );

    promise
      .then((r) => {
        if (r.response.data) {
          clearTimeout(myVar);
          hideNotification();
          const indexedPanels = arrayToIndexed(
            (r.response.data.panels as FilterQueryResponsePanel[]).map((p) => ({
              id: p.id,
              ...p.mad,
              audienceSort: p.audienceSort,
            })),
          );
          setMapPanelsCache(indexedPanels);
          this.fetchMapPanels();
          if (!_get(gridPanels, 'length', 0)) {
            this.checkForPagination();
          }
        }
        if (_get(r, 'request.where.audienceSort')) {
          setAudienceSortRequest(r.request.where.audienceSort);
        }
      })
      .catch((e) => {
        clearTimeout(myVar);
        hideNotification();

        showNotification('Error to download map panels');
      });
  };

  fetchMapPopUpPanels = async (partialPanels: Props['mapPopUpPanels']) => {
    const {token, setMapPopUpPanels, addMapPopUpCachedPanel, mapPopUpPanels} = this.props;

    const $select = [
      'id',
      'marketCode',
      'locationDescription',
      'mediaName',
      'marketingImageUrl',
      'panelStatus',
      'location',
      'facing',
    ].join(',');

    const $ids = partialPanels.map((p) => p.id);
    const panels = await filterPanelsKeepingOrder({$select, $ids}, token);

    addMapPopUpCachedPanel(panels.data);

    if (equalList(panels.data, mapPopUpPanels)) {
      setMapPopUpPanels(panels.data);
    }
  };

  checkForPagination = () => {
    if (this.props.noPagination) {
      this.props.updateState({
        query: {
          limit: 10000,
          skip: 0,
          sort: this.props.query.sort,
        },
        searchTerm: '',
      });
      debounceFuncs([
        () => {
          this.fetchGridPanels();
          if (document.getElementById('surface-explorer-map')) {
            scrollToTopOfList('surface-explorer-map');
          }
        },
      ]);
    } else {
      this.fetchGridPanels();
    }
  };

  getPanelsPage = async (skip: number) => {
    const {token, searchTerm, query, proposalId, mapCachedPanels} = this.props;

    const result = await fetchs.searchPanels(proposalId, token, {
      limit: query.limit,
      from: skip,
      sort: query.sort,
      select: {mad: fetchs.madGridSelect},
      where: {
        mad: {
          must: {
            id: {terms: Object.keys(mapCachedPanels)},
            search: {query: searchTerm},
          },
        },
      },
    });

    return {
      skip: result.response.from,
      limit: result.response.limit,
      total: result.response.total,
      data: result.response.data.panels.map((p) => ({id: p.id, ...p.mad})),
    };
  };

  fetchGridPanels = async (): Promise<PaginatedResponse<Partial<Panel>>> => {
    const {total, showLoading, updateState, query, gridCachedPages} = this.props;

    showLoading();

    const cachedPanels = gridCachedPages[query.skip];

    let result: PaginatedResponse<Partial<Panel>>;

    if (!cachedPanels) {
      try {
        result = await this.getPanelsPage(query.skip);

        updateState({
          total: result.total,
          loading: false,
          gridPanels: result.data,
          gridCachedPages: {...gridCachedPages, [query.skip]: result.data},
        });
      } catch {
        this.props.showNotification(`Failed: Request Unavailable`);
      }
    } else {
      updateState({
        gridPanels: cachedPanels,
        loading: false,
      });

      result = {data: cachedPanels, limit: query.limit, skip: query.skip, total};
    }

    this.cacheNearPages(query.skip, result ? result.total : total);

    return result;
  };

  cacheNearPages = (skip: number, total: number) => {
    const {limit} = this.props.query;
    if (skip > 0) {
      this.fetchAndCachePage(skip - limit);
    }
    if (skip + limit < total) {
      this.fetchAndCachePage(skip + limit);
    }
  };

  fetchAndCachePage = async (skip: number) => {
    const {gridCachedPages, setGridPagesCache} = this.props;
    const nextCachedPanels = gridCachedPages[skip];

    if (!nextCachedPanels) {
      try {
        const result = await this.getPanelsPage(skip);

        setGridPagesCache({...gridCachedPages, [skip]: result.data});
      } catch {
        this.props.showNotification(`Failed: Request Unavailable`);
      }
    }
  };

  fetchMapPanels = async () => {
    try {
      await fetchs.MapPanels(this.props);
    } catch (error) {
      if (error instanceof fetchs.FetchError) {
        this.props.showNotificationReload(error.message);
      } else {
        this.props.showNotification(error.message);
      }
    }
  };

  handleViewModeToggle = () => {
    const {viewMode, setViewMode} = this.props;
    if (viewMode === SurfaceExplorerViewMode.Grid) {
      setViewMode(SurfaceExplorerViewMode.Map);
      debounceFuncs([
        () => {
          this.fetchMapPanels();
          scrollToTopOfList('surface-explorer');
        },
      ]);
    } else {
      setViewMode(SurfaceExplorerViewMode.Grid);
      debounceFuncs([
        () => {
          this.fetchGridPanels();
          scrollToTopOfList('surface-explorer');
        },
      ]);
    }
  };

  handleSkipChange = (skip: number) => {
    const {query, setQuery} = this.props;
    setQuery({...query, skip});
    debounceFuncs([
      () => {
        this.fetchGridPanels();
        if (document.getElementById('surface-explorer')) {
          scrollToTopOfList('surface-explorer');
        }
      },
    ]);
  };

  handleSearchChange = (searchTerm: string) => {
    const {query, updateState, width} = this.props;

    updateState({searchTerm, gridCachedPages: {}, query: {...query, skip: 0}});

    debounceFuncs([
      () => {
        this.fetchMapPanels();
        this.fetchGridPanels();
        if (isWidthDown('md', width)) {
          if (document.getElementById('surface-explorer')) {
            scrollToTopOfList('surface-explorer');
          }
        } else {
          if (document.getElementById('surface-explorer-map')) {
            scrollToTopOfList('surface-explorer-map');
          }
        }
      },
    ]);
  };

  handleSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.handleSearchChange(evt.target.value);
  };

  clearSearch = () => {
    this.handleSearchChange('');
  };

  closeMapPopUp = () => {
    this.fetchMapPopUpPanelsDebounce.cancel();
    this.props.clearMapPopUpPanels();
  };
}
