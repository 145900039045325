import purple from '@material-ui/core/colors/purple';
import createStyles from '@material-ui/core/styles/createStyles';

export const styles = () =>
  createStyles({
    chartContent: {
      display: 'flex',
    },
    chartColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    dayColumn: {
      flex: 1,
    },
    timeColumn: {
      alignItems: 'flex-end',
    },
    tablet: {
      margin: '1px',
    },
    range1Color: {
      'backgroundColor': purple['100'],
      '@media print': {
        'backgroundColor': purple['100'],
        '-webkit-print-color-adjust': 'exact',
      },
    },
    range2Color: {
      'backgroundColor': purple['200'],
      '@media print': {
        'backgroundColor': purple['200'],
        '-webkit-print-color-adjust': 'exact',
      },
    },
    range3Color: {
      'backgroundColor': purple['400'],
      '@media print': {
        'backgroundColor': purple['400'],
        '-webkit-print-color-adjust': 'exact',
      },
    },
    range4Color: {
      'backgroundColor': purple['700'],
      '@media print': {
        'backgroundColor': purple['700'],
        '-webkit-print-color-adjust': 'exact',
      },
    },
  });
