import createStyles from '@material-ui/core/styles/createStyles';

export const styles = () =>
  createStyles({
    root: {
      transform: 'scale(0.1)',
      opacity: 0,
      width: 10,
      height: 10,
      backgroundColor: '#FF7629',
      borderRadius: 10,
      transition: 'all 0.3s ease-in-out',
      margin: 10,
    },
    on: {
      transform: 'scale(1)',
      opacity: 1,
    },
  });
