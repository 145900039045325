import React from 'react';
import {Omit} from 'utility-types';

import Message, {MessageProps} from './Presentational';

type Props = Omit<MessageProps, 'type' | 'classes'>;

export const ErrorMessage = (props: Props) => <Message type="error" {...props} />;

export const SuccessMessage = (props: Props) => <Message type="success" {...props} />;
