import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      margin: '60px 0',
      lineHeight: 1.65,
    },
    formControl: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: 500,
      margin: '20px 0',
    },
    textField: {
      'width': '100%',
      'marginBottom': 10,

      '@media (max-width: 500px)': {
        marginBottom: 0,
      },
    },
    textFieldMessage: {
      marginTop: 10,
    },
    textFieldGroup: {
      'display': 'flex',
      'width': '100%',

      '@media (max-width: 500px)': {
        flexDirection: 'column',
        marginBottom: 20,
      },
    },
    btn: {
      'margin': '28px 0px 0px 10px',
      'width': 50,

      '@media (max-width: 500px)': {
        margin: '10px 0 0 -10px',
      },
    },
    list: {
      width: '100%',
      marginBottom: 30,
    },
    listItem: {
      paddingLeft: 0,
    },
  });
