import React from 'react';
import {Redirect, Route, RouteProps, useLocation, useParams} from 'react-router-dom';
import {PageTransition} from 'next-page-transitions';

import {useAuth, WithAuth} from './auth';
import {useQuery} from './hooks';

type QueryString = {
  noPagination: boolean;
  noLazy: boolean;
  noMap: boolean;
};

type RequiredProperty<T> = {[P in keyof T]: Required<NonNullable<T[P]>>};

export type PrivatePageProps = RequiredProperty<WithAuth> & {pageProps: QueryString};

type Props<T extends PrivatePageProps = PrivatePageProps> = Omit<
  RouteProps,
  'component' | 'render' | 'children'
> & {
  component: React.ComponentType<T>;
};

function AuthenticatedRoute(props: Props) {
  const auth = useAuth();
  const {id: proposalId} = useParams();
  const pageProps = useQuery();
  const location = useLocation();
  const {component: PageComponent, ...rest} = props;

  const shoulRedirectToAuth =
    (auth.loaded && !auth.validUserAndProposal) || auth.token === 'NOT_AUTHORIZED';
  const isStillLoading = !shoulRedirectToAuth && !(auth.loaded && auth.validUserAndProposal);

  if (shoulRedirectToAuth) {
    return (
      <Redirect
        to={{
          pathname: `/p/${proposalId}/auth`,
          state: {from: location},
        }}
      />
    );
  }

  if (isStillLoading) {
    return <div></div>;
  }

  return (
    <PageTransition
      timeout={400}
      classNames="page-transition"
      loadingClassNames=""
      loadingDelay={0}
      loadingTimeout={{
        enter: 400,
        exit: 0,
      }}
      monkeyPatchScrolling={true}
    >
      <PageComponent
        key={location.pathname}
        {...(Object.assign({pageProps}, rest, auth) as PrivatePageProps)}
      />
    </PageTransition>
  );
}

export function PrivateRoute({component, ...rest}: Props) {
  return (
    <Route
      {...rest}
      component={(props) => (
        // This way we can get the context injected by Route to use hooks
        <AuthenticatedRoute {...Object.assign({component}, props, rest)} />
      )}
    />
  );
}
