import createStyles from '@material-ui/core/styles/createStyles';

export const styles = () =>
  createStyles({
    lessonRow: {
      'zIndex': 10,
      'position': 'relative',
      'padding': '0 8px',
      'backgroundColor': '#FFF',
      'display': 'flex',
      'alignItems': 'center',
      'fontFamily': 'Roboto, sans-serif',
      'border': '1px solid #e0e0e0',
      'marginBottom': -1,
      'cursor': 'pointer',
      '&:first-child': {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      },
      '&:last-child': {
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
      },
      '&:hover': {},
    },
    titleFont: {
      display: 'flex',
      flex: 1,
      fontSize: 14,
      lineHeight: 1.5,
      cursor: 'pointer',
      textAlign: 'left',
      color: 'rgba(0, 0, 0, 0.8)',
    },
    durationFont: {
      color: '#828282',
      fontSize: 14,
    },
    rightDisposedItem: {
      minWidth: 50,
      textAlign: 'right',
    },
  });
