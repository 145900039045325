import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      'margin': '60px 0 30px',

      '@media (max-width: 500px)': {
        marginTop: 30,
      },
    },
  });
