import React from 'react';
import classNames from 'classnames';

import Fade from '@material-ui/core/Fade';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import {styles} from './styles';

export type MessageProps = {message: string; type: 'success' | 'error'} & WithStyles<typeof styles>;

const icons = {
  success: <CheckCircleOutlineIcon />,
  error: <ErrorOutlineIcon />,
};

export default withStyles(styles)(({classes, type, message}: MessageProps) => (
  <>
    {message && (
      <Fade in={!!message} timeout={300}>
        <div className={classNames(classes.message, classes[type])}>
          {icons[type]}

          <Typography variant="body2">{message}</Typography>
        </div>
      </Fade>
    )}
  </>
));
