import React from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import Button, {ButtonProps} from '@material-ui/core/Button';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';

import {styles} from './styles';

type Props = {
  active?: boolean;
  asHref?: string;
} & ButtonProps &
  WithStyles<typeof styles>;

const MainNavMenu: React.SFC<Props> = ({
  active,
  classes,
  href,
  asHref,
  children,
  ...otherProps
}) => {
  const Btn = (
    <Button
      className={classNames(classes.btn, {
        [classes.btnActive]: active,
      })}
      {...otherProps}
    >
      {children}
    </Button>
  );
  return href ? (
    <Link to={asHref} className={classes.root}>
      {Btn}
    </Link>
  ) : (
    Btn
  );
};

export default withStyles(styles)(MainNavMenu);
