import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import {toUSLocale} from 'utils/number';
import {maybePluralize} from 'utils/string';

import {styles} from './styles';

export interface PaginationProps extends WithStyles<typeof styles> {
  disabled?: boolean;
  limit: number;
  skip: number;
  total: number;
  minify?: boolean;
  model?: string;
  onChangeSkip: (skip: number) => void;
}

class Pagination extends React.Component<PaginationProps> {
  static defaultProps: Partial<PaginationProps> = {
    model: 'item',
  };

  handleChangeSkip = (direction: 'prev' | 'next') => {
    return (_e: React.MouseEvent<HTMLButtonElement>) => {
      const {limit, skip} = this.props;

      const updatedSkip = direction === 'next' ? skip + limit : skip - limit;

      this.props.onChangeSkip(updatedSkip);
    };
  };

  pageLimit = (limit: number, skip: number, total: number) => {
    if (skip + limit > total) {
      return total;
    }

    return limit + skip;
  };

  render() {
    const {classes, limit, skip, total, minify, model, disabled} = this.props;

    return (
      <div className={classes.root}>
        {!total ? (
          <PaginationText>Results not found</PaginationText>
        ) : minify ? (
          <PaginationText>{`${total} ${maybePluralize(total, model, 's')} found`}</PaginationText>
        ) : (
          <>
            <PaginationText>
              {toUSLocale(skip + 1)} - {toUSLocale(this.pageLimit(limit, skip, total))}
              &nbsp;of&nbsp;
              <span>{toUSLocale(total)}</span>
            </PaginationText>

            <IconButton
              key={2}
              className="btn-page-nav"
              type="button"
              style={{marginRight: '0.5rem'}}
              onClick={this.handleChangeSkip('prev')}
              disabled={disabled || skip - limit < 0}
            >
              <ChevronLeft />
            </IconButton>

            <IconButton
              key={3}
              className="btn-page-nav"
              type="button"
              onClick={this.handleChangeSkip('next')}
              disabled={disabled || skip + limit + 1 > total}
            >
              <ChevronRight />
            </IconButton>
          </>
        )}
      </div>
    );
  }
}

interface TextProps extends WithStyles<typeof styles> {}

const Text: React.SFC<TextProps> = ({children, classes}) => (
  <Typography variant="caption" classes={{root: classes.label}}>
    {children}
  </Typography>
);

const PaginationText = withStyles(styles)(Text);

export default withStyles(styles)(Pagination);
