import React from 'react';
import {Helmet} from 'react-helmet';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import DefinitionsDialog from 'components/common/DefinitionsDialog';
import LoggedUser from 'components/common/LoggedUser';
import OutFrontImage from 'components/common/OutFrontImg';
import SectionAudience from 'components/common/SectionAudience';
import SectionIntroduction from 'components/common/SectionIntroduction';
import SectionMore from 'components/common/SectionMore';
import SectionOverview from 'components/common/SectionOverview';
import SectionPricing from 'components/common/SectionPricing';
import {PrivatePageProps} from 'contexts/privateRoute';
import {page, PageProps} from 'hoc/page';

import Definitions from 'models/Definitions';
import Proposal from 'models/Proposal';
import User from 'models/User';

import {getDefinitions} from 'services/definitions';

import {isPersonicxCluster} from 'utils/demographics/mapPersonicxData';

import {styles} from './styles';

enum PersonicxInfoTypesEnum {
  cluster = 'Cluster',
  lsg = 'Life Stage Group',
}

const initialState = {
  personicxInfo: {
    open: false,
    loading: false,
    title: '' as PersonicxInfoTypesEnum,
    definitions: {} as Definitions,
  },
};
type State = typeof initialState;

type Props = PrivatePageProps & WithStyles<typeof styles> & PageProps;

class Index extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  handleClickPersonicxInfoButton = async (id: string) => {
    const {token} = this.props;
    this.setState((state) => ({
      personicxInfo: {...state.personicxInfo, open: true, loading: true},
    }));
    const isCluster = isPersonicxCluster(id);

    const definitions = await getDefinitions(id, token, isCluster);
    const title = isCluster ? PersonicxInfoTypesEnum.cluster : PersonicxInfoTypesEnum.lsg;

    this.setState((state) => ({
      personicxInfo: {...state.personicxInfo, definitions, title, loading: false},
    }));
  };

  handleClosePersonicxInfo = () => {
    this.setState((state) => ({
      personicxInfo: {...state.personicxInfo, open: false},
    }));
  };

  renderOverviewSection = (
    proposal: Proposal | undefined,
    pageProps: Props['pageProps'],
    token: string,
    onClickPersonicxInfoButton: (id: string) => void,
  ) => (
    <SectionOverview
      proposalId={proposal?.id ?? ''}
      startDate={proposal?.startDate ?? ''}
      endDate={proposal?.endDate ?? ''}
      token={token}
      onClickPersonicxInfoButton={onClickPersonicxInfoButton}
      noPagination={pageProps.noPagination}
      noLazy={pageProps.noLazy}
      noMap={pageProps.noMap}
    />
  );

  render() {
    const {classes, proposal, loggedUser, token, pageProps} = this.props;
    const _owner = proposal?._owner;
    const {personicxInfo} = this.state;
    const proposalName = proposal?.name ?? '';
    const netRate = proposal?.netRate ?? 0;

    return (
      <div className={classes.root}>
        <Helmet>
          <title>{proposalName ?? ''} | OUTFRONT Media Proposal</title>
        </Helmet>
        <main className={classes.main} id="proposal-main">
          <div id="introduction">
            <OutFrontImage classes={{root: classes.outfrontImage}} />
            <LoggedUser email={loggedUser} />
            <SectionIntroduction
              brandImageUrl={proposal?.brandImageUrl ?? ''}
              title={proposalName}
              description={proposal?.description ?? ''}
              updatedAt={proposal?.updatedAt ?? ''}
              owner={proposal?._owner ?? ({} as User)}
            />
          </div>
          {this.renderOverviewSection(
            proposal,
            pageProps,
            token,
            this.handleClickPersonicxInfoButton,
          )}
          <SectionAudience
            proposalId={proposal?.id ?? ''}
            token={token}
            onClickPersonicxInfoButton={this.handleClickPersonicxInfoButton}
          />
          {/* <SectionCaseStudies items={data.cases} description={data.casesDescription} /> */}
          {/* <SectionBuyButton /> */}
          {!!netRate && (
            <SectionPricing netRate={netRate} adjustedRate={proposal?.adjustedRate ?? 0} />
          )}
          {_owner && (
            <SectionMore
              proposalTitle={proposalName}
              ownerName={_owner?.name ?? ''}
              ownerEmail={_owner?.email ?? ''}
            />
          )}
          <DefinitionsDialog {...personicxInfo} onClose={this.handleClosePersonicxInfo} />
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(page(Index));
