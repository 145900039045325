import fetch from 'isomorphic-fetch';
import _get from 'lodash.get';

import environment from 'config/environment';
import {processAvgInfoBase, processDefaultValues} from 'utils/demographics/utils';

const baseUrl = environment.settings.demographics.url;
const headers = {
  'x-api-key': environment.settings.demographics.apiKey,
};

export const getDemographics = async (id: string) => {
  const res = await fetch(`${baseUrl}/${id}`, {
    headers,
  });

  return res.json().then((r) => {
    const _source = _get(r, 'json._source', null);
    if (_source) {
      const demographics = processDefaultValues(_get(_source, 'days', {}));
      return processAvgInfoBase(demographics, 1);
    }
  });
};
