import {createStyles} from '@material-ui/core';

export const styles = () =>
  createStyles({
    caption: {
      textTransform: 'uppercase',
      color: 'rgba(0, 0, 0, 0.54)',
      lineHeight: '1.375em',
    },
    dialogContent: {
      height: '70vh',
    },
    dialogContentLg: {
      width: 400,
    },
    moreInfoSection: {
      margin: '1rem 0',
    },
    moreInfoSideTable: {
      width: '100%',
      color: 'rgba(0, 0, 0, 0.54)',
      margin: 0,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5em',
    },
    loading: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
