import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      margin: '60px 0',
      lineHeight: 1.65,
    },
    textField: {
      width: '100%',
      maxWidth: 400,
      marginRight: 10,
      marginBottom: 30,
    },
    list: {
      maxWidth: 600,
    },
    listItem: {
      padding: '20px 0',
      borderBottom: '1px solid #E5E5E5',
    },
    caption: {
      color: '#666',
    },
    eventText: {
      marginTop: 10,
    },
    paginationProgress: {
      display: 'flex',
      justifyContent: 'center',
      margin: 60,
    },
    progress: {},
  });
