import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import equal from 'fast-deep-equal';
import Cookie from 'js-cookie';

import {get} from 'services/proposal';

import {removeAllCookies} from 'utils/cookie';
import {jwtDecode} from 'utils/jwt';

import {actions, initialState} from 'config/redux/auth';
import {actions as proposalActions} from 'config/redux/proposal';
import {RootState} from 'config/redux/rootReducer';

import {useQuery} from './hooks';

const AuthContext = React.createContext({
  state: initialState,
  logout: () => {
    return;
  },
});

export function AuthProvider({children}: React.PropsWithChildren<any>) {
  const state = useSelector(({auth}: RootState) => auth);
  const dispatch = useDispatch();
  const {qsToken, urlProposalId} = useQuery();
  const history = useHistory();

  const logout = () => {
    removeAllCookies();
    dispatch(actions.clearUserInfo());
    const authUrl = urlProposalId ? `/p/${urlProposalId}/auth` : '/';
    history.push(authUrl);
  };

  React.useEffect(() => {
    let token =
      qsToken || Cookie.get('jwt') || Cookie.get(`p_${urlProposalId}_token`) || 'NOT_AUTHORIZED';
    const user = jwtDecode(token);

    if (!user) {
      token = 'NOT_AUTHORIZED';
    }

    if (token !== 'NOT_AUTHORIZED') {
      if (token !== state.token) {
        dispatch(actions.setToken(token));
      }
      if (urlProposalId && equal(state.proposal, {})) {
        get(urlProposalId, token, true)
          .then((proposal) => {
            const isAdmin = proposal.ownerId === user.user_id;
            const isOwner = user?.app_metadata?.permissions?.ofm?.role[0] === 'admin';

            dispatch(actions.setUserInfo({isAdmin, isOwner}));
            dispatch(proposalActions.setProposal(proposal));
          })
          .catch((err) => {
            token = 'NOT_AUTHORIZED';
            dispatch(actions.setToken(token));
            dispatch(proposalActions.setProposal(null));
          });
      }
    } else {
      dispatch(actions.setToken(token));
    }
  }, [qsToken, state.proposal, state.token, urlProposalId, dispatch]);

  return <AuthContext.Provider value={{state, logout}}>{children}</AuthContext.Provider>;
}

export type WithAuth = ReturnType<typeof useAuth>;

export function useAuth() {
  const {
    state: {userInfo, token, proposal},
    logout,
  } = React.useContext(AuthContext);

  const user = jwtDecode(token) as {email: string};
  const loaded = !!token && !equal(proposal, {});
  const validUserAndProposal = loaded && !!user && [{}, null].every((p) => !equal(proposal, p));
  const loggedUser = user?.email || '';

  return {user, loggedUser, token, proposal, userInfo, loaded, validUserAndProposal, logout};
}
