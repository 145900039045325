import {applyMiddleware, createStore, Store} from 'redux';
import reduxCatch from 'redux-catch';
import {composeWithDevTools} from 'redux-devtools-extension';

import * as Sentry from '@sentry/browser';

import {stateTransformer} from 'utils/sentry';

import {rootReducer, RootState} from './rootReducer';

const startTime = new Date();
const errorHandler = (error: Error, getState: Function, lastAction: any) => {
  Sentry.withScope((scope) => {
    scope.setExtra('lastAction', lastAction);
    scope.setExtra('session:duration', new Date().getTime() - startTime.getTime());
    scope.setExtra('state', stateTransformer(getState()));
    scope.setExtra('redux', true);

    Sentry.captureException(error);
  });
};

export default function configureStore(initalState = {}): Store<RootState> {
  // create the composing function for our middlewares
  const composeEnhancers = composeWithDevTools({});

  const enhancers = composeEnhancers(applyMiddleware(reduxCatch(errorHandler)));

  const store = createStore(rootReducer, initalState, enhancers);

  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      store.replaceReducer(require('./rootReducer').rootReducer);
    });
  }

  return store;
}
