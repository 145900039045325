import React from 'react';
import Button from '@material-ui/core/Button';

import {FileType} from 'models/FileType';

import container from './Container';
import withGetProposalFile from './withGetProposalFile';

// interface Props {
//   targetCount: number;
// }

// TODO: Fix typings: Older <ButtonProps & DataType> was not helping; replaced to any.
export const CSV = container(
  withGetProposalFile<any>(FileType.csv)(({get, token, proposal, children, ...props}) => (
    <Button {...props} onClick={() => get(proposal, token)}>
      {children ? children : 'CSV'}
    </Button>
  )),
);
