import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      'width': 230,
      'display': 'flex',
      'flexDirection': 'column',
      'alignItems': 'flex-start',
      'height': 80,
      'justifyContent': 'space-around',

      '@media print': {
        height: 50,
      },
    },
    btn: {
      'marginLeft': -8,

      '@media print': {
        display: 'none',
      },
    },
  });
