import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      margin: '60px 0',
      lineHeight: 1.65,
    },
    suggestedRateWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    suggestedRateButton: {
      margin: '0 0 26px 7px',
      fontWeight: 400,
    },
    formsWrapper: {
      display: 'flex',
    },
    formControl: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 230,
      margin: '20px 20px 10px 0',
    },
    subHeading: {
      marginTop: '1rem',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 16,
    },
    suggestedRateError: {
      marginBottom: 30,
    },
    properties: {
      marginTop: '1rem',
    },
    propertiesContent: {
      display: 'flex',
      alignItems: 'center',
    },
    contentCircle: {
      height: 7,
      width: 7,
      borderRadius: '50%',
      backgroundColor: '#000000',
      marginRight: 10,
    },
    contentLabel: {
      fontFamily: 'Roboto',
      fontSize: 14,
      color: 'rgba(0, 0, 0, 0.8)',
    },
  });
