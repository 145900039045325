import {connect} from 'react-redux';
import {bindActionCreators as bindActions} from 'redux';

import {actions as globalActions} from 'config/redux/global/actions';
import {getState} from 'config/redux/rootReducer';
import {actions as surfaceExplorerActions} from 'config/redux/surfaceExplorer/actions';

const mapStateToProps = getState(({surfaceExplorer, proposal: {item: {id: proposalId}}}) => ({
  ...surfaceExplorer,
}));

const mapDispatchToProps = (dispatch) =>
  bindActions(
    {
      ...surfaceExplorerActions,
      showNotification: globalActions.showNotification,
      showNotificationReload: globalActions.showNotificationReload,
      showNotificationLoading: globalActions.showNotificationLoading,
      hideNotification: globalActions.hideNotification,
    },
    dispatch,
  );

export type DataType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps);
