import React, {Component, SFC} from 'react';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Email';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import User from 'models/User';

const ItemIcon: SFC<{name: 'delete' | 'send'}> = ({name}) => (
  <ListItemIcon style={{margin: 0}}>
    {
      {
        delete: <DeleteIcon />,
        send: <SendIcon />,
      }[name]
    }
  </ListItemIcon>
);

type Props = {
  user: User;
  canSendEmail: boolean;
  onRemove: (user: User) => void;
  onSend: (user: User) => void;
};

type State = {anchorEl: HTMLElement};

export class MenuEmailItem extends Component<Props, State> {
  state = {anchorEl: null};

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  handleOpen = ({currentTarget: el}: React.MouseEvent<HTMLElement>) => {
    this.setState({anchorEl: el});
  };

  onRemove = () => {
    this.handleClose();
    const {user, onRemove} = this.props;
    onRemove(user);
  };

  onSend = () => {
    const {user, onSend, canSendEmail} = this.props;

    if (canSendEmail) {
      this.handleClose();
      onSend(user);
    }
  };

  render() {
    const {canSendEmail} = this.props;
    const {anchorEl} = this.state;
    const open = !!anchorEl;
    const titleSendEmail = canSendEmail ? '' : 'This proposal has not been published';

    return (
      <>
        <IconButton onClick={this.handleOpen} aria-label="Open user menu">
          <MoreVertIcon />
        </IconButton>
        <Menu id="render-props-menu" anchorEl={anchorEl} open={open} onClose={this.handleClose}>
          <MenuItem onClick={this.onRemove} title="Remove user access">
            <ItemIcon name="delete" />
            <ListItemText inset primary="Remove" />
          </MenuItem>
          <MenuItem
            onClick={this.onSend}
            disabled={!canSendEmail}
            title={titleSendEmail}
            style={{pointerEvents: 'initial'}}
          >
            <ItemIcon name="send" />
            <ListItemText inset primary="Send Login Link" />
          </MenuItem>
        </Menu>
      </>
    );
  }
}
