import React from 'react';
import {SFC} from 'react';
import classNames from 'classnames';

import Avatar from '@material-ui/core/Avatar';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Placeholder from 'components/common/Placeholder';

import User from 'models/User';

import {isEmpty} from 'utils/object';

import {styles} from './styles';

type Props = WithStyles<typeof styles> & {
  proposalTitle: string;
  user: User;
};

const Owner: SFC<Props> = ({classes, user, proposalTitle}) => {
  const {picture, name, email, phone} = user;
  const loading = isEmpty(user);

  return (
    <div className={classes.root}>
      <Typography variant="caption" gutterBottom className={classes.title}>
        Proposal managed by
      </Typography>

      <div className={classes.columns}>
        <Avatar
          alt={name}
          src={picture}
          className={classNames(classes.avatar, 'animated-background')}
        />

        <div className={classes.content}>
          {loading ? (
            <>
              <Placeholder variant="body1" style={{width: '50%', height: 16, marginTop: 4}} />
              <Placeholder variant="body2" style={{width: '100%', height: 14, marginTop: 8}} />
            </>
          ) : (
            <>
              <Typography variant="body1">{name}</Typography>

              <Typography variant="body2">
                <a
                  href={`mailto:${name}<${email}>?subject=Contact%20from%20proposal%3A ${proposalTitle}&body=Write%20your%20message`}
                  className={classes.link}
                >
                  {email}
                </a>
              </Typography>

              <Typography variant="body2" style={{color: '#666'}}>
                {phone}
              </Typography>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Owner.defaultProps = {
  user: {} as User,
};

export default withStyles(styles)(Owner);
