import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      maxWidth: 650,
      margin: '30px 0',
    },
    title: {
      'fontSize': '2.4rem',
      'fontWeight': 700,
      'letterSpacing': -1.5,
      'lineHeight': 1.3,
      'marginBottom': 20,

      '@media (max-width: 500px)': {
        fontSize: '1.8rem',
      },
    },
    caption: {
      color: '#666',
    },
  });
