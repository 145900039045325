import React from 'react';
import {SFC} from 'react';
import NumberFormat from 'react-number-format';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';

import Proposal from 'models/Proposal';

import DataTitle from '../DataTile';
import SectionTitle from '../SectionTitle';
import {styles} from './styles';

const NumberFormatCustom = (props) => {
  return (
    <NumberFormat
      value={props.value}
      renderText={(value) =>
        props.hasParentheses ? (
          <div>
            {'('}
            {value}
            {')'}
          </div>
        ) : (
          <div>{value}</div>
        )
      }
      displayType="text"
      thousandSeparator
      decimalSeparator="."
      decimalScale={0}
      prefix="$ "
    />
  );
};

interface Props extends WithStyles<typeof styles>, Pick<Proposal, 'netRate' | 'adjustedRate'> {}

const SectionPricing: SFC<Props> = (props) => {
  const {classes, netRate, adjustedRate} = props;

  return (
    <section className={classes.root} id="pricing">
      <div className={classes.topBorder} />

      <SectionTitle text="Pricing" />

      <div className={classes.content}>
        <div className={classes.tile}>
          <DataTitle label="Net Rate" data={<NumberFormatCustom value={netRate} />} />
        </div>

        {adjustedRate && (
          <div className={classes.tile}>
            <DataTitle
              label="Discount"
              data={<NumberFormatCustom value={netRate - adjustedRate} hasParentheses={true} />}
            />
          </div>
        )}

        <div className={classes.tile}>
          <DataTitle
            label="Total"
            data={
              <b>
                <NumberFormatCustom value={adjustedRate ? adjustedRate : netRate} />
              </b>
            }
          />
        </div>
      </div>
    </section>
  );
};

export default withStyles(styles)(SectionPricing);
