import React from 'react';
import {CSSProperties} from 'react';
import dayjs from 'dayjs';

import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Placeholder from 'components/common/Placeholder';

import {styles} from './styles';

type Props = WithStyles<typeof styles> & {
  type?: string;
  label: string;
  data: number | string | JSX.Element;
  style?: CSSProperties;
  loading?: boolean;
};

const format = 'MMM DD, YYYY';

function DataTitle(props: Props) {
  const {classes, type, label, data, style, loading} = props;

  return (
    <div className={classes.root} style={style}>
      <Typography variant="caption" className={classes.label}>
        {label}
      </Typography>

      {loading && <Placeholder variant="h4" style={{height: 30, marginTop: 8}} />}

      {!loading &&
        (type === 'date' ? (
          <Typography variant="h4" className={classes.data}>
            <time dateTime={data as string}>{dayjs(data as number | string).format(format)}</time>
          </Typography>
        ) : (
          <Typography variant="h4" className={classes.data}>
            {data}
          </Typography>
        ))}
    </div>
  );
}

export default withStyles(styles)(DataTitle);
