import purple from '@material-ui/core/colors/purple';
import createStyles from '@material-ui/core/styles/createStyles';

export const styles = () =>
  createStyles({
    root: {
      fontSize: 12,
      width: '100%',
    },
    chartContent: {
      display: 'flex',
    },
    chartColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    dayColumn: {
      flex: 1,
    },
    timeColumn: {
      alignItems: 'flex-end',
    },
    tablet: {
      margin: '1px',
    },
    range1Color: {
      backgroundColor: purple['100'],
    },
    range2Color: {
      backgroundColor: purple['200'],
    },
    range3Color: {
      backgroundColor: purple['400'],
    },
    range4Color: {
      backgroundColor: purple['700'],
    },
  });
