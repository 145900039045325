import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      'display': 'flex',

      '@media (max-width: 900px)': {
        flexDirection: 'column',
      },
    },
    brand: {
      marginBottom: 60,
    },
    formSection: {
      'display': 'flex',
      'flexDirection': 'column',
      'width': 500,
      'height': '100vh',
      'position': 'fixed',
      'justifyContent': 'flex-start',
      'alignItems': 'flex-start',
      'padding': 40,

      '@media (max-width: 900px)': {
        position: 'relative',
        border: 'none',
        width: '100%',
        maxWidth: 500,
        padding: 20,
        height: 'auto',
      },
    },
    formControl: {
      'width': '100%',
      'margin': '30px 0',

      '@media (max-width: 900px)': {
        marginTop: 10,
      },
    },
    textField: {
      width: '100%',
    },
    actionBtn: {
      fontSize: '0.8rem',
      fontWeight: 400,
      padding: '12px 20px',
      boxShadow: 'none',
    },
  });
