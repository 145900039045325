import {combineReducers} from 'redux';
import {createAction, createReducer} from 'redux-act';
import {Omit} from 'utility-types';

import Log from 'models/Log';

export const initialState = {
  data: [] as Log[],
  skip: 0,
  limit: 5,
  total: 0,
  search: '',
};

export const actions = {
  setData: createAction<Omit<typeof initialState, 'limit' | 'search'>>('logs/SET_DATA'),
  setSearch: createAction<string>('logs/SET_SEARCH'),
};

const data = createReducer({}, initialState.data).on(actions.setData, (_state, {data}) => data);
const skip = createReducer({}, initialState.skip).on(actions.setData, (_state, {skip}) => skip);
const total = createReducer({}, initialState.total).on(actions.setData, (_state, {total}) => total);
const search = createReducer({}, initialState.search).on(
  actions.setSearch,
  (_state, value) => value,
);
const limit = createReducer({}, initialState.limit);

export const logs = combineReducers({data, skip, total, limit, search});
