import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {WithStyles} from '@material-ui/core/styles/withStyles';
import {PrivatePageProps} from 'contexts/privateRoute';
import {PageProps} from 'hoc/page';

import {defaultCsvFields} from 'utils/csv';

import {actions} from 'config/redux/manage/actions';
import {getState} from 'config/redux/rootReducer';

import {styles} from './styles';

type External = PrivatePageProps & PageProps & WithStyles<typeof styles>;
const mapStateToProps = getState(({manage}, external: External) => ({
  ...external,
  csvColumns: manage.proposal.csvColumns ?? defaultCsvFields,
  suggestedRate: manage.suggestedRate,
  proposal: manage.proposal,
}));

const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);

export type DataType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps);
