import {connect} from 'react-redux';
import _isEqual from 'fast-deep-equal';

import Proposal, {ProposalStatus} from 'models/Proposal';

import {updateProposal} from 'services/proposal';

import {actions as globalActions} from 'config/redux/global/actions';
import {actions as proposalActions} from 'config/redux/proposal/actions';
import {getState} from 'config/redux/rootReducer';

const mapStateToProps = getState(({manage, auth, proposal: {item: proposal}}) => ({
  manageForm: manage.proposal,
  proposalId: proposal?.id ?? '',
  proposalStatus: proposal?.status ?? ProposalStatus.draft,
  isAdmin: auth.userInfo?.isAdmin ?? false,
  isOwner: auth.userInfo?.isOwner ?? false,
  token: auth.token ?? '',
  hasPricing: !!proposal?.netRate ?? 0,
  hasChangesToSave: !_isEqual(manage.proposal, proposal),
}));

const mapDispatchToProps = (dispatch) => ({
  save: async (proposal: Partial<Proposal>, token: string) => {
    try {
      const result = await updateProposal(proposal, token);

      dispatch(proposalActions.updateRawProposal(result));
      dispatch(globalActions.showNotification('Proposal saved'));
    } catch (er) {
      dispatch(globalActions.showNotificationInfo('Proposal failed'));
    }
  },
});

export type DataType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps);
