import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      margin: '60px 0',
      lineHeight: 1.65,
    },
    formControl: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 500,
      marginBottom: 20,
    },
    fieldsetLegend: {
      padding: '10px 0',
    },
    textField: {
      width: '100%',
      marginBottom: 30,
    },
  });
