import React from 'react';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';

import User from 'models/User';

import ClientBrand from '../ClientBrand';
import MainTitle from '../MainTitle';
import Owner from '../Owner';
import TextWrap from '../TextWrap';
import {styles} from './styles';

type Props = WithStyles<typeof styles> & {
  brandImageUrl?: string;
  title: string;
  description?: string;
  updatedAt: string;
  owner: User;
};

function SectionIntroduction(props: Props) {
  const {classes, brandImageUrl, title, description, updatedAt, owner} = props;

  return (
    <section className={classes.root}>
      {brandImageUrl && <ClientBrand picture={brandImageUrl} />}

      <MainTitle text={title} updateDate={updatedAt} />

      <Owner user={owner} proposalTitle={title} />

      {description && <TextWrap text={description} />}
    </section>
  );
}

export default withStyles(styles)(SectionIntroduction);
