import {createStyles} from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      display: 'flex',
    },
    main: {
      marginLeft: 200,
      padding: '40px 50px',
      width: '100%',
      backgroundColor: '#fff',
      position: 'relative',
    },
    paginationProgress: {
      display: 'flex',
      justifyContent: 'center',
      margin: 60,
    },
    progress: {},
  });
