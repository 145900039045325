import {combineReducers} from 'redux';
import {createReducer} from 'redux-act';

import Proposal from 'models/Proposal';

import {defaultCsvFields} from 'utils/csv';

import {actions as proposalActions} from 'config/redux/proposal/actions';

import {actions} from './actions';

export const initialState = {
  proposal: {} as Proposal,
  suggestedRate: 0,
};

const proposal = createReducer({}, initialState.proposal)
  .on(actions.setProposal, (_state, prop) => prop || initialState.proposal)
  .on(proposalActions.setProposal, (_state, prop) => prop || initialState.proposal)
  .on(proposalActions.updateRawProposal, (_state, prop) => ({..._state, ...prop}))
  .on(actions.setName, (_state, prop) => ({..._state, name: prop}))
  .on(actions.setBrandImageUrl, (_state, prop) => ({..._state, brandImageUrl: prop}))
  .on(actions.setDescription, (_state, prop) => ({..._state, description: prop}))
  .on(actions.setStatus, (_state, prop) => ({..._state, status: prop}))
  .on(actions.setDateRange, (_state, prop) => ({
    ..._state,
    startDate: prop.startDate,
    endDate: prop.endDate,
  }))
  .on(actions.setRate, (_state, prop) => ({
    ..._state,
    netRate: prop.netRate,
    adjustedRate: prop.adjustedRate,
  }))
  .on(actions.setArquiveDate, (_state, prop) => ({..._state, arquiveDate: prop}))
  .on(actions.addCsvFields, (state, prop) => ({
    ...state,
    csvColumns: [...(state.csvColumns || defaultCsvFields), ...prop],
  }))
  .on(actions.setCsvFields, (state, prop) => ({
    ...state,
    csvColumns: prop,
  }))
  .on(actions.deleteCsvField, (state, prop) => ({
    ...state,
    csvColumns: state.csvColumns?.filter((column) => column !== prop),
  }));

const suggestedRate = createReducer({}, initialState.suggestedRate).on(
  actions.setSuggestedRate,
  (_state, prop) => prop || initialState.suggestedRate,
);

export default combineReducers({
  proposal,
  suggestedRate,
});
