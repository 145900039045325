import createStyles from '@material-ui/core/styles/createStyles';

export const styles = () =>
  createStyles({
    root: {
      backgroundColor: '#FFF',
      transition: 'all 400ms ease',
    },
    menuOpen: {
      '@media (max-width: 500px)': {
        transform: 'translate3d(200px, 0, 0)',
      },
    },
  });
