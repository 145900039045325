import jwtDecode3rdParty from 'jwt-decode';

export const jwtDecode = (token: string) => {
  try {
    return jwtDecode3rdParty(token);
  } catch (error) {
    return undefined;
  }
};

export const isExpired = (jwt: any) => {
  const currentTime = new Date().getTime() / 1000;

  return currentTime > jwt.exp;
};
