import React, {Component, Suspense} from 'react';
import classNames from 'classnames';

import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import withWidth, {isWidthDown, WithWidth} from '@material-ui/core/withWidth';
import {Datagrid} from 'components/common/Datagrid';
import {WithAuth} from 'contexts/auth';
import _get from 'lodash.get';

import {Panel} from 'models/Panel';
import Proposal from 'models/Proposal/Proposal';
import SurfaceExplorerViewMode from 'models/SurfaceExplorerViewMode';

import {collectionSlicer} from 'utils/array';
import {isEmpty} from 'utils/object';

import {Props as SurfaceDetailViewProps} from '../SurfaceDetailView';
import {Props as SurfaceExplorerCardsProps} from '../SurfaceExplorerCards';
import container, {DataType} from './Container';
import Controller from './Controller';
import {styles} from './styles';
import Toolbar from './Toolbar';

const SurfaceExplorerCardsLazy = React.lazy(() => import('components/common/SurfaceExplorerCards'));
const SurfaceExplorerCards = (props) => (
  <Suspense fallback={null}>
    <SurfaceExplorerCardsLazy {...props} />
  </Suspense>
);

const SurfaceDetailViewLazy = React.lazy(() => import('components/common/SurfaceDetailView'));
const SurfaceDetailView = (props) => (
  <Suspense fallback={null}>
    <SurfaceDetailViewLazy {...props} />
  </Suspense>
);

const SurfaceExplorerMapLazy = React.lazy(() => import('components/common/SurfaceExplorerMap'));
const SurfaceExplorerMap = (props) => (
  <Suspense fallback={null}>
    <SurfaceExplorerMapLazy {...props} />
  </Suspense>
);

export type Props = DataType &
  WithStyles<typeof styles> &
  WithWidth &
  Pick<Proposal, 'startDate' | 'endDate'> &
  Pick<SurfaceExplorerCardsProps, 'onSurfaceClick'> &
  Pick<
    SurfaceDetailViewProps,
    | 'onClickPersonicxInfoButton'
    | 'demographics'
    | 'loadingSurface'
    | 'loadingDemographics'
    | 'loadingAvailability'
    | 'availability'
  > & {
    proposalId: Proposal['id'];
    token: WithAuth['token'];
    noLazy: boolean;
    noPagination: boolean;
    noMap: boolean;
    selectedSurface: Partial<Panel>;
    openDetailView: boolean;
    onCloseDetailView: () => void;
    startDate: string;
    endDate: string;
  };
class SurfaceExplorer extends Component<Props> {
  controller: Controller;

  constructor(props: Props) {
    super(props);
    this.controller = new Controller(props);
  }

  componentWillReceiveProps(nextProps: Props) {
    this.controller.props = nextProps;
  }

  componentDidMount() {
    const {mapCachedPanels} = this.props;
    if (isEmpty(mapCachedPanels)) {
      this.controller.loadMapPanels();
    }
  }

  getAudienceInfo = () => {
    const {mapCachedPanels, selectedSurface, audienceSortRequest} = this.props;
    if (
      selectedSurface &&
      selectedSurface.id &&
      mapCachedPanels &&
      mapCachedPanels[selectedSurface.id] &&
      audienceSortRequest
    ) {
      const audienceSort = mapCachedPanels[selectedSurface.id].audienceSort;
      if (audienceSort) {
        const {keywords = [], keywordsLow = [], keywordsStats = []} = audienceSortRequest;
        return {
          ...audienceSort,
          total: Object.keys(mapCachedPanels).length,
          primaryCharacteristics: audienceSort.keywordStats.filter((keyword) =>
            keywords.includes(keyword.name),
          ),
          secondaryCharacteristics: audienceSort.keywordStats.filter((keyword) =>
            keywordsLow.includes(keyword.name),
          ),
          informativeCharacteristics: audienceSort.keywordStats.filter((keyword) =>
            keywordsStats.includes(keyword.name),
          ),
        };
      }
    }
  };

  renderToolbar = (isMobileSize) => {
    const {
      classes,
      loading,
      query,
      searchTerm,
      viewMode,
      mapPanels,
      total,
      mapCachedPanels,
    } = this.props;
    const toolbarTotal = viewMode === SurfaceExplorerViewMode.Map ? mapPanels.length : total;
    return (
      <div className={classNames(classes.header, classes.sticky)}>
        {loading && <LinearProgress variant="query" className={classes.progress} />}

        <Toolbar
          limit={query.limit}
          skip={query.skip}
          searchTerm={searchTerm}
          total={toolbarTotal}
          onChangeSkip={this.controller.handleSkipChange}
          onClearClick={this.controller.clearSearch}
          onSearch={this.controller.handleSearch}
          viewMode={viewMode}
          onViewModeToggle={this.controller.handleViewModeToggle}
          minify={isMobileSize && viewMode === SurfaceExplorerViewMode.Map}
          showToggleButton={isMobileSize}
          disabled={isEmpty(mapCachedPanels)}
        />
      </div>
    );
  };

  renderSurfaceExplorerMap = (isMobileSize: boolean) => {
    const {
      classes,
      noMap,
      viewMode,
      mapPanels,
      onSurfaceClick,
      onCloseDetailView,
      mapPopUpPanels,
      selectedSurface,
    } = this.props;
    const mapSelectedPanel = mapPopUpPanels.length <= 1 ? selectedSurface : null;
    return (
      !noMap && (
        <div
          className={classNames(classes.root, {
            [classes.hidden]: isMobileSize && viewMode !== SurfaceExplorerViewMode.Map,
          })}
          id="surface-explorer-map"
        >
          <SurfaceExplorerMap
            innerRef={(ref) => (this.controller.surfaceExplorerMap = ref)}
            panels={mapPanels}
            onPanelClick={onSurfaceClick}
            selectedPanel={mapSelectedPanel}
            onSelectedPanelIsClustered={onCloseDetailView}
            onResetClick={this.controller.onMapResetClick}
            onChange={this.controller.onMapChange}
            popUpPanels={this.props.mapPopUpPanels}
            onIdle={this.controller.onMapIdle}
            onMapLoad={this.controller.onMapLoad}
            closePopUp={this.controller.closeMapPopUp}
            disabled={!this.props.mapInitialPosition}
            openPopUp={this.controller.openMapPopUp}
            showResetButton={this.props.mapResetButton}
          />
        </div>
      )
    );
  };

  renderSurfaceExplorerCards = (isMobileSize: boolean) => {
    const {viewMode, gridPanels, onSurfaceClick, openDetailView, selectedSurface} = this.props;
    const gridSelectedPanelId = openDetailView ? _get(selectedSurface, 'id', '') : null;
    return (
      (!isMobileSize || (isMobileSize && viewMode === SurfaceExplorerViewMode.Grid)) && (
        <SurfaceExplorerCards
          panels={gridPanels}
          onSurfaceClick={onSurfaceClick}
          selectedSurfaceId={gridSelectedPanelId}
        />
      )
    );
  };

  renderDatagrid = () => {
    const {classes, gridPanels} = this.props;
    return collectionSlicer(gridPanels, 30).map((pagePanels, index) => (
      <div className={classes.tableWrapper} key={`pagePanels-${index}`}>
        <div className={classes.tableView}>
          <Datagrid data={pagePanels as Panel[]} />
        </div>
      </div>
    ));
  };

  render() {
    const {
      classes,
      selectedSurface,
      demographics,
      loadingSurface,
      loadingDemographics,
      loadingAvailability,
      availability,
      openDetailView,
      onCloseDetailView,
      token,
      onClickPersonicxInfoButton,
      startDate,
      endDate,
      width,
    } = this.props;
    const isMobileSize = isWidthDown('md', width);

    return (
      <div className={classNames({[classes.wrapper]: isMobileSize})}>
        {this.renderSurfaceExplorerMap(isMobileSize)}
        <div className={classes.root} id="surface-explorer">
          {this.renderToolbar(isMobileSize)}
          {this.renderSurfaceExplorerCards(isMobileSize)}
          {this.renderDatagrid()}
          <SurfaceDetailView
            audienceInfo={this.getAudienceInfo()}
            demographics={demographics}
            loadingSurface={loadingSurface}
            loadingDemographics={loadingDemographics}
            loadingAvailability={loadingAvailability}
            availability={availability}
            open={openDetailView}
            onClickCloseButton={onCloseDetailView}
            token={token}
            onClickPersonicxInfoButton={onClickPersonicxInfoButton}
            startDate={startDate}
            endDate={endDate}
            {...selectedSurface}
          />
        </div>
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(container(SurfaceExplorer)));
