import {createRef, FunctionComponent} from 'react';
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Datagrid as RootDatagrid,
  DatagridContent,
  DatagridSortingType,
  DatagridTableView,
  DatagridTableViewColumn,
  DatagridTableViewRow,
  DatagridViewMode,
  Scrollbars,
} from '@onsmart/ui-kit';
import classNames from 'classnames';

import {Panel} from 'models/Panel';

import {Styles, styles} from './styles';

interface Props extends Styles {
  data: Panel[];
}

const headerProperties = (classes: Props['classes']) => [
  {
    label: 'Market',
    value: 'marketName',
    columnClass: classNames(classes.columnClass, classes.largeCol),
  },
  {
    label: 'ID',
    value: 'id',
    columnClass: classNames(classes.columnClass, classes.smallCol),
  },
  {
    label: 'Media',
    value: 'mediaName',
    columnClass: classNames(classes.columnClass, classes.mediumCol),
  },
  {
    label: 'Location Description',
    value: 'locationDescription',
    columnClass: classNames(classes.columnClass),
  },
];

const Datagrid: FunctionComponent<Props> = (props) => {
  const {classes, data} = props;
  const {columnClass, mediumCol, smallCol, largeCol, last, row, headRow, grid} = classes;

  const vScrollbarsRef = createRef<Scrollbars>();

  return (
    <RootDatagrid<Panel> items={data} printable={true} viewMode={DatagridViewMode.table}>
      <DatagridContent>
        {(items: Panel[]) => {
          return (
            <DatagridTableView
              properties={headerProperties(classes)}
              sorting={{key: 'marketName', type: DatagridSortingType.asc}}
              verticalScrollbarsRef={vScrollbarsRef}
              onSortingChange={() => null}
              classes={{grid, headRow}}
            >
              {items.map((item) => (
                <DatagridTableViewRow classes={{root: classNames(row, last)}} key={item.id}>
                  <DatagridTableViewColumn
                    classes={{
                      root: classNames(columnClass, largeCol),
                    }}
                  >
                    {item.marketName} - {item.marketCode}
                  </DatagridTableViewColumn>
                  <DatagridTableViewColumn
                    classes={{
                      root: classNames(columnClass, smallCol),
                    }}
                  >
                    {item.id}
                  </DatagridTableViewColumn>
                  <DatagridTableViewColumn
                    classes={{
                      root: classNames(columnClass, mediumCol),
                    }}
                  >
                    {item.mediaName}
                  </DatagridTableViewColumn>
                  <DatagridTableViewColumn classes={{root: classNames(columnClass)}}>
                    {item.locationDescription}
                  </DatagridTableViewColumn>
                </DatagridTableViewRow>
              ))}
            </DatagridTableView>
          );
        }}
      </DatagridContent>
    </RootDatagrid>
  );
};

export default withStyles(styles)(Datagrid);
