import React from 'react';
import Fab from '@material-ui/core/Fab';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import MenuIcon from '@material-ui/icons/Menu';

import {styles} from './styles';

type Props = WithStyles<typeof styles> & {
  handleMenuClick: () => void;
};

function MainNavFloatinButton(props: Props) {
  const {classes, handleMenuClick} = props;

  return (
    <Fab aria-label="Open Menu" className={classes.root} onClick={handleMenuClick}>
      <MenuIcon />
    </Fab>
  );
}

export default withStyles(styles)(MainNavFloatinButton);
